import React from "react";
import "./images.css";

export default function Images(props) {
  const [images, setImages] = React.useState(props.images);

  const handelDeleteImage = (e) => {
    e.preventDefault();
    // console.log(props.number);
    props.handelDeleteImage(props.number);
  };

  React.useEffect(() => {
    setImages(props.images);
  }, [props.images]);
  return (
    <div
      className={`eachImage grid-container-images ${
        props.number % 2 === 1 ? "bG" : "bW"
      }`}
    >
      <div className="grid-item-images imageNumberInImages">{props.number}</div>
      <div className="grid-item-images imageURLInImages">
        <input
          value={images}
          placeholder="رابط الصورة"
          onChange={(e) => {
            setImages(e.target.value);
            props.changeImage(e.target.value, props.number);
          }}
        ></input>
      </div>
      <img
        className="grid-item-images imageInImages"
        src={`${images}`}
        alt=""
      ></img>
      <div
        className="deleteImage grid-item-images"
        onClick={(e) => handelDeleteImage(e)}
      >
        X
      </div>
    </div>
  );
}
