import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AOS from "aos";
import { BiMap } from "react-icons/bi";
import { withTranslation } from "react-i18next";
import "./Proj.css";
import { object } from "firebase-functions/lib/providers/storage";
import { useHistory } from "react-router-dom";

function Proj(props) {
  const [booking, setBooking] = React.useState(0);
  const [solds, setSolds] = React.useState(0);

  const handelAddBooking = (e) => {
    history.push(`/addBooking/${e.name.en}`);
  };

  AOS.init();
  const project = props.project;
  let animationDirection = props.location % 2 === 1 ? true : false;
  let details = [];
  if (props.details) {
    project.details[props.currentLang === "ar" ? "ar" : "en"].forEach((e) => {
      details.push(e);
    });
  }

  // console.log(Object.values(project["booking"]));

  let Solds = 0;
  let Booking = 0;
  Object.values(props.project["booking"]).forEach((e, key) => {
    e.forEach((ee, key2) => {
      if (ee.state === "مباعه") {
        Solds = Solds + 1;
      } else {
        Booking = Booking + 1;
      }
    });
  });

  // setSolds(Solds);
  // setBooking(Booking);
  const history = useHistory();

  return (
    <Router>
      <div className="Proj">
        <div className="div-image">
          <Card
            className="card-div"
            data-aos={`zoom-in-${animationDirection ? "left" : "right"}`}
          >
            <Card.Img
              className="project-image"
              variant="top"
              src={project.img}
              loading="lazy"
            />

            <Card.Body>
              <Card.Text
                className="cardTextBooking"
                dir={props.currentLang === "ar" ? "rtl" : "ltr"}
              >
                {props.i18n.language === "ar"
                  ? project.name["ar"]
                  : project.name["en"]}
              </Card.Text>
            </Card.Body>
            <small
              className="proj-details"
              dir={props.currentLang === "ar" ? "rtl" : "ltr"}
            >
              <div className="projINFO">
                {props.i18n.language === "ar" ? (
                  <>
                    <div
                      className="grid-container"
                      onClick={(e) => history.push("/mybooking")}
                    >
                      <div className="grid-item 1">{Solds}</div>
                      <div className="grid-item 2">المباع</div>
                      <div className="grid-item 3">{Booking}</div>
                      <div className="grid-item 4">المحجوز</div>
                    </div>
                    <div
                      className="newBooking"
                      onClick={(e) => handelAddBooking(project)}
                    >
                      حجز جديد
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="grid-container"
                      onClick={(e) => history.push("/mybooking")}
                    >
                      <div className="grid-item 1">SOLD</div>
                      <div className="grid-item 1">{Solds}</div>
                      <div className="grid-item 3">BOOKING</div>
                      <div className="grid-item 3">{Booking}</div>
                    </div>
                    <div
                      className="newBooking"
                      onClick={(e) => handelAddBooking(project)}
                    >
                      New Booking
                    </div>
                  </>
                )}
              </div>
            </small>
          </Card>
        </div>
      </div>
    </Router>
  );
}

export default withTranslation()(Proj);
