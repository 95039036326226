import React from 'react';
import './attendanceReport.css';
export default function AttendanceReport({ userAttendance }) {
  const handelTimeSubtract = (time1, time2) => {
    // console.log(time1, time2);
    time1 = time1.split(':');
    time2 = time2.split(':');
    let time_H_Diff = parseInt(time2[0]) - parseInt(time1[0]);

    let time_M_Diff = parseInt(time2[1]) - parseInt(time1[1]);

    let time_S_Diff = parseInt(time2[2]) - parseInt(time1[2]);

    if (time_S_Diff < 0) {
      time_S_Diff = 60 + time_S_Diff;
      if (time_M_Diff < 0) {
        time_M_Diff = 60 + time_M_Diff - 1;
        time_H_Diff = time_H_Diff - 1;
      }
      time_M_Diff = time_M_Diff - 1;
    }
    if (time_M_Diff < 0) {
      time_M_Diff = 60 + time_M_Diff;
      time_H_Diff = time_H_Diff - 1;
    }

    return `${time_H_Diff < 10 ? '0' + time_H_Diff : time_H_Diff}:${
      time_M_Diff < 10 ? '0' + time_M_Diff : time_M_Diff
    }:${time_S_Diff < 10 ? '0' + time_S_Diff : time_S_Diff}`;
  };

  let tempDate = [];
  for (let [date, value] of Object.entries(userAttendance)) {
    tempDate.push(date);
  }

  // sort date
  tempDate.sort(function (a, b) {
    return new Date(a) - new Date(b);
  });

  const Tr_loader = Object.keys(userAttendance).map((date, key) => {
    const d = new Date(tempDate[key]);
    let day = d.getDay();
    let allDays = [
      'الأحد',
      'الإثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت'
    ];

    return (
      <tr key={key}>
        {/* DATE */}
        <th>{tempDate[key]}</th>
        {/* DAY */}
        <th>{allDays[day]}</th>
        {/* Attend Time */}
        <th
          className={`${
            userAttendance[tempDate[key]]['attend'].onTime ? '' : 'gray'
          }`}
        >
          {userAttendance[tempDate[key]]['attend'].time}
        </th>
        {/* Leave Time */}
        <th
          className={`${
            userAttendance[tempDate[key]]['leave'] !== undefined
              ? userAttendance[tempDate[key]]['leave'].onTime === undefined
                ? 'red'
                : userAttendance[tempDate[key]]['leave'].onTime
                ? ''
                : 'gray'
              : 'red'
          }`}
        >
          {userAttendance[tempDate[key]]['leave'] !== undefined
            ? userAttendance[tempDate[key]]['leave'].time === undefined
              ? 'لم يتم تسجيل إنصراف'
              : userAttendance[tempDate[key]]['leave'].time
            : 'لم يتم تسجيل إنصراف'}
        </th>
        {/* <th>{temp_arr[]}</th> */}
        {/* Total Time */}
        <th
          className={`${
            userAttendance[tempDate[key]]['leave'] !== undefined
              ? userAttendance[tempDate[key]]['leave'].time === undefined
                ? 'red'
                : parseInt(
                    handelTimeSubtract(
                      userAttendance[tempDate[key]]['attend'].time,
                      userAttendance[tempDate[key]['leave']] !== undefined
                        ? userAttendance[tempDate[key]]['leave'].time
                        : '08:00'
                    ).split(':')[0]
                  ) < 8
                ? 'red'
                : ''
              : 'red'
          }`}
        >
          {' '}
          {userAttendance[tempDate[key]]['leave'] !== undefined
            ? userAttendance[tempDate[key]]['leave'].time === undefined
              ? 'لم يتم تسجيل إنصراف'
              : handelTimeSubtract(
                  userAttendance[tempDate[key]]['attend'].time,
                  userAttendance[tempDate[key]]['leave'].time
                )
            : 'لم يتم تسجيل إنصراف'}
        </th>
      </tr>
    );
  });

  return (
    <div className="attendanceReportDiv">
      <table>
        <thead>
          <tr>
            <th>التاريخ</th>
            <th>اليوم</th>
            <th>وقت الحضور</th>
            <th>وقت الإنصراف</th>
            <th>الوقت الكلي</th>
          </tr>
        </thead>
        <tbody>{Tr_loader}</tbody>
      </table>
    </div>
  );
}