import React from "react";
import "./addBooking.css";
import Form from "react-bootstrap/Form";
import TableExcel from "../TableExcel";

const AddBooking = (props) => {
  //   console.log(props.history.location.pathname.split("/")[2]);
  //   console.log(props.projects);
  const [currentProject, setCurrentProject] = React.useState({});
  const [buildingArray, setBuildingArray] = React.useState([]);

  const [buildingSelected, setBuildingSelected] = React.useState(null);

  props.projects.forEach((e) => {
    if (
      e.name.en === props.history.location.pathname.split("/")[2] &&
      currentProject !== e
    ) {
      setCurrentProject(e);
    }
  });

  const newForLoop = (e) => {
    let temp_arr = [];
    for (let i = 1; i <= e; i++) {
      temp_arr.push(i);
    }
    setBuildingArray(temp_arr);
  };

  React.useEffect(() => {
    newForLoop(currentProject.no_Buildings);
  }, []);

  return (
    <div className="addBookingDiv">
      <h5>العمارة</h5>
      <Form.Control
        className="buildingOption"
        required
        as="select"
        onChange={(e) => setBuildingSelected(e.target.value)}
      >
        <option value="" key={-1}>
          اختر المبنى
        </option>
        {buildingArray.map((e, key) => {
          return (
            <option value={e} key={key}>
              {e}
            </option>
          );
        })}
      </Form.Control>

      {buildingSelected !== null && buildingSelected !== "" ? (
        <>
          <TableExcel
            data={currentProject}
            building={buildingSelected}
            currentUser={props.currentUserData.name}
          />
        </>
      ) : null}
    </div>
  );
};

export default AddBooking;
