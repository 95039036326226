import React from "react";

export default function Figure({ deleteFigure, index, figure, addFigureData }) {
  const updateFigure = (index, name, value) => {
    addFigureData(index, name, value);
  };

  const alphabet_ar = [
    "أ",
    "ب",
    "ج",
    "د",
    "ه",
    "و",
    "ز",
    "ح",
    "ط",
    "ي",
    "ك",
    "ل",
    "م",
    "ن",
    "س",
    "ع",
    "ف",
    "ص",
    "ق",
    "ر",
    "ش",
    "ت",
    "ث",
    "خ",
    "ذ",
    "ض",
    "ظ",
    "غ",
  ];
  return (
    <>
      <p style={{ width: "80%", margin: "auto", textAlign: "center" }}>
        نموذج {alphabet_ar[index]}
      </p>
      <div className="newDiv">
        <div className="grid-container">
          <div className="grid-item 1 size">
            <input
              name="size"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.value);
              }}
              value={figure.size}
            ></input>
          </div>
          <div className="grid-item 2 size">المساحة</div>
          <div className="grid-item 3 rooms">
            {" "}
            <input
              name="rooms"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.value);
              }}
              value={figure.rooms}
            ></input>
          </div>
          <div className="grid-item 4 rooms">غرف النوم</div>
          <div className="grid-item 5 livingRooms">
            {" "}
            <input
              name="livingRooms"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.value);
              }}
              value={figure.livingRooms}
            ></input>
          </div>
          <div className="grid-item 6 livingRooms">الصالات</div>
          <div className="grid-item 7 bathRooms">
            {" "}
            <input
              name="bathRooms"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.value);
              }}
              value={figure.bathRooms}
            ></input>
          </div>
          <div className="grid-item 8 bathRooms">دورات المياه</div>
          <div className="grid-item 9 parking">
            {" "}
            <input
              name="parking"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.value);
              }}
              value={figure.parking}
            ></input>
          </div>
          <div className="grid-item 10 parking">موقف سيارة</div>
          <div className="grid-item 11 checkBox extra">
            <input
              type="checkbox"
              id="vehicle1"
              name="extra"
              onChange={(e) => {
                updateFigure(index, e.target.name, e.target.checked);
              }}
              checked={figure.extra}
            ></input>
          </div>
          <div className="grid-item 12 extra">ملحق</div>
        </div>
        <div
          className="deleteFigure"
          onClick={(ee) => {
            deleteFigure(index);
            //   let temp = projectFigures;
            //   temp.splice(index, 1);
            //   setProjectFigures(temp);
            //   setRefresh(refresh + 1);
          }}
        >
          حذف النموذج
        </div>
      </div>
    </>
  );
}
