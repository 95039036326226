// import {
//     updateMyBooking,
//     updateMyBooking2,
//     insertIntoArchive,
//   } from "../../api";
import swal from 'sweetalert';
import { addNewAttendance, addNewLeaving } from '../../api';

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

const errorCallback = (error) => {
  console.log('error geolocation: ', error);

  window.alert('الرجاء تفعيل الموقع من الإعدادات');
};

const geolocationOptions = {
  enableHighAccuracy: true,
  maximumAge: 10000
  // timeout: 5000
};

const handelAttendence = async (userEmail, allProjects, handelAfterAttend) => {
  console.log(navigator.platform);
  let newDate = new Date();
  let time = newDate.toLocaleString('ar-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });

  let time_24 = newDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  });

  let date = newDate.toLocaleDateString('ar-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });

  let locName = 'خارج الموقع';
  let currentLoc = '';
  let far = 100;
  // let permissionState = 'denied';
  // await navigator.permissions
  //   .query({ name: 'geolocation' })
  //   .then((e) => (permissionState = e.state));
  // console.log(permissionState);
  // if (permissionState == 'granted') {
  await navigator.geolocation.getCurrentPosition(
    async (e) => {
      console.log(e);
      currentLoc =
        'https://www.google.com/maps/@' +
        e.coords.latitude +
        ',' +
        e.coords.longitude +
        ',16z';
      console.log(currentLoc);
      await allProjects.forEach((proj, key2) => {
        let distance = getDistanceFromLatLonInKm(
          e.coords.latitude,
          e.coords.longitude,
          proj['location'].split('@')[1].split(',')[0],
          proj['location'].split('@')[1].split(',')[1]
        );
        if (distance <= far) {
          far = distance;
          locName = proj.name.ar;
        }
      });
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);

      let lates =
        parseFloat(`${newDate.getHours()}.${newDate.getMinutes()}`) - 8.0;
      let onTime = lates < 0.15 ? true : false;

      addNewAttendance(
        userEmail,
        time_24,
        parseInt(newDate.getMonth() + 1) +
          '-' +
          newDate.getDate() +
          '-' +
          newDate.getFullYear(),
        locName,
        far,
        currentLoc,
        onTime,
        lates
      );
      handelAfterAttend();

      console.log('userEmail: ', userEmail);
      console.log('time_24: ', time_24);
      console.log(
        `Date: `,
        parseInt(newDate.getMonth() + 1) +
          '-' +
          newDate.getDate() +
          '-' +
          newDate.getFullYear()
      );
      console.log('locName: ', locName);
      console.log('far: ', far);
      console.log('currentLoc: ', currentLoc);
      console.log('onTime: ', onTime);
      console.log('lates: ', lates);
      swal(
        'تم تسجيل الحضور بنجاح',
        `في يوم ${date}, في الوقت ${time}`,
        'success'
      ).then(async (ok) => {
        await delay(1000);
        // window.location.reload();
      });
    },
    errorCallback,
    geolocationOptions
  );

  // } else {
  //   prompt('لايمكنك التحضير الرجاء المحاول مرة أخرى والتاكد من تفعيل الموقع');
  // }
};

const handelLeaving = async (userEmail, allProjects) => {
  let newDate = new Date();
  let time = newDate.toLocaleString('ar-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  let time_24 = newDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  });

  let date = newDate.toLocaleDateString('ar-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  let locName = 'خارج الموقع';
  let currentLoc = '';
  let far = 100;
  // let permissionState = 'denied';
  // await navigator.permissions
  //   .query({ name: 'geolocation' })
  //   .then((e) => (permissionState = e.state));
  // console.log(permissionState);

  // if (permissionState == 'granted') {
  await navigator.geolocation.getCurrentPosition(
    async (e) => {
      currentLoc =
        'https://www.google.com/maps/@' +
        e.coords.latitude +
        ',' +
        e.coords.longitude +
        ',16z';
      await allProjects.forEach((proj, key2) => {
        let distance = getDistanceFromLatLonInKm(
          e.coords.latitude,
          e.coords.longitude,
          proj['location'].split('@')[1].split(',')[0],
          proj['location'].split('@')[1].split(',')[1]
        );
        if (distance <= far) {
          far = distance;
          locName = proj.name.ar;
        }
      });
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      let early =
        parseFloat(`${newDate.getHours()}.${newDate.getMinutes()}`) - 16.0;
      let onTime = early > 0 ? true : false;

      console.log('userEmail: ', userEmail);
      console.log('time_24: ', time_24);
      console.log(
        `Date: `,
        parseInt(newDate.getMonth() + 1) +
          '-' +
          newDate.getDate() +
          '-' +
          newDate.getFullYear()
      );
      console.log('locName: ', locName);
      console.log('far: ', far);
      console.log('currentLoc: ', currentLoc);
      console.log('onTime: ', onTime);
      console.log('early: ', early);
      addNewLeaving(
        userEmail,
        time_24,
        parseInt(newDate.getMonth() + 1) +
          '-' +
          newDate.getDate() +
          '-' +
          newDate.getFullYear(),
        locName,
        far,
        currentLoc,
        onTime,
        early
      );

      swal(
        'تم تسجيل الإنصراف بنجاح',
        `في يوم ${date}, في الوقت ${time}`,
        'success'
      ).then((ok) => {
        window.location.reload();
      });
    },
    errorCallback,
    geolocationOptions
  );

  // } else {
  //   prompt('لايمكنك الإنصراف الرجاء المحاول مرة أخرى والتاكد من تفعيل الموقع');
  // }
};

export { handelAttendence, handelLeaving };