import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'js-cookie';
import $ from 'jquery';
import app from '../../base.js';
import { useTranslation } from 'react-i18next';
import './login.css';
import { updateMessage } from '../Booking/Functions';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};

function Login(props) {
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [showPass, setShowPass] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [openReset, setOpenReset] = React.useState(false);

  $('.custom-control-input').click((e) => {
    setShowPass(!showPass);
  });

  const handelUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handelPassword = (e) => {
    setPassword(e.target.value);
  };

  const handelSingIn = async (e) => {
    e.preventDefault();

    try {
      await app.auth().signInWithEmailAndPassword(userName.trim(), password);
      let userData = props.userData;
      // console.log(userData);
      window.location.href = userData.isOwner
        ? '/booking'
        : userData.isAdmin
        ? '/booking'
        : userData.isMiniAdmin
        ? '/controlpanel'
        : userData.isAlwaqil
        ? '/الوكيل'
        : userData.isSaler
        ? '/booking'
        : userData.isWorker
        ? '/messages'
        : '/landing';
      // window.location.href = `/booking`;
    } catch (error) {
      setShow(true);
    }
  };

  const AlertDismissibleExample = () => {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{t('loginError.header')}</Alert.Heading>
        <pre>{t('loginError.message')}</pre>
      </Alert>
    );
  };

  return (
    <div className="Login-main" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      {openReset ? (
        <>
          {' '}
          <Modal
            open={openReset}
            onClose={(e) => setOpenReset(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="bookingDataEdite"
          >
            <Box className="mainBoxLogin" sx={{ ...style, width: '35%' }}>
              <div>
                <label
                  style={{ float: i18n.language === 'ar' ? 'right' : 'left' }}
                >
                  {t('login.email')}
                </label>
                <input
                  type="name"
                  className="form-control"
                  placeholder="Enter user email"
                  onChange={(e) => handelUserNameChange(e)}
                  required
                />
                <div className="resetButtonDiv">
                  {' '}
                  <Button
                    className="resetButton"
                    onClick={(e) => {
                      app
                        .auth()
                        .sendPasswordResetEmail(userName.trim())
                        .then(() => {
                          // Password reset email sent!
                          // ..
                          updateMessage('resetPass');
                        })
                        .catch((error) => {});
                    }}
                  >
                    {t('restPass.submit')}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      ) : null}
      {Cookies.get('name') ? (
        (window.location.href = `/Messages`)
      ) : show ? (
        <AlertDismissibleExample />
      ) : null}
      <form>
        <h3> {t('login.header')}</h3>

        <div className="form-group">
          <label style={{ float: i18n.language === 'ar' ? 'right' : 'left' }}>
            {t('login.email')}
          </label>
          <input
            type="name"
            className="form-control"
            placeholder="Enter user email"
            onChange={(e) => handelUserNameChange(e)}
            required
          />
        </div>

        <div className="form-group">
          <label style={{ float: i18n.language === 'ar' ? 'right' : 'left' }}>
            {t('login.password')}
          </label>
          <input
            type={`${showPass ? 'name' : 'password'}`}
            className="form-control"
            placeholder="Enter password"
            onChange={(e) => handelPassword(e)}
            required
          />
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <label
              style={{ float: i18n.language === 'ar' ? 'right' : 'left' }}
              className="custom-control-label"
              htmlFor="customCheck1"
            >
              {t('login.showPassword')}
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-dark btn-lg btn-block"
          onClick={(e) => handelSingIn(e)}
        >
          {t('login.signIn')}
        </button>
        <p
          className="forgot-password text-right resetPass"
          style={{
            float: i18n.language === 'ar' ? 'right' : 'left',
            fontSize: '16px'
          }}
          onClick={(e) => {
            setOpenReset(true);
            setPassword('');
          }}
        >
          {t('restPass.forget')}
          <a style={{ fontSize: '16px' }}>{t('restPass.pass')}</a>
        </p>
      </form>
    </div>
  );
}

export default Login;
