import React from 'react';
import './home.css';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import { tafqeet } from './Tafqeet';

export default function Home(props) {
  const [day, setDay] = React.useState('الثلاثاء');
  const [firstDateAr, setFirstDateAr] = React.useState('10/10/1443');
  const [firstDateEn, setFirstDateEn] = React.useState('10/10/2021');
  const [clientName, setClientName] = React.useState('نجوى احمد علي الغامدي');
  const [nationality, setNationality] = React.useState('سعودية');
  const [clientID, setClientID] = React.useState('1111111111');
  const [mobileNumber, setMobileNumber] = React.useState('0555555555');
  const [apartment, setApartment] = React.useState('1/أ');
  const [direction, setDirection] = React.useState('الجنوبية الغربية');
  const [floor, setFloor] = React.useState('الدور الأرضي');
  const [size, setSize] = React.useState('244.66');
  const [landNumber, setLandNumber] = React.useState('43');
  const [scheme, setScheme] = React.useState('444/ج/س/المعدل');
  const [district, setDistrict] = React.useState('الورود');
  const [instrument, setInstrument] = React.useState('320222005420');
  const [instrumentDate, setInstrumentDate] = React.useState('16/12/1442');
  const [guardsmanApartment, setGuardsmanApartment] = React.useState('1');
  const [parkingNumber, setParkingNumber] = React.useState('1');
  const [tankNumber, setTankNumber] = React.useState('1');
  const [priceNumbers, setPriceNumbers] = React.useState('960,000');
  const [priceWords, setPriceWords] = React.useState('تسعمائة وستون الف');
  const [checkNumber, setCheckNumber] = React.useState('00636179');
  const [bankName, setBankName] = React.useState('البلاد');
  const [checkDate, setCheckDate] = React.useState('12/09/2021');
  const [deliveryTime, setDeliveryTime] = React.useState('14/2/1443');
  const [type, setType] = React.useState('التمديد المدفون');
  const [years, setYears] = React.useState('سنتين');
  const [years2, setYears2] = React.useState('عشر سنوات');
  const [nameFirst, setNameFirst] = React.useState('نواف بن سعيد محمد الغامدي');
  const [nameSecond, setNameSecond] = React.useState('نجوى احمد علي الغامدي');

  const [contract, setContract] = React.useState(false);

  const handelPriceNumbers = (price) => {
    // console.log(price.length);
    if (price.length === 4) {
      let tempArr = price.split('');
      let tempPop = tempArr.pop();
      tempArr.push(',');
      tempArr.push(tempPop);
      price = tempArr.join('');
    }
    setPriceNumbers(price);
  };
  props.project.forEach((e, key) => {
    for (let [key, value] of Object.entries(e['booking'])) {
      value.forEach((ee, key) => {
        if (
          ee.contractID === parseInt(window.location.pathname.split('/')[2]) &&
          !contract
        ) {
          setContract(ee.contract);
        }
      });
    }
  });

  React.useEffect(() => {
    setDay(contract.day);
    setFirstDateAr(contract.firstDateAr);
    setFirstDateEn(contract.firstDateEn);
    setClientName(contract.clientName);
    setNationality(contract.nationality);
    setClientID(contract.clientID);
    setMobileNumber(contract.mobileNumber);
    setApartment(contract.apartment);
    setDirection(contract.direction);
    setFloor(contract.floor);
    setSize(contract.size);
    setLandNumber(contract.landNumber);
    setScheme(contract.scheme);
    setDistrict(contract.district);
    setInstrument(contract.instrument);
    setInstrumentDate(contract.instrument_Date);
    setGuardsmanApartment(contract.guardsmanApartment);
    setParkingNumber(contract.parkingNumber);
    setTankNumber(contract.tankNumber);
    setPriceNumbers(contract.priceNumbers);
    setPriceWords(contract.priceWords);
    setCheckNumber(contract.checkNumber);
    setBankName(contract.bankName);
    setCheckDate(contract.checkDate);
    setDeliveryTime(contract.deliveryTime);
    setType(contract.type);
    setYears(contract.years);
    setYears2(contract.years2);
    setNameFirst(contract.nameFirst);
    props.handelHeader(false);
  }, [contract !== false]);

  const PrintButton = () => {
    return (
      <div className="buttons">
        <button
          className="btn btn-primary next save"
          onClick={(e) => {
            let buttonDiv = $('.contractDiv .buttons')[0];
            $('.contractDiv .buttons')[0].remove();
            window.print();
            $('.contractDiv')[0].append(buttonDiv);
          }}
        >
          طباعة
        </button>
      </div>
    );
  };

  return (
    <div>
      {contract ? (
        <>
          {' '}
          <div className="contractDiv">
            {' '}
            <h3>بسم الله الرحمن الرحيم</h3>
            <h3
              className="contractHeader"
              style={{ textDecoration: 'underline' }}
            >
              عقد بيع وحدة سكنية
            </h3>
            <p>
              الحمد لله رب العالمين والصلاة والسلام على أشرف المرسلين سيدنا محمد
              صلى الله عليه وسلم وعلى آلة وصحبه أجمعين، وبعد
            </p>
            <div className="p1">
              <p>
                إنه في يوم{' '}
                <span className="inputSpan">
                  {day} {firstDateAr} هـ
                </span>{' '}
                الموافق: <span className="inputSpan">{firstDateEn} م </span>
                بمدينة جدة بعون الله تعالى تم الاتفاق والتراضي بين:{' '}
              </p>
            </div>
            {/* <p>{`إنه في يوم ${day} ${firstDateAr}ه الموافق:  ${firstDateEn}م بمدينة جدة بعون الله تعالى تم الاتفاق والتراضي بين:`}</p> */}
            <p>
              <span className="numbering">1.</span> البائع: شركة أوتار العالمية
              للتطوير العقاري سجل تجاري رقم (4030587137) صادر من جدة بتاريخ
              17/10/1438هـ وعنوانها جدة حي الصفا مركز الاخوة التجاري.. ويشار
              إليه فيما بعد (الطرف الأول).
            </p>
            <div className="p1">
              <p>
                <span className="numbering">2.</span> المشتري: السيد/ة{' '}
                {clientName} {nationality} الجنسية بموجب سجل مدني رقم (
                {clientID}) جوال رقم ({mobileNumber}) ويشار اليها فيما بعد
                (الطرف الثاني).
              </p>
            </div>
            <p>تمهيد:</p>
            <div className="p1">
              <p>
                الطرف الأول هو المالك لـ(الوحدة السكنية المباعة) وهي الوحدة رقم
                ( <span className="inputSpan">{apartment}</span> ) الواقعة في
                الجهة ( <span className="inputSpan">{direction}</span> ) في ({' '}
                <span className="inputSpan">{floor}</span> ) ومساحتها ({' '}
                <span className="inputSpan">{size}</span> ) متر مربع ، والمقامة
                على قطعة أرض رقم من المخطط رقم ({' '}
                <span className="inputSpan">{landNumber}</span> ) والواقع في حي
                ( <span className="inputSpan">{district}</span> ) بمدينة جدة ؛
                وذلك بموجب صك شرعي بالرقم ({' '}
                <span className="inputSpan">{instrument}</span> ) وتاريخ (
                <span className="inputSpan">{instrumentDate}</span> ) والصادر من
                كتابة العدل الأولى بمدينة جدة ، وحيث رغب الطرف الثاني في شراء
                هذه الشقة وملحقاتها المتمثلة في غرفة الحارس رقم ({' '}
                <span className="inputSpan">{guardsmanApartment}</span> ) و
                الموقف رقم ( <span className="inputSpan">{parkingNumber}</span>{' '}
                ) ، والخزانين المستقلين والمرقمين بالرقم ({' '}
                <span className="inputSpan">{tankNumber}</span> ) أحدهما في
                الأعلى والآخر في الأسفل وقد تم تحديدهم من قبل الطرف الاول. فقد
                اتفق الطرفان بكامل أهليتهما المعتبرة شرعاً ونظاماً على الآتي:
              </p>
            </div>
            <p>البند الأول:</p>
            <p>يعتبر التمهيد أعلاه جزءً لا يتجزأ من العقد يقرأ ويفسر معه.</p>
            <p>البند الثاني:</p>
            <p>
              باع الطرف الأول للطرف الثاني الشقة المشار إليها في التمهيد بيعاً
              منجزاً لا رجعة فيه.
            </p>
            <p>البند الثالث: (الثمن)</p>
            <div className="p1">
              <p>
                تم البيع بثمن إجمالي ({' '}
                <span className="inputSpan" className="inputSpan">
                  {priceNumbers}
                </span>{' '}
                ) ريال ({' '}
                <span className="inputSpan" className="inputSpan">
                  {priceWords}
                </span>{' '}
                ريال سعودي) استلمه الطرف الأول بموجب شيك رقم ({' '}
                <span className="inputSpan" className="inputSpan">
                  {checkNumber}
                </span>{' '}
                ) صادر من بنك ({' '}
                <span className="inputSpan" className="inputSpan">
                  {bankName}
                </span>{' '}
                ) بتاريخ ({' '}
                <span className="inputSpan" className="inputSpan">
                  {checkDate}
                </span>{' '}
                ) الشيك غير شامل للقيمة المضافة.
              </p>
            </div>
            <p>البند الرابع:</p>
            <p>
              يقر الطرف الثاني بأنه عاين الشقة المباعة وملحقاتها المعاينه التامة
              النافية للجهالة وأنه قبلها بحالتها الراهنة، وأنه أصبح مسئولا عنها
              المسئولية المدنية والجنائية، ولا يحق له الرجوع على الطرف الأول لأي
              سبب.
            </p>
            <p>البند الخامس:</p>
            <p>
              يحق للطرف الثاني استغلال الشقة المباعة والتصرف فيها بكل الطرق
              القانونية، ولا يحق له التصرف في الأجزاء المشتركة بين جميع الملاك
              أو المطالبة بموقف أو خزان إضافي أو باستخدام الأسطح السفلية أو
              العلوية للعمارة.
            </p>
            <p>البند السادس:</p>
            <p>
              يجوز للطرف الثاني تأجير الشقة المباعة لعائلة محترمة، ولا يجوز له
              أن يؤجرها لعزاب أو مكاتب.
            </p>
            <p>البند السابع: (التزامات الطرف الأول)</p>
            <p className="indentText">
              <span className="numbering">1. </span>يلتزم الطرف الأول بنقل ملكية
              الشقة المباعة للطرف الثاني وإفراغها باسمه خالية تماماً من أي
              رهونات أو حجوزات وغير مشغولة أو مؤجرة لأي طرف آخر، وتنتهي مسئولية
              الطرف الأول عند الإفراغ، على أن يتحمل الطرف الثاني جميع مصروفات
              الافراغ.
            </p>
            <p>
              <span className="numbering"> 2. </span> يلتزم الطرف الأول بعدم
              التعرض للطرف الثاني وعدم التصرف في الشقة المباعة وإلا كان من حق من
              الطرف الثاني إبطال هذا التصرف.
            </p>
            <div className="p1">
              {' '}
              <p>
                <span className="numbering">3.</span> يلتزم الطرف الأول بتسليم
                الشقة المباعة للطرف الثاني بتاريخ{' '}
                <span className="inputSpan">{deliveryTime} هـ</span> ذا استوفى
                كامل المستحقات المالية من الطرف الثاني.
              </p>
            </div>
            <p className="paned_8">البند الثامن: (التزامات الطرف الثاني)</p>
            <p>
              <span className="numbering">1.</span> يلتزم الطرف الثاني بعدم
              القيام بأي تعديلات على الأجزاء المشتركة أو على الواجهة الرئيسية أو
              الهيكل الإنشائي للعمارة.
            </p>
            <p>
              <span className="numbering"> 2.</span> يلتزم الطرف الثاني بالإسراع
              في إصلاح التلفيات والأعطال التي يتسبب فيها سواء بقصد أو بغير قصد
              حفاظا على سلامة المبنى.
            </p>
            <p className="indentText">
              <span className="numbering">3.</span>يلتزم الطرف الثاني بسداد
              العوائد وجميع الأموال المقررة نظاماً على الشقة المباعة، ويلتزم
              بدفع حصته من اتحاد الملاك أو من استهلاك الكهرباء والمياه الخاص
              بخدمات العمارة، وكل ما تحتاجه العمارة من مصروفات خاصة بالصيانة
              والتجديد والنظافة، كما يلتزم بدفع حصته من راتب حارس العمارة.
            </p>
            <p className="indentText">
              <span className="numbering">4.</span>يلتزم الطرف الثاني بنقل ملكية
              العداد الخاص بالوحدة السكنية (عداد الكهرباء) باسمه بعد الإفراغ له
              مباشرة. ويتعهد منفردا ومجتمعا مع ملاك الوحدات الأخرى في العمارة
              بنقل ملكية عداد الكهرباء التابع للخدمات باسم أحد منهم أو باسم
              جمعية الملاك.
            </p>
            <p>
              <span className="numbering">5.</span> يلتزم الطرف الثاني منفردا
              ومجتمعا مع ملاك الوحدات الأخرى في العمارة بالحفاظ على أساسات
              المبنى والإلتزام بالسحب الدوري للصرف الصحي إن وجد.
            </p>
            <p>
              <span className="numbering ">6.</span> القيمة المضافة على بيع
              الوحدة السكنية يتم سددها عن طريق الطرف الثاني قبل الافراغ او بعده.
            </p>
            <p>البند التاسع: (الضمان)</p>
            <div className="p1">
              <p className="indentText">
                <span className="numbering">1.</span> يضمن الطرف الأول أعمال
                السباكة والكهرباء المخفية ({' '}
                <span className="inputSpan">{type}</span> ) لمدة ({' '}
                <span className="inputSpan">{years}</span> ) واعمال العظمة لمدة
                ( <span className="inputSpan">{years2}</span> ) من تاريخ هذا
                العقد. ولا يشمل هذا الضمان التلفيات والأعطال التي سببها الطرف
                الثاني او التلفيات والأعطال الناتجة عن الكوارث الطبيعية والقوارض
                والحشرات وما شابهه.
              </p>
            </div>
            <p className="indentText">
              <span className="numbering">2.</span> الضمان الخاص بالمصعد أو
              أعمال الخرسانة يكون لدى الشركة الموردة حسب أنظمتها واشتراطاتها،
              ولا يشمل التلفيات والأعطال الناتجة عن الكوارث الطبيعية والقوارض
              والحشرات وما شابهه، ولا يشمل التعديلات التي يقوم بها الطرف الثاني
              في الشقة أو العمارة.
            </p>
            <p>البند العاشر:</p>
            <p>
              <span className="numbering">1.</span> تسري بنود هذا العقد على كل
              من يشتري الشقة المباعة أو يستأجرها من الطرف الثاني.
            </p>
            <p>
              <span className="numbering">2.</span> لا يعتبر هذا العقد صحيحاً ما
              لم يحتوي على توقيع الأطراف.
            </p>
            <p>البند الحادي عشر:</p>
            <p>
              يخضع هذا العقد لأنظمة المملكة العربية السعودية وفي حالة نشأ نزاع
              بشأن تنفيذ أو تفسير أو صحته أو نفاذه فيتم حله صلحاً فإذا تعذر ذلك
              فيحال إلى المحاكم الشرعية.
            </p>
            <p>البند الثاني عشر:</p>
            <p>
              حرر هذا العقد من نسختين أصليتين مختومتين بيد كل طرف نسخة للعمل
              بموجبها بعد أن قرأها الطرف الثاني وعلم بمحتواها علما نافيا للجهالة
              والغرر والغبن.
            </p>
            <div className="t12">
              <div>
                <p>الطرف الأول </p>
                <p>{nameFirst}</p>
                <p className="signature">التوقيع:</p>
              </div>

              <div className="t2">
                <p>الطرف الثاني</p>
                <p>{nameSecond}</p>
                <p className="signature">التوقيع:</p>
              </div>
            </div>
            <p>الشهود:</p>
            <pre className="shahed shahed_1">{`1) ...................... هويةرقم (                   ) جوال (                  ) التوقيع ...............`}</pre>
            <pre className="shahed">{`2) ...................... هويةرقم (                   ) جوال (                  ) التوقيع ...............`}</pre>
            <PrintButton />
          </div>
        </>
      ) : (
        <>
          <h4>لايوجد عقد</h4>
        </>
      )}
      {/* <button onClick={(e) => window.print()}>طباعة</button> */}
    </div>
  );
}
