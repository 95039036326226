import React from 'react';
import './home.css';

export default function Home() {
  return (
    <div className="bgimgLanding">
      <div className="topleftLanding"></div>
      <div className="middleLanding">
        <h1>أهلاً وسهلاً بكم</h1>
        <hr />
        <p>شركة أوتار العقارية</p>
      </div>
      <div className="bottomleftLanding">
        <p>@ِAwtar.org</p>
      </div>
    </div>
  );
}
