import React, { Component } from "react";
import Content from "./Content";
import { deleteMessageByID, updateMessageByID } from "../api";

export default class Message extends Component {
  deleteMessage = (id) => {
    deleteMessageByID(id, this.props.currentUserData.name);
    const newMessageList = this.props.allMessages.filter((message) => {
      return message._id !== id;
    });

    this.props.setMessages(newMessageList);
  };

  updateMessage = (id, bookingBy, isDone) => {
    updateMessageByID(id, bookingBy, isDone, this.props.currentUserData.name);
  };

  render() {
    let done = this.props.allMessages.filter((e) => e.isDone === "true");
    let workingOn = this.props.allMessages.filter(
      (e) => e.bookingBy !== "false" && e.isDone !== "true"
    );
    let restMessages = this.props.allMessages.filter(
      (e) => e.bookingBy === "false"
    );

    let All = restMessages.concat(workingOn.concat(done));

    const allMessages = All.map((message, index) => {
      return (
        <Content
          name={message.name}
          email={message.email}
          mobile={message.mobileNumber}
          location={message.location}
          message={message.message}
          publishedOn={message.publishedOn}
          bookingBy={message.bookingBy}
          isDone={message.isDone}
          deleteMessage={this.deleteMessage}
          updateMessage={this.updateMessage}
          key={index}
          id={message._id}
          currentUserData={this.props.currentUserData}
        />
      );
    });

    let showMessages =
      this.props.allMessages !== "" ? allMessages : <h4>لايوجد رسائل</h4>;
    return showMessages;
  }
}
