import React from "react";
import Proj from "./Proj";
import CardDeck from "react-bootstrap/CardDeck";
import { useTranslation } from "react-i18next";

const Projects = ({ projects, currentLang }) => {
  const { t } = useTranslation();

  const [projectsArr, setProjectsArr] = React.useState([]);
  React.useEffect(() => {
    if (projects !== null && projects !== undefined) {
      if (projects.length > 0) {
        setProjectsArr(projects);
      }
    }
  }, [projects]);

  const projectsMap = projectsArr.map((proj, index) => (
    <Proj
      project={proj}
      key={index}
      location={proj.name}
      currentLang={currentLang}
      id={`Projects/${proj.name["en"]}`}
      details={false}
    />
  ));

  return (
    <div className="bgImage">
      {" "}
      <div className="project-main">
        <div className="project-text-header"></div>
        <div className="Projects">
          {}
          {projectsArr.length !== 0 ? (
            <CardDeck className="carddeck-div">{projectsMap}</CardDeck>
          ) : (
            <>
              <h2>{t("NoProject")}</h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
