import React from "react";
import WeekendOutlinedIcon from "@material-ui/icons/WeekendOutlined";
import { BiBed } from "react-icons/bi";
import { BiBath } from "react-icons/bi";
import { RiParkingLine } from "react-icons/ri";
import "./projFigure.css";

export default function Figure({ figure, index, lang }) {
  const alphabet = {
    ar: [
      "أ",
      "ب",
      "ج",
      "د",
      "ه",
      "و",
      "ز",
      "ح",
      "ط",
      "ي",
      "ك",
      "ل",
      "م",
      "ن",
      "س",
      "ع",
      "ف",
      "ص",
      "ق",
      "ر",
      "ش",
      "ت",
      "ث",
      "خ",
      "ذ",
      "ض",
      "ظ",
      "غ",
    ],
    en: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
  };

  const figure_ar_en = {
    ar: "نموذج",
    en: "Figure",
  };

  const type = {
    ar: ["شقة", "ملحق"],
    en: ["appartment", "roof"],
  };

  return (
    <div className="projFigure">
      <p className="projFigureTitle">{`${figure_ar_en[lang]} ${alphabet[lang][index]}`}</p>
      <div className="projFigureDetails">
        {figure.size ? (
          <p>
            {" "}
            {lang === "ar" ? <>م²</> : <>&#13217;</>} {figure.size}{" "}
          </p>
        ) : null}
        {figure.rooms ? (
          <p>
            {" "}
            <BiBed /> {figure.rooms}
          </p>
        ) : null}

        {figure.livingRooms ? (
          <p>
            <WeekendOutlinedIcon /> {`${figure.livingRooms} `}
          </p>
        ) : null}

        {figure.bathRooms ? (
          <p>
            <BiBath /> {figure.bathRooms}
          </p>
        ) : null}

        {figure.parking ? (
          <p>
            <RiParkingLine /> {figure.parking}
          </p>
        ) : null}

        <p>{figure.extra ? `${type[lang][1]}` : `${type[lang][0]}`}</p>
      </div>
    </div>
  );
}
