import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import "./mybooking/mybooking.css";
import { updateMybookingTable } from "../../api";
import { Button, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { arSD } from "@material-ui/data-grid";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { TiExportOutline } from "react-icons/ti";
import { CSVLink } from "react-csv";

import ReactExport from "@ibrahimrahmani/react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function getThemePaletteMode(palette) {
  return palette.type || palette.mode;
}

const defaultTheme = createTheme();
const useStyles = makeStyles({
  root: {
    "& .contractRender": {
      lineHeight: "unset !important",
      fontSize: "12px",
    },
    "& .contractRender p": {
      textAlign: "center !important",
    },
  },
});

let rows2 = "";

function QuickSearchToolbar(props) {
  const classes = useStyles();

  let headers = [
    { label: "إسم المشروع", key: "projectName" },
    { label: "رقم المبنى", key: "buildingNumber" },
    { label: "رقم الشقة التسلسلي", key: "no_apartment" },
    { label: "رقم الشقة الجديد", key: "secondAppartmentNumber" },
    { label: "مساحة الشقة", key: "space" },
    { label: "اسم العميل", key: "client_name" },
    { label: "هوية العميل", key: "clientID" },
    { label: "جوال العميل", key: "client_phone" },
    { label: "الحالة", key: "state" },
    { label: "العربون", key: "deposit" },
    { label: "المرتجع من العربون", key: "depositReturn" },
    { label: "حالة التصرفات العقارية", key: "VATState" },
    { label: "السعر", key: "price" },
    { label: "قيمة التصرفات العقارية", key: "VAT" },
    { label: "الإجمالي مع الضريبة", key: "total" },
    { label: "اسم البنك", key: "bankName" },
    { label: "حالة البنك", key: "bankState" },
    { label: "رقم الصك", key: "instrument_No" },
    { label: "تاريخ الحجز", key: "date" },
    { label: "الموظف", key: "employe_name" },
    { label: "تقييم الوكيل", key: "done" },
    { label: "ملاحظات", key: "note" },
  ];

  let allRows = [];
  if (rows2 !== "") {
    rows2.forEach((e) => {
      let tempArr = [];
      tempArr.push(e.projectName);
      tempArr.push(e.buildingNumber);
      tempArr.push(e.no_apartment);
      tempArr.push(e.secondAppartmentNumber);
      tempArr.push(e.space);
      tempArr.push(e.client_name);
      tempArr.push(e.clientID);
      tempArr.push(e.client_phone);
      tempArr.push(e.state);
      tempArr.push(e.deposit);
      tempArr.push(e.depositReturn);
      tempArr.push(e.VATState);
      tempArr.push(e.price);
      tempArr.push(e.VAT);
      tempArr.push(e.total);
      tempArr.push(e.bankName);
      tempArr.push(e.bankState);
      tempArr.push(e.instrument_No);
      tempArr.push(e.date);
      tempArr.push(e.employe_name);
      tempArr.push(e.done);
      tempArr.push(e.note);
      allRows.push(tempArr);
    });
  }

  // console.log(allRows);
  const multiDataSet = [
    {
      columns: [
        {
          value: "إسم المشروع",
          widthPx: 70,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "رقم المبنى",
          widthCh: 60,
          style: { font: { sz: "14", bold: true } },
        }, // width in charachters
        {
          value: "رقم الشقة التسلسلي",
          widthPx: 80,
          widthCh: 20,
          style: { font: { sz: "14", bold: true } },
        }, // will check for width in pixels first
        {
          value: "رقم الشقة الجديد",
          widthPx: 80,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "مساحة الشقة",
          widthPx: 60,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "اسم العميل",
          widthPx: 50,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "هوية العميل",
          widthPx: 50,
          style: { font: { sz: "14", bold: true } },
        }, // width in charachters
        { value: "جوال العميل", widthPx: 70, style: { font: { bold: true } } }, // width in pixels
        {
          value: "الحالة",
          widthPx: 50,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "العربون",
          widthPx: 50,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "المرتجع من العربون",
          widthPx: 80,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "حالة التصرفات العقارية",
          widthPx: 100,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "السعر",
          widthPx: 50,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "قيمة التصرفات العقارية",
          widthPx: 100,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "الإجمالي مع الضريبة",
          widthPx: 100,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "اسم البنك",
          widthPx: 70,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "حالة البنك",
          widthPx: 70,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "رقم الصك",
          widthPx: 100,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "تاريخ الحجز",
          widthPx: 80,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "الموظف",
          widthPx: 80,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "تقييم الوكيل",
          widthPx: 80,
          style: { font: { sz: "14", bold: true } },
        }, // width in pixels
        {
          value: "ملاحظات",
          widthPx: 150,
          style: {
            font: { sz: "14", bold: true },
            alignment: { horizontal: "center", vertical: "center" },
          },
        }, // width in pixels
      ],
      data: allRows,
    },
  ];
  return (
    <div className="search_exportDiv">
      <Button className="showAllDataButton" onClick={props.onClick}>
        {!props.openData ? "عرض البيانات" : "إخفاء البيانات"}
      </Button>
      <div className={`${classes.root} searchDiv`}>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="بحث"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
      <div>
        <ExcelFile
          element={
            <Button>
              {" "}
              <TiExportOutline /> تصدير
            </Button>
          }
          filename="المبيعات"
        >
          <ExcelSheet
            dataSet={multiDataSet}
            name="المبيعات والحجوزات"
          ></ExcelSheet>
        </ExcelFile>
      </div>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  rows2: PropTypes.string.isRequired,
  openData: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function SearchBar({
  allData,
  columns,
  data,
  setData,
  currentUserData,
  from,
  updateRows,
  setSelectedProject,
  setSelectedBuilding,
  setSelectedAppartement,
  setSelectedAppartmentState,
  setBankName,
  setBankState,
  setDone,
  changeSearch,
  setSearch,
  handelShowAllData,
}) {
  const [rows, setRows] = React.useState(allData);
  const [searchText, setSearchText] = React.useState("");
  const [refresh, setRefresh] = React.useState(1);
  const [openData, onClick] = React.useState(false);

  React.useEffect(() => {
    setRows(allData);
    rows2 = allData;
    setRefresh(refresh + 1);
  }, [allData.length !== 0]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = allData.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]);
      });
    });
    rows2 = filteredRows;
    setRows(filteredRows);
  };

  React.useEffect(() => {
    if (changeSearch) {
      requestSearch(searchText);
      setSearch(false);
    }
  }, [changeSearch]);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      let newID = id;

      let temp = {};
      allData.forEach((e, index) => {
        if (e.id == id) {
          temp = e;
        }
      });

      if (field === "bankState") {
        switch (parseInt(value)) {
          case 1:
            value = "تحت الإجراء";
            break;
          case 2:
            value = "وقع العقود";
            break;
          case 3:
            value = "تقييم أول";
            break;
          case 4:
            value = "تقييم ثاني";
            break;
          case 5:
            value = "صدر الشيك";
            break;
          case 6:
            value = "تم الإفراغ";
            break;
          case 7:
            value = "كاش";
            break;
          default:
            value = temp[field];
        }
      }

      if (field === "done") {
        switch (parseInt(value)) {
          case 0:
            value = "لم يتم الإفراغ";
            break;
          case 1:
            value = "تم الإفراغ";
            break;
          default:
            value = temp[field];
        }
      }

      if (field === "checkDate") {
        let date = new Date(value);
        value =
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear();
      }

      temp[field] = value;
      let DD = data;

      DD[temp.projectName][temp.buildingNumber].forEach((e, index) => {
        if (e.id == newID) {
          newID = index;
        }
      });

      DD[temp.projectName][temp.buildingNumber][newID][field] = value;
      updateMybookingTable(
        temp.projectName,
        temp.buildingNumber,
        newID,
        field,
        value,
        currentUserData.name
      );
      setData(DD);
      requestSearch(searchText);
      // updateRows(DD);
    },
    [rows]
  );

  const handelOnClick = () => {
    onClick(!openData);
    handelShowAllData(!openData);
  };

  const classes = useStyles();
  return (
    <div style={{ width: "100%" }} className={`${classes.root} dataTableDiv`}>
      <input type="hidden" value={refresh} />

      <DataGrid
        rows={rows}
        columns={columns}
        components={{ Toolbar: QuickSearchToolbar }}
        autoHeight={true}
        onCellEditCommit={handleCellEditCommit}
        onCellClick={(e) => {
          let project = e.row;
          if (
            currentUserData.isAlwaqil &&
            project.done === "تم الإفراغ" &&
            !currentUserData.isAdmin &&
            !currentUserData.isOwner
          ) {
            setSelectedProject();
            setSelectedBuilding();
            setSelectedAppartement();
            setSelectedAppartmentState();
            setBankName();
            setBankState();
            setDone();
          } else {
            setSelectedProject(project.projectName);
            setSelectedBuilding(project.buildingNumber);
            setSelectedAppartement(project.no_apartment);
            setSelectedAppartmentState(project.state);
            setBankName(project.bankName);
            setBankState(project.bankState);
            setDone(project.done);
          }
        }}
        hideFooter
        showCellRightBorder
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
            openData: openData,
            onClick: () => handelOnClick(!openData),
          },
        }}
        localeText={arSD.props.MuiDataGrid.localeText}
        isCellEditable={(e) => {
          if (
            (e.row.done === "تم الإفراغ" &&
              (currentUserData.isAdmin || currentUserData.isOwner) &&
              from === "الوكيل") ||
            e.row.done !== "تم الإفراغ"
          )
            return false; // was True
          return false;
        }}
        disableColumnResize
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        getCellClassName={(params) => {
          if (params.field === "contract") {
            // console.log(params.row);
            return params.row.contract !== undefined ? "contractRender" : null;
          }
        }}
      />
    </div>
  );
}
