import React, { Component } from "react";
import Message from "./Message";
import { deleteMessageByID, getAllMessage } from "../api";

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      check: false,
      first: true,
      user: "",
    };
  }
  getAllMessages = () => {
    getAllMessage()
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((err) => {
        console.log("ERR: ", err);
      });
  };

  deleteAllMessages = () => {
    this.state.data.forEach((e) => {
      deleteMessageByID(e._id);
    });
  };

  funcSetMessages = (newMessages) => {
    this.setState({ data: newMessages });
  };

  componentDidMount() {
    this.getAllMessages();
  }

  render() {
    return (
      <div className="message-main-div">
        <div className="messages-div">
          {/* <h1>{this.state.user}</h1> */}
          {/* <button className="get-messages-button" onClick={this.getAllMessages}>
            اضغط لتحديث الرسائل
          </button> */}

          {/* <button
            className="delAll-messages-button"
            onClick={this.deleteAllMessages}
          >
            لحذف جميع الرسائل
          </button> */}
          <div className="messages">
            <Message
              allMessages={this.state.data}
              setMessages={this.funcSetMessages}
              currentUserData={this.props.currentUserData}
            />
          </div>
        </div>
      </div>
    );
  }
}
