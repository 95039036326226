import React from "react";
import Card from "react-bootstrap/Card";
import { deleteProjectByID } from "../../api";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export default function Projects(props) {
  const handelDeleteProject = (e, id, name) => {
    deleteProjectByID(id, name, props.currentUserData.name);
    props.deleteProject(e, props.index);
    swal("تم", "الحذف بنجاح", "success");
  };

  const history = useHistory();

  return (
    <div className="project-div">
      <div className="dashboard-Projects">
        <Card
          className="card-div"
          onClick={(e) => {
            history.push(`/ControlPanel/${props.project.name["en"]}`);
          }}
        >
          <p>{props.project.name["ar"]}</p>
        </Card>
      </div>
      <div className="deleteProject">
        <Card className="cardDelete-div">
          <p
            onClick={(e) => {
              if (
                window.confirm(
                  `هل انت متأكد من حذف مشروع ${props.project.name["ar"]}`
                )
              ) {
                handelDeleteProject(
                  e,
                  props.project._id,
                  props.project.name["en"]
                );
              }
            }}
          >
            حذف
          </p>
        </Card>
      </div>
    </div>
  );
}
