import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AOS from "aos";
import state from "./state.png";
import { BiMap } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Figure from "./Figure";

export default function Proj(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleClick = (e, project) => {
    e.preventDefault();

    if ("project" in project && project.project.length !== 0)
      history.push(props.id);
    else if (!("project" in project) && project.images.length !== 0)
      history.push(props.id);
  };

  // handel location >> open google map location for project
  const handelLocationClick = (e, location) => {
    e.preventDefault();
    // window.location.href = location;
    window.open(location, "_blank");
    // history.push(location);
  };

  AOS.init();
  const project = props.project;
  let animationDirection = props.location % 2 === 1 ? true : false;
  let details = [];
  if (props.details) {
    project.details[props.currentLang === "ar" ? "ar" : "en"].forEach((e) => {
      details.push(e);
    });
  }
  return (
    <Router>
      <div className="Proj">
        <div className="div-image">
          <Card
            className="card-div"
            data-aos={`zoom-in-${animationDirection ? "left" : "right"}`}
          >
            <Card.Img
              className={`${"project-image"} ${
                props.details !== false
                  ? project.figures.length === 0
                    ? "project-image-without-figures"
                    : null
                  : null
              }`}
              variant="top"
              src={project.img}
              loading="lazy"
              onClick={(e) => handleClick(e, project)}
            />

            <div className="state-div">
              <img className="state-img" src={state} alt="" />

              <p>
                {" "}
                {props.currentLang === "ar"
                  ? project.state["ar"]
                  : project.state["en"]}
              </p>
            </div>
            <Card.Body>
              <Card.Text
                className="cardText"
                dir={props.currentLang === "ar" ? "rtl" : "ltr"}
              >
                {props.currentLang === "ar"
                  ? "figures" in project
                    ? `${project.name["ar"]} غرف`
                    : `${project.name["ar"]}`
                  : "figures" in project
                  ? `${project.name["ar"]} rooms`
                  : `${project.name["en"]}`}
              </Card.Text>
            </Card.Body>
            <small
              className="proj-details"
              dir={props.currentLang === "ar" ? "rtl" : "ltr"}
            >
              {props.details === false ? (
                <div
                  className="proj-location"
                  onClick={(e) => handelLocationClick(e, project.location)}
                >
                  {t("location")} <BiMap />
                </div>
              ) : (
                <>
                  {/* <p>{details[0]}</p>
                  <p>{details[1]}</p>
                  <p>{details[2]}</p> */}

                  {project.figures.map((e, index) => (
                    <Figure
                      figure={e}
                      key={index}
                      index={index}
                      lang={props.currentLang}
                    />
                  ))}
                </>
              )}
            </small>
          </Card>
        </div>
      </div>
    </Router>
  );
}
