import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { updateUserData } from "../../api";
import { updateMessage } from "../Booking/Functions";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default function UsersData({ open3, handleClose, editData }) {
  const [state, setState] = React.useState({
    Admin: false,
    Alwaqil: false,
    SalesMan: false,
    Service: false,
  });
  const [isAdmin, setIsAdmin] = React.useState();
  const [isAlwaqil, setIsAlwaqil] = React.useState();
  const [isSaler, setIsSaler] = React.useState();
  const [isWorker, setIsWorker] = React.useState();

  React.useEffect(() => {
    setState({
      ...state,
      Admin: editData.isMiniAdmin,
      Alwaqil: editData.isAlwaqil,
      SalesMan: editData.isSaler,
      Service: editData.isWorker,
    });
    setIsAdmin(editData.isMiniAdmin);
    setIsAlwaqil(editData.isAlwaqil);
    setIsSaler(editData.isSaler);
    setIsWorker(editData.isWorker);
  }, [editData]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { Admin, Alwaqil, SalesMan, Service } = state;

  const Buttons = () => {
    return (
      <div className="buttons">
        <button
          className="btn btn-primary next save"
          onClick={(e) => {
            //   handelUpdate();
            editData.isMiniAdmin = Admin;
            editData.isAlwaqil = Alwaqil;
            editData.isSaler = SalesMan;
            editData.isWorker = Service;
            delete editData.id;
            // delete editData.lastSeen;
            // delete editData.email;
            updateUserData(editData);
            updateMessage("edit");
            handleClose();
          }}
        >
          حفظ
        </button>

        <button
          className="btn btn-primary past cancel"
          onClick={(e) => {
            setState({
              ...state,
              Admin: isAdmin,
              Alwaqil: isAlwaqil,
              SalesMan: isSaler,
              Service: isWorker,
            });
            handleClose();
          }}
        >
          إلغاء
        </button>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open3}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="bookingDataEdite"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <div className="addBookingTab">
            <div className="tabsContent myBookingData previlage">
              <TextField
                id="standard-basic"
                label="إسم الموظف"
                value={editData.name}
                required
                disabled
              ></TextField>

              <TextField
                id="standard-basic"
                label="البريد الإلكتروني للموظف"
                value={editData.email}
                required
                disabled
              ></TextField>

              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="Admin" />}
                    label="ادمن"
                    labelPlacement="top"
                    checked={Admin}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="Alwaqil" />}
                    label="الوكيل"
                    labelPlacement="top"
                    checked={Alwaqil}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="SalesMan" />}
                    label="بائع"
                    labelPlacement="top"
                    checked={SalesMan}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={<Checkbox name="Service" />}
                    label="الصيانة"
                    labelPlacement="top"
                    checked={Service}
                    onChange={handleChange}
                  />
                </FormGroup>
              </FormControl>
              <Buttons />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
