import React, { useEffect } from "react";
import "./home.css";
import Form from "react-bootstrap/Form";
import RenderTables from "./RenderTables";

export default function Home(props) {
  const [allProjects, setAllProjects] = React.useState([]);
  const [currentProject, setCurrentProject] = React.useState("الكل");
  const [currentBuilding, setCurrentBuilding] = React.useState("الكل");
  const [projectBuildings, setProjectBuildings] = React.useState([]);
  const [projectTable, setProjectTable] = React.useState([]);
  const [buildingTable, setBuildingTable] = React.useState([]);

  useEffect(async () => {
    let tempProjects = props.projects;
    props.projects.map((project, key) => {
      let temp = {};
      Object.keys(project["booking"]).map((building, key2) => {
        if (project["booking"][building].length !== 0) {
          temp[building] = project["booking"][building];
        }
      });
      tempProjects[key]["booking"] = temp;
    });
    await setAllProjects(tempProjects);
  }, [props.projects]);

  let lastKey = 0;
  const renderTable =
    currentProject === "الكل" ? (
      allProjects.map((project, key) => {
        let lastOne = allProjects.length - 1;
        while (lastOne > 0) {
          if (Object.keys(allProjects[lastOne]["booking"]).length !== 0) {
            break;
          }
          lastOne = lastOne - 1;
        }

        return Object.keys(project["booking"]).map((building, key2) => (
          <RenderTables
            name={key2 === 0 ? `مشروع ${project.name.ar}` : ""}
            building={building}
            data={project["booking"][building]}
            lastBuilding={
              Object.keys(project["booking"]).length - 1 == key2 &&
              lastOne !== key
                ? true
                : false
            }
            key={key2}
          />
        ));
      })
    ) : currentBuilding === "الكل" ? (
      Object.keys(
        allProjects.filter((e, key) => e.name.ar === currentProject)[0][
          "booking"
        ]
      ).map((building, key2) => (
        <RenderTables
          name={""}
          building={building}
          data={
            allProjects.filter((e, key3) => e.name.ar === currentProject)[0][
              "booking"
            ][building]
          }
          key={key2}
        />
      ))
    ) : (
      <RenderTables
        name={""}
        building={currentBuilding}
        data={
          allProjects.filter((e, key) => e.name.ar === currentProject)[0][
            "booking"
          ][currentBuilding]
        }
      />
    );

  return (
    <div className="reportDiv">
      <h5 className="projectSelect">اختر المشروع</h5>
      <Form.Control
        className="buildingOption"
        required
        as="select"
        onChange={(e) => {
          setCurrentProject(e.target.value);
          setCurrentBuilding("الكل");
        }}
      >
        <option value="الكل" key={-1}>
          الكل
        </option>
        {allProjects.map((e, key) => {
          return (
            <option value={e.name["ar"]} key={key}>
              {e.name["ar"]}
            </option>
          );
        })}
      </Form.Control>

      {currentProject !== "الكل" ? (
        Object.keys(
          allProjects.filter((ee, key) => ee.name["ar"] === currentProject)[0][
            "booking"
          ]
        ).length ? (
          <>
            {" "}
            <h5 className="projectSelect">اختر المبنى</h5>
            <Form.Control
              className="buildingOption"
              required
              as="select"
              value={currentBuilding}
              onChange={(e) => {
                setCurrentBuilding(e.target.value);
              }}
            >
              <option value="الكل" key={-1}>
                الكل
              </option>
              {Object.keys(
                allProjects.filter(
                  (ee, key) => ee.name["ar"] === currentProject
                )[0]["booking"]
              ).map((e, key2) => {
                return (
                  <option value={e} key={key2}>
                    {e}
                  </option>
                );
              })}
            </Form.Control>
          </>
        ) : (
          <h5 className="noBookingNoSold">
            لايوجد اي عمليات حجز أو بيع في هذا المشروع
          </h5>
        )
      ) : null}

      {renderTable}
    </div>
  );
}
