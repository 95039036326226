import React from "react";
// import Gallery from "./Gallery";
import Proj from "./Proj";
// import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { getDataID } from "../api";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Projects = (props) => {
  const [projectsArr, setProjectsArr] = React.useState([]);
  const { t } = useTranslation();

  let history = useHistory();

  React.useEffect(() => {
    getDataID(props.match.params.id).then((e) => {
      setProjectsArr(e[0].project);
    });
  }, [props.projects]);

  const projectsMap = projectsArr.map((proj, index) => (
    <Proj
      project={proj}
      key={index}
      location={proj.name}
      currentLang={props.currentLang}
      id={`${props.match.params.id}/${proj._id}`}
      details={true}
      // handleGallery={this.handleGallery}
    />
  ));

  const handelButtonClicked = () => {
    history.push(`/Projects`);
  };

  return (
    <div className="bgImage">
      {" "}
      <div className="project-main">
        <button
          onClick={() => {
            handelButtonClicked();
          }}
        >
          {<AiOutlineArrowLeft />} {t("gallery.btn")}
        </button>
        <div className="project-text-header"></div>
        <div className="Projects">
          <CardDeck className="carddeck-div">{projectsMap}</CardDeck>
        </div>
        {/* {map} */}
      </div>
    </div>
  );
};

export default Projects;
