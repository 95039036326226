import React, { Component } from "react";
import { insertOneMessage, getEmailJsData } from "../api";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import swal from "sweetalert";

import { withTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

class Services extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      email: "",
      mobile: "",
      location: "",
      text: "",
      notification: false,
      show: false,
      error_name: "",
      error_number: "",
      error_location: "",
      error_text: "",
      recaptcha: true,
      validated: false,
      locations: [],
    };
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleMobileChange = (e) => {
    this.setState({ mobile: e.target.value });
  };

  handelLocationChange = (e) => {
    this.setState({ location: e.target.value });
  };

  handleMessageChange = (e) => {
    this.setState({ text: e.target.value });
  };

  insertToDB = () => {
    let body = {
      name: this.state.name,
      email: this.state.email,
      mobileNumber: this.state.mobile,
      location: this.state.location,
      message: this.state.text,
      isDone: "false",
      bookingBy: "false",
    };

    // insert to database by calling post method in fron-end
    insertOneMessage(body);
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    this.setState({ ...this.state, validated: true });

    const templateParams = {
      from_name: this.state.name,
      message: this.state.text,
      email: this.state.email,
      mobile: this.state.mobile,
      location: this.state.location,
    };

    if (
      this.state.name !== "" &&
      (this.state.mobile.length === 10 ||
        this.state.mobile.length === 12 ||
        this.state.mobile.length === 13) &&
      this.state.text !== ""
    ) {
      this.insertToDB();
      let userID = "";
      let serviceID = "";
      let templateID = "";
      getEmailJsData("services").then((res) => {
        userID = res.data.USERID;
        serviceID = res.data.SERVICEID;
        templateID = res.data.TEMPLATEID;
        emailjs.send(serviceID, templateID, templateParams, userID).then(
          (response) => {
            swal("Good job!", "Message Sent", "success").then((ok) => {
              if (ok) {
                this.setState({
                  name: "",
                  email: "",
                  mobile: "",
                  location: "",
                  text: "",
                  notification: false,
                  show: false,
                  error_name: "",
                  error_number: "",
                  error_location: "",
                  error_text: "",
                  validated: false,
                });
                // window.location.reload();
              }
            });
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
      });
    }
  };
  componentDidMount() {
    let locations = [];

    this.props.projects.forEach((e) => {
      if (e.isService) {
        locations.push(e.name);
      }
    });
    this.setState({ ...this.state, locations });
  }
  render() {
    return (
      <div className="service-header">
        {/* {this.state.show ? <this.AlertDismissibleExample /> : null} */}

        <div className="services-div bgImage">
          <Form
            noValidate
            validated={this.state.validated}
            className={`Form ${
              this.props.i18n.language === "ar" ? "service-ar" : "service-en"
            }`}
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Form.Group controlId="validationCustom01">
              <Form.Label className="textLabel">
                {this.props.t("services.input1")}
              </Form.Label>
              <Form.Control
                required
                // id="name"
                type="text"
                name="name"
                placeholder={this.props.t("services.place1")}
                onChange={(e) => this.handleNameChange(e)}
                value={this.state.name}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label className="textLabel">
                {this.props.t("services.input2")}
              </Form.Label>
              <Form.Control
                required
                type="tel"
                name="phone"
                placeholder={this.props.t("services.place2")}
                onChange={(e) => this.handleMobileChange(e)}
                value={this.state.mobile}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom03">
              <Form.Label className="textLabel">
                {this.props.t("services.input3")}
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder={this.props.t("services.place3")}
                onChange={(e) => this.handleEmailChange(e)}
                value={this.state.email}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom04">
              <Form.Label className="textLabel">
                {this.props.t("services.input4")}
              </Form.Label>
              <Form.Control
                required
                as="select"
                placeholder={this.props.t("services.place4")}
                onChange={(e) => this.handelLocationChange(e)}
                value={this.state.location}
                dir={`${this.props.i18n.language === "ar" ? "rtl" : "ltr"}`}
              >
                {this.state.locations.map((e, key) => {
                  return (
                    <option value={e[this.props.i18n.language]} key={key}>
                      {e[this.props.i18n.language]}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1 validationCustom05">
              <Form.Label className="textLabel">
                {this.props.t("services.input5")}
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={7}
                onChange={(e) => this.handleMessageChange(e)}
                value={this.state.text}
              />
            </Form.Group>
            {this.state.recaptcha ? (
              <Button className="send-button" variant="primary" type="submit">
                {this.props.t("services.btn")}
              </Button>
            ) : (
              <ReCAPTCHA
                sitekey="6LemhKcaAAAAALwIj1RYYUYt1qHBXvUD7jvJeZgZ"
                onChange={(e) =>
                  this.setState({ ...this.state, recaptcha: true })
                }
              />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Services);
