import React from "react";
import "./homepage.css";
import TableExcel from "./TableExcel";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Proj from "./Proj";
import CardDeck from "react-bootstrap/CardDeck";

const Homepage = ({ projects, currentUserData }) => {
  const [allProjects, setAllProjects] = React.useState([]);
  const [projectSelected, setProjectSelected] = React.useState(false);
  const [projectSelectedData, setProjectSelectedData] = React.useState([]);
  const [buildingSelected, setBuildingSelected] = React.useState(null);
  const [buildingArray, setBuildingArray] = React.useState({});
  const [currentProject, setCurrentProject] = React.useState({});

  const temp_arr = [];
  const [refresh, setRefresh] = React.useState(1);
  let pSelected = null;

  const ref = React.useRef();

  const newForLoop = (e) => {
    let temp_arr = [];
    for (let i = 1; i <= e; i++) {
      temp_arr.push(i);
    }
    setBuildingArray(temp_arr);
  };

  const handelBuildingNumber = (e) => {
    let temp = allProjects.filter((ee) => {
      return ee.name["ar"] == e;
    });
    newForLoop(temp[0].no_Buildings);
    setProjectSelectedData([temp[0]]);
  };

  const handelSelectChange = (e) => {
    if (e !== "") {
      setProjectSelected(e);
      handelBuildingNumber(e);
    } else {
      setProjectSelected(false);
    }
  };

  const handelNewBooking = (e) => {
    setCurrentProject(e);
    window.history.replaceState({}, null, "/newBooking");
  };

  React.useEffect(() => {
    if (projects.length > 0) {
      const activeProject = projects.filter((e) => e.state["en"] !== "Sold");
      setAllProjects(activeProject);
    }
    /*
    setTimeout(() => {
      ref.current.innerHTML = "Test";
      console.log(ref.current);
    }, 5000);
    */
  }, [projects]);

  const projectsMap = allProjects.map((proj, index) => (
    <Proj project={proj} key={index} handelNewBooking={handelNewBooking} />
  ));

  return (
    <div className="homepage">
      <input type="hidden" value={refresh} />
      <h2 style={{ textAlign: "center" }}>المبيعات</h2>
      <p style={{ direction: "rtl", textAlign: "center" }}>
        اهلا{" "}
        <span style={{ color: "rgb(6 105 69)" }}>{currentUserData.name}</span>
      </p>

      <div>
        <div className="project-main">
          <div className="project-text-header"></div>
          <div className="bookingProjects">
            {}
            {allProjects.length !== 0 ? (
              <CardDeck className="carddeck-div">{projectsMap}</CardDeck>
            ) : (
              <>
                <h2>{"لاتوجد مشاريع حاليا"}</h2>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
