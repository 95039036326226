import React from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import "./createContract.css";
import { handelUpdateData } from "../Booking/Functions";
import CurrencyFormat from "react-currency-format";
import { tafqeet } from "./Tafqeet";
import { getAndUpdateContractID } from "../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function CreateContract({
  openContract,
  setOpenContract,
  editData,
  data,
  setData,
  allData,
  setAllData,
  setSearch,
  refresh,
  setRefresh,
  currentUserData,
}) {
  const [day, setDay] = React.useState("الثلاثاء");
  const [firstDateAr, setFirstDateAr] = React.useState("10/10/1443");
  const [firstDateEn, setFirstDateEn] = React.useState("10/10/2021");
  const [clientName, setClientName] = React.useState("نجوى احمد علي الغامدي");
  const [nationality, setNationality] = React.useState("سعودية");
  const [clientID, setClientID] = React.useState("1111111111");
  const [mobileNumber, setMobileNumber] = React.useState("0555555555");
  const [apartment, setApartment] = React.useState("1/أ");
  const [direction, setDirection] = React.useState("الجنوبية الغربية");
  const [floor, setFloor] = React.useState("الدور الأرضي");
  const [size, setSize] = React.useState("244.66");
  const [landNumber, setLandNumber] = React.useState("43");
  const [scheme, setScheme] = React.useState("444/ج/س/المعدل");
  const [district, setDistrict] = React.useState("الورود");
  const [instrument, setInstrument] = React.useState("320222005420");
  const [instrumentDate, setInstrumentDate] = React.useState("16/12/1442");
  const [guardsmanApartment, setGuardsmanApartment] = React.useState("1");
  const [parkingNumber, setParkingNumber] = React.useState("1");
  const [tankNumber, setTankNumber] = React.useState("1");
  const [priceNumbers, setPriceNumbers] = React.useState("960,000");
  const [priceWords, setPriceWords] = React.useState("تسعمائة وستون الف");
  const [checkNumber, setCheckNumber] = React.useState("00636179");
  const [bankName, setBankName] = React.useState("البلاد");
  const [checkDate, setCheckDate] = React.useState("12/09/2021");
  const [deliveryTime, setDeliveryTime] = React.useState("14/2/1443");
  const [type, setType] = React.useState("التمديد المدفون");
  const [years, setYears] = React.useState("سنتين");
  const [years2, setYears2] = React.useState("عشر سنوات");
  const [nameFirst, setNameFirst] = React.useState("نواف بن سعيد محمد الغامدي");
  const [nameSecond, setNameSecond] = React.useState("نجوى احمد علي الغامدي");

  React.useEffect(() => {
    let days = [
      "الأحد",
      "الإثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ];
    let floors = {
      أ: "الدور الأرضي",
      1: "الدور الأول",
      2: "الدور الثاني",
      3: "الدور الثالث",
      4: "الدور الرابع",
      5: "الدور الخامس",
      6: "الدور السادس",
      7: "الدور السابع",
    };

    let today = new Date();
    let en_date =
      today.getFullYear() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getDate();
    let deliveryT_en =
      today.getFullYear() +
      1 +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getDate();
    let deliveryT_ar = new Date(deliveryT_en).toLocaleDateString("ar-SA");
    deliveryT_ar = deliveryT_ar.split("");
    deliveryT_ar.pop();
    deliveryT_ar.pop();
    deliveryT_ar.pop();
    deliveryT_ar = deliveryT_ar.join("");
    let ar_date = new Date(en_date).toLocaleDateString("ar-SA");
    ar_date = ar_date.split("");
    ar_date.pop();
    ar_date.pop();
    ar_date.pop();
    ar_date = ar_date.join("");
    setDay(days[today.getDay()]);
    setFirstDateAr(ar_date);
    setFirstDateEn(en_date);
    setClientName(editData.client_name);
    setNationality("سعودي");
    setClientID(editData.clientID);
    setMobileNumber(editData.client_phone);
    setApartment(editData.secondAppartmentNumber);
    setDirection("إتجاه الشقة");
    setFloor(floors[editData.secondAppartmentNumber[0]]);
    setSize(editData.space);
    setLandNumber("33");
    setScheme("444/ج/س/المعدل");
    setDistrict("الورود");
    setInstrument(editData.instrument_No);
    setInstrumentDate(editData.instrument_Date);
    setGuardsmanApartment(editData.no_apartment);
    setParkingNumber(editData.no_apartment);
    setTankNumber(editData.no_apartment);
    setPriceNumbers(editData.total);
    let tempT = tafqeet(editData.total);
    setPriceWords(tempT);
    setCheckNumber(editData.checkNumber);
    setBankName(editData.bankName);
    setCheckDate(editData.checkDate);
    setDeliveryTime(deliveryT_ar);
    setType("التمديد المدفون");
    setYears("سنتين");
    setYears2("عشر سنوات");
    setNameFirst("نواف بن سعيد محمد الغامدي");
  }, [editData]);

  const handelUpdate = async () => {
    let contract = {
      day: day,
      firstDateAr: firstDateAr,
      firstDateEn: firstDateEn,
      clientName: clientName,
      nationality: nationality,
      clientID: clientID,
      mobileNumber: mobileNumber,
      apartment: apartment,
      direction: direction,
      floor: floor,
      size: size,
      landNumber: landNumber,
      scheme: scheme,
      district: district,
      instrument: instrument,
      instrumentDate: instrumentDate,
      guardsmanApartment: guardsmanApartment,
      parkingNumber: parkingNumber,
      tankNumber: tankNumber,
      priceNumbers: priceNumbers,
      priceWords: priceWords,
      checkNumber: checkNumber,
      bankName: bankName,
      checkDate: checkDate,
      deliveryTime: deliveryTime,
      type: type,
      years: years,
      years2: years2,
      nameFirst: nameFirst,
    };

    let tempData = JSON.parse(JSON.stringify(data));
    let index = "";
    await tempData[editData.projectName][editData.buildingNumber].forEach(
      (e, key) => {
        if (e.no_apartment === editData.no_apartment) {
          index = key;
        }
      }
    );
    // console.log(index);
    tempData[editData.projectName][editData.buildingNumber][index]["contract"] =
      contract;

    let id = await getAndUpdateContractID();

    tempData[editData.projectName][editData.buildingNumber][index][
      "contractID"
    ] = id;

    // console.log(tempData[editData.projectName][editData.buildingNumber][index]);

    let index2 = -1;
    await allData.forEach((e, key) => {
      if (
        e.projectName === editData.projectName &&
        e.buildingNumber === editData.buildingNumber &&
        e.no_apartment === editData.no_apartment
      ) {
        index2 = key;
      }
    });
    if (index2 > -1) {
      let tempAllData = JSON.parse(JSON.stringify(allData));
      tempAllData[index2] =
        tempData[editData.projectName][editData.buildingNumber][index];
      tempAllData[index2]["projectName"] = editData.projectName;
      tempAllData[index2]["buildingNumber"] = editData.buildingNumber;

      let booking = tempData[editData.projectName][editData.buildingNumber];

      handelUpdateData(
        booking,
        tempAllData[index2],
        refresh,
        setRefresh,
        "createContract",
        currentUserData
      );
      setData(tempData);
      setAllData(tempAllData);
      setRefresh(refresh + 1);
      setSearch(true);
    }
  };

  const Buttons = () => {
    return (
      <div className="buttons">
        <button
          className="btn btn-primary next save"
          onClick={(e) => {
            handelUpdate();
            setOpenContract(false);
          }}
        >
          إنشاء
        </button>

        <button
          className="btn btn-primary past cancel"
          onClick={(e) => {
            setOpenContract(false);
          }}
        >
          إلغاء
        </button>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={openContract}
        onClose={(e) => setOpenContract(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="bookingDataEdite"
      >
        <Box className="mainBoxCreateContract" sx={{ ...style, width: "80%" }}>
          <h4 className="contractHeader">إنشاء عقد جديد</h4>
          <div>
            <TextField
              id="standard-basic"
              label="اليوم"
              required
              value={day}
              onChange={(e) => setDay(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="تاريخ اليوم هجري"
              required
              value={firstDateAr}
              onChange={(e) => setFirstDateAr(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              className="dateEnContract"
              label="تاريخ اليوم ميلادي"
              required
              value={firstDateEn}
              onChange={(e) => setFirstDateEn(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              className="clientNameContract"
              label="اسم العميل"
              required
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="الجنسية"
              required
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الهوية"
              required
              value={clientID}
              onChange={(e) => setClientID(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الجوال"
              required
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="الشقة"
              required
              value={apartment}
              onChange={(e) => setApartment(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="إتجاه الشقة"
              required
              value={direction}
              onChange={(e) => setDirection(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="الدور"
              required
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="مساحة الشقة"
              required
              value={size}
              onChange={(e) => setSize(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم قطعة الأرض"
              required
              value={landNumber}
              onChange={(e) => setLandNumber(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم المخطط"
              required
              value={scheme}
              onChange={(e) => setScheme(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="إسم الحي"
              required
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الصك"
              required
              value={instrument}
              onChange={(e) => setInstrument(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="تاريخ الصك"
              required
              value={instrumentDate}
              onChange={(e) => setInstrumentDate(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم غرفة الحارس"
              required
              value={guardsmanApartment}
              onChange={(e) => setGuardsmanApartment(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الموقف"
              required
              value={parkingNumber}
              onChange={(e) => setParkingNumber(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الخزان"
              required
              value={tankNumber}
              onChange={(e) => setTankNumber(e.target.value)}
            ></TextField>

            <div className="MuiFormControl-root MuiTextField-root">
              {" "}
              <label
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
                id="standard-basic-label"
              >
                سعر الشقة رقما
                <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  *
                </span>
              </label>
              <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                <CurrencyFormat
                  id="standard-basic"
                  className="MuiInputBase-input MuiInput-input"
                  // className="priceNumbers"
                  value={priceNumbers}
                  thousandSeparator={true}
                  //   prefix={"$"}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setPriceNumbers(formattedValue);
                    let tempNumbers = formattedValue.split("");

                    let newNumbers = tempNumbers.filter((e) => e !== ",");
                    let numberText = newNumbers.join("").toString();
                    let tempT = tafqeet(numberText);
                    setPriceWords(tempT);
                  }}
                />
              </div>
            </div>

            <TextField
              id="standard-basic"
              className="priceWordContract"
              label="سعر الشقة كتابتا"
              required
              value={priceWords}
              onChange={(e) => setPriceWords(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="رقم الشيك"
              required
              value={checkNumber}
              onChange={(e) => setCheckNumber(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="إسم البنك"
              required
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="تاريخ الشيك"
              required
              value={checkDate}
              onChange={(e) => setCheckDate(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="تاريخ تسليم الشقة"
              required
              value={deliveryTime}
              onChange={(e) => setDeliveryTime(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="الضمان على"
              required
              value={type}
              onChange={(e) => setType(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              label="لمدة"
              required
              value={years}
              onChange={(e) => setYears(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              className="warrantyContract"
              label="مدة ضمان أعمال العظمة"
              required
              value={years2}
              onChange={(e) => setYears2(e.target.value)}
            ></TextField>
            <TextField
              id="standard-basic"
              className="FirstPersonContract"
              label="الطرف الأول"
              required
              value={nameFirst}
              onChange={(e) => setNameFirst(e.target.value)}
            ></TextField>
          </div>
          <Buttons />
        </Box>
      </Modal>
    </div>
  );
}
