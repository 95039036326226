import React from 'react';
import './home.css';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@mui/x-data-grid';
import { arSD } from '@material-ui/data-grid';
import { AiOutlineEdit } from 'react-icons/ai';
import UsersData from './UsersData';
import { getUsersData, getUsersName, removeUser } from '../../api';
import AddUser from './AddUser';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import app from '../../base.js';
import { updateMessage } from '../Booking/Functions';
import { useTranslation } from 'react-i18next';

export default function Home({ isOwner }) {
  const [editData, setEditData] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [usersNames, setAllUsersNames] = React.useState([]);
  const [usersEmail, setAllUsersEmail] = React.useState([]);
  const { t, i18n } = useTranslation();

  const handleOpen = () => {
    setOpen3(true);
  };
  const handleClose = () => {
    setOpen3(false);
  };

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  let testRow = '';

  React.useEffect(() => {
    getUsersData(isOwner).then((e) => {
      testRow = e;
      setRows(e);
    });
    getUsersName().then((e) => {
      console.log(e);
      setAllUsersNames(e.allUsersName);
      setAllUsersEmail(e.allUsersEmail);
    });
  }, []);

  const columns = [
    {
      field: 'delete',
      headerName: 'حذف',
      flex: 0.5,
      renderCell: (params) => (
        <strong
          className="edit_delte_buttons"
          onClick={async (e) => {
            let clieckedData = '';
            clieckedData = params.row;
            if (
              window.confirm(
                `هل انت متاكد من رغبتك في حذف المستخدم ${clieckedData.name} ؟`
              )
            ) {
              let tempRows = rows;
              tempRows = tempRows.filter((e) => e.name !== clieckedData.name);

              let user = app;
              removeUser(clieckedData);
              setRows(tempRows);
              updateMessage('delete');
            }
            // setEditData(clieckedData);
            // handleOpen();
          }}
        >
          <IoIosRemoveCircleOutline
            style={{ justifyContent: 'center', fontSize: 'x-large' }}
          />
        </strong>
      )
    },
    {
      field: 'edit',
      headerName: 'تعديل',
      flex: 0.5,
      renderCell: (params) => (
        <strong
          className="edit_delte_buttons"
          onClick={async (e) => {
            let clieckedData = '';
            clieckedData = params.row;
            setEditData(clieckedData);
            handleOpen();
          }}
        >
          <AiOutlineEdit
            style={{ justifyContent: 'center', fontSize: 'x-large' }}
          />
        </strong>
      )
    },
    {
      field: 'lastSeen',
      headerName: 'اخر زيارة',
      flex: 1
    },
    {
      field: 'email',
      headerName: 'البريد الإلكتروني',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'الإسم',
      flex: 1
    }
  ];
  return (
    <div className="manageUsers">
      <div className="topManageUser">
        <h1>إدارة المستخدمين</h1>
        <p>
          يمكنك إضافة مستخدم جديد او تحديث بيانات مستخدم حالي مع إمكانية تعديل
          صلاحيات كل مستخدم وايضا بالإمكان حذف المستخدم.
        </p>

        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ justifyContent: 'center', fontSize: '16px' }}
          onClick={handleOpen4}
        >
          إضافة مستخدم جديد
        </Button>
      </div>
      {editData ? (
        <>
          {' '}
          <UsersData
            open3={open3}
            handleClose={handleClose}
            editData={editData}
          />
        </>
      ) : null}

      {open4 ? (
        <>
          {' '}
          <AddUser
            open4={open4}
            handleClose4={handleClose4}
            rows={rows}
            setRows={setRows}
            usersNames={usersNames}
            usersEmail={usersEmail}
          />
        </>
      ) : null}
      <div className="datagridManageUsers" style={{ paddingTop: '5%' }}>
        {rows.length > 0 ? (
          <>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter
              showCellRightBorder
              localeText={arSD.props.MuiDataGrid.localeText}
              disableColumnResize
              disableMultipleColumnsSorting
              disableMultipleColumnsFiltering
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
