import firebase from 'firebase';
import 'firebase/auth';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyDTjxAy1znt1cW8tAyvTgnr8O9yjm7uARg',
  authDomain: 'awtar-int.firebaseapp.com',
  projectId: 'awtar-int',
  storageBucket: 'awtar-int.appspot.com',
  messagingSenderId: '972779194774',
  appId: '1:972779194774:web:f23f97d24fa6238192b5b7',
  measurementId: 'G-5QHJBG7SWN'
});

export default app;
// new code after update v3.1
// firebase analytics
export const firebaseAnalytics = firebase.analytics();
