import API_URL from './apiConfig';
// const API_URL=require('./apiConfig')
import axios from 'axios';
// INDEX, SHOW, CREATE, UPDATE, DESTROY

// Get All Articles
const getAllMessage = () => {
  return axios.get(`${API_URL}/api/Messages`);
};

// Delete Article by ID
const deleteMessageByID = (id, user) => {
  return axios.delete(`${API_URL}/api/Messages/${id}/${user}`);
};

const updateMessageByID = (id, bookingBy, isDone, user) => {
  return axios.put(
    `${API_URL}/api/updateMessages/${id}/${bookingBy}/${isDone}/${user}`
  );
};

const insertOneMessage = (data) => {
  return axios.post(`${API_URL}/api/Messages/`, data);
};

const signIn = async (userName, password) => {
  // return axios.get(`${API_URL}/api/login/${userName}/${password}`);
  let body = {
    userName: userName,
    password: password
  };
  return await axios.post(`${API_URL}/api/login/`, body, {
    withCredentials: true
  });
};

const refreshToken = async () => {
  return fetch(`${API_URL}/api/refresh_token`, {
    withCredentials: true // Needed to include the cookie
  });
};

const Protected = async (headers) => {
  return await axios.post(`${API_URL}/api/protected`, {
    headers
  });
};

const logOutCallback = async () => {
  await fetch(`${API_URL}/api/logout`, {
    method: 'POST',
    credentials: 'include' // Needed to include the cookie
  });
};

const userName = async (refreshtoken) => {
  let body = {
    refreshtoken: refreshtoken
  };
  return await axios.post(`${API_URL}/api/userName/`, body, {
    withCredentials: true
  });
};

const getData = async () => {
  try {
    let { data } = await axios.get(`${API_URL}/api/getData`);
    return data.allData;
  } catch (error) {
    console.log('getDAta error: ', error);
  }
};

const getDataID = async (id) => {
  try {
    let { data } = await axios.get(`${API_URL}/api/getData/${id}`);
    return data.allData;
  } catch (error) {
    console.log(error);
  }
};

const getDataForGallery = async (name, id) => {
  try {
    let body = {
      name: name,
      id: id
    };

    let { data } = await axios.post(`${API_URL}/api/getDataForGallery`, body);

    return data.allData;
  } catch (error) {
    console.log('getdata', error);
  }
};

// Dashboard side

const adminsignIn = async (adminName, password) => {
  let body = {
    adminName: adminName,
    password: password
  };
  return await axios.post(`${API_URL}/api/dashboard/login/`, body, {
    withCredentials: true
  });
};

const deleteProjectByID = async (id, name, user) => {
  return await axios.delete(
    `${API_URL}/api/deleteProject/${id}/${name}/${user}`
  );
};

const addNewProject = async (project, user) => {
  return await axios.post(`${API_URL}/api/addProject`, {
    project: project,
    user: user
  });
};

const updateProjectToDB = async (projectName, projectData, user) => {
  return await axios.post(`${API_URL}/api/updateProject`, {
    projectName: projectName,
    projectData: projectData,
    user: user
  });
};

const getEmailJsData = async (template) => {
  return await axios.get(`${API_URL}/api/getEmailJsData/${template}`);
};

const getFireBaseData = async () => {
  return await axios.get(`${API_URL}/api/firebaseData/`);
};

const getBookingData = async (user, page) => {
  try {
    let { data } = await axios.get(
      `${API_URL}/api/getBookingData/${user.name}/${user.isOwner}/${user.isAdmin}/${user.isAlwaqil}/${page}`
    );
    return data;
  } catch (error) {
    console.log('getDAta error: ', error);
  }
};

const updateMyBooking = async (
  projectName,
  buildingNumber,
  appartmentNumber,
  newState,
  bankState,
  bankName,
  done,
  user
) => {
  return await axios.post(`${API_URL}/api/updateMyBooking`, {
    projectName: projectName,
    buildingNumber: buildingNumber,
    appartmentNumber: appartmentNumber,
    newState: newState,
    bankState: bankState,
    bankName: bankName,
    done: done,
    user: user
  });
};

const updateMyBooking2 = async (booking, data, user, type) => {
  return await axios.post(`${API_URL}/api/updateMyBooking2`, {
    booking: booking,
    data: data,
    user: user,
    type: type
  });
};

const insertIntoArchive = async (data, user) => {
  return await axios.post(`${API_URL}/api/insertIntoArchive`, {
    data: data,
    user: user
  });
};

const updateMybookingTable = async (
  projectName,
  buildingNumber,
  id,
  field,
  newValue,
  user
) => {
  return await axios.post(`${API_URL}/api/updateMybookingTable`, {
    projectName: projectName,
    buildingNumber: buildingNumber,
    id: id,
    field: field,
    newValue: newValue,
    user: user
  });
};

const checkPrivilage = async (userEmail) => {
  try {
    let userData = await axios.get(
      `${API_URL}/api/checkPrivilage/${userEmail}`
    );
    return userData;
  } catch (error) {
    console.log(error);
  }
};

const getAndUpdateID = async () => {
  try {
    let currentID = await axios.post(`${API_URL}/api/getAndUpdateID`);
    return currentID.data.id;
  } catch (error) {
    console.log(error);
  }
};

const uploadImages = async (data) => {
  try {
    let name = data.name;
    let path = data.path;

    await axios.post(`${API_URL}/api/uploadImage`, { name: name, path: path });
  } catch (error) {
    console.log(error);
  }
};

const getUsersData = async (isOwner) => {
  let usersData = await axios.get(`${API_URL}/api/getUsersData/${isOwner}`);
  return usersData.data;
};

const getUsersName = async () => {
  let usersName = await axios.get(`${API_URL}/api/getUsersName`);
  return usersName.data;
};

const updateUserData = async (userData) => {
  return await axios.post(`${API_URL}/api/updateUserData`, {
    userData: userData
  });
};

const removeUser = async (user) => {
  axios.post(`${API_URL}/api/removeUser`, {
    user: user
  });
};

const updateUserLastSeen = async (userEmail) => {
  return await axios.post(`${API_URL}/api/updateUserLastSeen`, {
    userEmail: userEmail
  });
};

const addNewOrder = async (userName, newOrder) => {
  await axios.post(`${API_URL}/api/addNewOrder`, {
    userName: userName,
    newOrder: newOrder
  });
};

const getAndUpdateContractID = async () => {
  try {
    let currentID = await axios.post(`${API_URL}/api/getAndUpdateContractID`);
    return currentID.data.id;
  } catch (error) {
    console.log(error);
  }
};

/* Attendance API's */

// add new attendance for user
const addNewAttendance = async (
  user,
  time,
  date,
  locName,
  far,
  currentLoc,
  onTime,
  lates
) => {
  return await axios.post(`${API_URL}/api/newAttendance`, {
    time: time,
    date: date,
    locName: locName,
    far: far,
    currentLoc: currentLoc,
    onTime: onTime,
    lates: lates,
    user: user
  });
};

// add new Leaving for user
const addNewLeaving = async (
  user,
  time,
  date,
  locName,
  far,
  currentLoc,
  onTime,
  lates
) => {
  return await axios.post(`${API_URL}/api/newLeaving`, {
    time: time,
    date: date,
    locName: locName,
    far: far,
    currentLoc: currentLoc,
    onTime: onTime,
    lates: lates,
    user: user
  });
};

// get attendance for user
const getUserAttendance = async (user) => {
  let userAttendance = await axios.get(
    `${API_URL}/api/getUserAttendance/${user}`
  );
  return userAttendance;
};



export {
  getAllMessage,
  deleteMessageByID,
  insertOneMessage,
  signIn,
  refreshToken,
  Protected,
  logOutCallback,
  userName,
  getData,
  API_URL,
  getDataID,
  getDataForGallery,
  adminsignIn,
  deleteProjectByID,
  addNewProject,
  updateProjectToDB,
  getEmailJsData,
  getFireBaseData,
  getBookingData,
  updateMyBooking,
  updateMyBooking2,
  updateMybookingTable,
  checkPrivilage,
  getAndUpdateID,
  uploadImages,
  updateMessageByID,
  insertIntoArchive,
  getUsersData,
  updateUserData,
  getUsersName,
  removeUser,
  updateUserLastSeen,
  addNewOrder,
  getAndUpdateContractID,
  addNewAttendance,
  addNewLeaving,
  getUserAttendance
};
