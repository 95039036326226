import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { updateMessage } from "../Booking/Functions";
import app from "../../base.js";
import { updateUserData } from "../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default function AddUser({
  open4,
  handleClose4,
  rows,
  setRows,
  usersNames,
  usersEmail,
}) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isAlwaqil, setIsAlwaqil] = React.useState(false);
  const [isSaler, setIsSaler] = React.useState(false);
  const [isWorker, setIsWorker] = React.useState(false);
  const [checkEmail, setCheckEmail] = React.useState([]);
  const Buttons = () => {
    return (
      <div className="buttons">
        <button
          className="btn btn-primary next save"
          onClick={(e) => {
            handelName(name);
            if (
              password.length > 5 &&
              checkEmail.includes("@") &&
              checkEmail.includes(".") &&
              checkEmail.includes("c") &&
              checkEmail.includes("o") &&
              checkEmail.includes("m") &&
              !usersNames.includes(name) &&
              !usersEmail.includes(email)
            ) {
              let user = {};
              user.name = name;
              user.email = email;
              user.isMiniAdmin = isAdmin;
              user.isAlwaqil = isAlwaqil;
              user.isSaler = isSaler;
              user.isWorker = isWorker;

              var today = new Date();
              var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
              var time =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();
              var dateTime = date + " " + time;
              user.lastSeen = dateTime;
              user.id = rows.length + 1;
              let tempRows = [];
              rows.map((e) => {
                tempRows.push(e);
              });
              tempRows.push(user);
              setRows(tempRows);
              app
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                  let userData = user;
                  userData.email = email;
                  updateUserData(userData);
                  updateMessage("add");
                  handleClose4();
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
        >
          حفظ
        </button>

        <button
          className="btn btn-primary past cancel"
          onClick={(e) => {
            handleClose4();
          }}
        >
          إلغاء
        </button>
      </div>
    );
  };

  const handelName = (name) => {
    let tempNameSplit = name.split(" ");
    for (let i = 0; i < tempNameSplit.length; i++) {
      tempNameSplit[i] =
        tempNameSplit[i].charAt(0).toUpperCase() + tempNameSplit[i].slice(1);
    }

    setName(tempNameSplit.join(" "));
  };

  return (
    <div>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="bookingDataEdite"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <div className="addBookingTab">
            <div className="tabsContent myBookingData previlage addUser">
              <TextField
                id="standard-basic"
                label="إسم الموظف"
                value={name}
                error={usersNames.includes(name) ? true : false}
                onChange={(e) => setName(e.target.value)}
                required
                helperText={
                  usersNames.includes(name) ? "إسم المستخدم موجود مسبقا" : null
                }
              ></TextField>

              <TextField
                id="standard-basic"
                label="البريد الإلكتروني للموظف"
                value={email}
                error={
                  (checkEmail.includes("@") &&
                    checkEmail.includes(".") &&
                    checkEmail.includes("c") &&
                    checkEmail.includes("o") &&
                    checkEmail.includes("m") &&
                    !usersEmail.includes(email)) ||
                  checkEmail.length === 0
                    ? false
                    : true
                }
                helperText={
                  usersEmail.includes(email)
                    ? "البريد الإلكتروني موجود مسبقا"
                    : null
                }
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                  setCheckEmail(e.target.value.split(""));
                }}
                required
              ></TextField>

              <TextField
                id="standard-basic"
                label="كلمة المرور"
                error={password.length > 5 || password === "" ? false : true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                helperText={
                  "كلمة المرور يجب ان تحتوي على 6 احرف/ارقام على الأقل"
                }
              ></TextField>

              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="Admin" />}
                    label="ادمن"
                    labelPlacement="top"
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  />
                  <FormControlLabel
                    control={<Checkbox name="Alwaqil" />}
                    label="الوكيل"
                    labelPlacement="top"
                    checked={isAlwaqil}
                    onChange={(e) => setIsAlwaqil(e.target.checked)}
                  />
                  <FormControlLabel
                    control={<Checkbox name="SalesMan" />}
                    label="بائع"
                    labelPlacement="top"
                    checked={isSaler}
                    onChange={(e) => setIsSaler(e.target.checked)}
                  />
                  <FormControlLabel
                    control={<Checkbox name="Service" />}
                    label="الصيانة"
                    labelPlacement="top"
                    checked={isWorker}
                    onChange={(e) => setIsWorker(e.target.checked)}
                  />
                </FormGroup>
              </FormControl>
              <Buttons />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
