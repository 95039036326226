import React from 'react';
import './bookingData.css';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { handelUpdateData, validateSAID } from '../Functions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};

export default function BookingData({
  open3,
  handleClose,
  editData,
  data,
  setData,
  allData,
  setAllData,
  setSearch,
  refresh,
  setRefresh,
  currentUserData
}) {
  const [apartment_No, setApartment_No] = React.useState();
  const [secondAppartmentNumber, setSecondAppatmentNumber] = React.useState();
  const [size, setSize] = React.useState();
  const [clientName, setClientName] = React.useState();
  const [phone, setClientPhone] = React.useState('');
  const [clientID, setClientID] = React.useState();
  const [state, setState] = React.useState();
  const [deposit, setDeposit] = React.useState();
  const [depositReturn, setDepositReturn] = React.useState(0);
  const [price, setPrice] = React.useState();
  const [instrument_No, setInstrument_No] = React.useState();
  const [newInstrument_No, setNewInstrument_No] = React.useState();
  const [firstDeposit, setFirstDeposit] = React.useState();
  const [FD_Money, setFD_Money] = React.useState();
  const [excessAmount, setExcessAmount] = React.useState();
  const [excess_Money, setExcess_Money] = React.useState();
  const [VAT, setVAT] = React.useState();
  const [VATState, setVATState] = React.useState();
  const [floor, setFloor] = React.useState();
  const [appartment, setAppartment] = React.useState();
  const [note, setNote] = React.useState();
  const [bankName, setBankName] = React.useState('لايوجد');
  const [bankState, setBankState] = React.useState('لايوجد');
  const [employe_name, setEmploye_name] = React.useState('');
  const [date, setDate] = React.useState('');
  const [done, setDone] = React.useState('');
  const [validateID, setValidateID] = React.useState(true);
  const [hidden, setHidden] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(async () => {
    setApartment_No(editData.no_apartment);
    setSecondAppatmentNumber(editData.secondAppartmentNumber);
    setSize(editData.space);
    setClientName(editData.client_name);
    setClientPhone(editData.client_phone);
    setClientID(editData.clientID);
    setState(editData.state);
    setDeposit(editData.deposit);
    setDepositReturn(editData.depositReturn);
    setPrice(editData.price);
    setInstrument_No(editData.instrument_No);
    setNewInstrument_No(editData.newInstrument_No);
    setFirstDeposit(editData.FD_Money === 0 ? 'بدون' : 'فائض');
    setFD_Money(editData.FD_Money);
    setExcessAmount(editData.excess_Money === 0 ? 'بدون' : 'دفعة أولى');
    setExcess_Money(editData.excess_Money);
    setVAT(editData.VAT);
    setVATState(editData.VATState !== '' ? editData.VATState : '');
    setFloor(editData.secondAppartmentNumber.split('/')[0]);
    setAppartment(editData.secondAppartmentNumber.split('/')[1]);
    setNote(editData.note);
    setBankName(editData.bankName);
    setBankState(editData.bankState);
    setEmploye_name(editData.employe_name);
    setDate(editData.date);
    setDone(editData.done);
  }, [editData]);

  const handelPrice = (newPrice, VState) => {
    setPrice(
      newPrice
        .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
        .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    );
    if (newPrice !== '') {
      if (VState === 'معفي') {
        if (parseInt(newPrice) > 1000000) {
          setVAT(((parseInt(newPrice) - 1000000) * 5) / 100);
        } else {
          setVAT(0);
        }
      } else {
        setVAT((parseInt(newPrice) * 5) / 100);
      }
    } else {
      setVAT(0);
    }
  };

  let bankNames = [
    'لايوجد',
    'البنك الأهلي التجاري',
    'البنك السعودي الفرنسي',
    'البنك الأول',
    'البنك السعودي للاستثمار',
    'البنك العربي الوطني',
    'بنك البلاد',
    'بنك الجزيرة',
    'بنك الرياض',
    'مجموعة سامبا المالية (سامبا)',
    'مصرف الراجحي',
    'مصرف الإنماء',
    'بنك الخليج الدولي - السعودية'
  ];

  let allBankState = [
    'لايوجد',
    'تحت الإجراء',
    'وقع العقود',
    'تقييم أول',
    'تقييم ثاني',
    'صدر الشيك',
    'تم الإفراغ',
    'كاش'
  ];

  const appartmentNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30
  ];
  const appartmentFloor = ['أ', 1, 2, 3, 4, 5, 6, 7];
  const appartmentOnFloor = [1, 2, 3, 4, 5];

  const handelUpdate = async () => {
    // console.log(editData);
    let tempData = JSON.parse(JSON.stringify(data));
    let index = '';
    await tempData[editData.projectName][editData.buildingNumber].forEach(
      (e, key) => {
        if (e.no_apartment === editData.no_apartment) {
          index = key;
        }
      }
    );
    // console.log(index);
    tempData[editData.projectName][editData.buildingNumber][index] = {
      FD_Money: FD_Money,
      VAT: VAT,
      VATState: VATState,
      bankName: bankName,
      bankState: bankState,
      clientID: clientID,
      client_name: clientName,
      client_phone: phone,
      date: date,
      deposit: deposit,
      depositReturn: depositReturn,
      done: done,
      employe_name: employe_name,
      excess_Money: excess_Money,
      id: editData.id,
      instrument_No: instrument_No,
      newInstrument_No: newInstrument_No,
      no_apartment: apartment_No,
      note: note,
      price: price,
      secondAppartmentNumber: `${floor}/${appartment}`,
      space: size,
      state: state,
      total: parseInt(price) + parseInt(VAT),
      contract: editData.contract,
      contractID: editData.contractID
    };
    // console.log(tempData[editData.projectName][editData.buildingNumber][index]);

    let index2 = -1;
    await allData.forEach((e, key) => {
      if (
        e.projectName === editData.projectName &&
        e.buildingNumber === editData.buildingNumber &&
        e.no_apartment === editData.no_apartment
      ) {
        index2 = key;
      }
    });
    if (index2 > -1) {
      let tempAllData = JSON.parse(JSON.stringify(allData));
      tempAllData[index2] =
        tempData[editData.projectName][editData.buildingNumber][index];
      tempAllData[index2]['projectName'] = editData.projectName;
      tempAllData[index2]['buildingNumber'] = editData.buildingNumber;

      let booking = tempData[editData.projectName][editData.buildingNumber];

      handelUpdateData(
        booking,
        tempAllData[index2],
        refresh,
        setRefresh,
        'edit',
        currentUserData
      );
      setData(tempData);
      setAllData(tempAllData);
      setRefresh(refresh + 1);
      setSearch(true);
    }
  };

  const { isAdmin, isOwner } = currentUserData;

  const Buttons = () => {
    return (
      <div className="buttons">
        {done === 'تم الإفراغ' && !isAdmin && isOwner ? null : (
          <button
            className="btn btn-primary next save"
            onClick={(e) => {
              handelUpdate();
              handleClose();
              setValue('1');
            }}
          >
            حفظ
          </button>
        )}
        <button
          className="btn btn-primary past cancel"
          onClick={(e) => {
            handleClose();
            setValue('1');
          }}
        >
          إلغاء
        </button>
      </div>
    );
  };

  let unFreeAppartments = data[editData.projectName][
    editData.buildingNumber
  ].map((e) => {
    if (appartmentNumber.includes(e.no_apartment)) return e.no_apartment;
  });
  unFreeAppartments = unFreeAppartments.filter((e) => e !== undefined);

  return (
    <div>
      <Modal
        open={open3}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="bookingDataEdite"
      >
        <Box className="mainBoxINFOAlwaqil" sx={{ ...style, width: '80%' }}>
          <h4
            style={{
              width: '40%',
              textAlign: 'center',
              margin: 'auto',
              marginTop: '3%'
            }}
            onClick={(e) => setHidden(!hidden)}
          >
            معلومات الحجز
          </h4>
          <div className="addBookingTab">
            <TabContext className="addBookingTab" value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="معلومات الشقة" value="1" />
                  <Tab label="معلومات العميل" value="2" />
                  <Tab label="معلومات الحجز" value="3" />
                  <Tab label="معلومات البنك" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <>
                  <div className="tabsContent bookingData">
                    <TextField
                      id="standard-basic"
                      label="اسم المشروع"
                      required
                      value={editData.projectName}
                      disabled
                    ></TextField>

                    <TextField
                      id="standard-basic"
                      label="رقم المبنى"
                      required
                      value={editData.buildingNumber}
                      disabled
                    ></TextField>

                    <TextField
                      className="appartmentNumber"
                      id="standard-select"
                      label="رقم الشقة التسلسلي"
                      select
                      required
                      value={apartment_No}
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setApartment_No(e.target.value)}
                    >
                      {/* {appartmentNumber.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })} */}
                      {appartmentNumber.map((e, key) => {
                        if (
                          !unFreeAppartments.includes(e) ||
                          e === apartment_No
                        ) {
                          return (
                            <MenuItem key={key} value={e}>
                              {e}
                            </MenuItem>
                          );
                        } else {
                          return (
                            <MenuItem key={key} value={e} disabled>
                              {e}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextField>

                    <TextField
                      id="standard-select"
                      className="appartmentOnFloor"
                      select
                      label="رقم الشقة في الدور"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      value={appartment}
                      onChange={(e) => setAppartment(e.target.value)}
                    >
                      {appartmentOnFloor.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <p className="slash">/</p>
                    <TextField
                      className="floor"
                      id="standard-select"
                      select
                      label="الدور"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      value={floor}
                      onChange={(e) => setFloor(e.target.value)}
                    >
                      {appartmentFloor.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <TextField
                      id="standard-basic"
                      error={
                        (size > 50 && size < 700) ||
                        size === '' ||
                        done === 'تم الإفراغ'
                          ? false
                          : true
                      }
                      label="مساحة الشقة (متر مربع)"
                      className="sizeField"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      value={size}
                      error={
                        (size >= 50 && size <= 1000) || size === ''
                          ? false
                          : true
                      }
                      helperText={
                        (size >= 50 && size <= 1000) || size === ''
                          ? null
                          : 'مساحة الشقة يجب ان تكون بين 50-1000 متر مربع'
                      }
                      onChange={(e) => {
                        setSize(
                          e.target.value
                            .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                            .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
                        );
                      }}
                    />
                  </div>
                  <Buttons />
                </>
              </TabPanel>
              <TabPanel value="2">
                <>
                  <div className="tabsContent">
                    <TextField
                      id="standard-basic"
                      label="اسم العميل"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                    <TextField
                      error={validateID || clientID === '' ? false : true}
                      className="IDField"
                      id="standard-basic"
                      label="هوية العميل"
                      variant="standard"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      value={clientID}
                      helperText={
                        validateID || clientID === ''
                          ? null
                          : 'رقم الهوية غير صحيح'
                      }
                      onChange={(e) => {
                        setClientID(e.target.value);
                        validateSAID(e.target.value) !== -1
                          ? setValidateID(true)
                          : setValidateID(false);
                      }}
                    />

                    <TextField
                      className="phoneField"
                      id="standard-basic"
                      label="الجوال"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      error={phone.length === 10 || phone === '' ? false : true}
                      value={phone}
                      helperText="ex:05XXXXXXXX"
                      onChange={(e) =>
                        e.target.value.length <= 10
                          ? setClientPhone(
                              e.target.value
                                .replace(/[٠-٩]/g, (d) =>
                                  '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                )
                                .replace(/[۰-۹]/g, (d) =>
                                  '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                )
                            )
                          : null
                      }
                    />
                  </div>
                  <Buttons />
                </>
              </TabPanel>
              <TabPanel className="bookingINFOAlwaqil" value="3">
                <>
                  <div className="tabsContent bookingData">
                    <TextField
                      id="standard-select"
                      className="appartmentState"
                      select
                      label="الحالة"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    >
                      <MenuItem key={1} value={'محجوزه'}>
                        حجز
                      </MenuItem>
                      <MenuItem key={2} value={'مباعه'}>
                        بيع
                      </MenuItem>
                    </TextField>
                    <TextField
                      id="standard-basic"
                      className="deposit"
                      label="العربون (ريال سعودي)"
                      type="number"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      min="0"
                      value={deposit}
                      onChange={(e) =>
                        e.target.value >= 0
                          ? setDeposit(
                              e.target.value
                                .replace(/[٠-٩]/g, (d) =>
                                  '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                )
                                .replace(/[۰-۹]/g, (d) =>
                                  '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                )
                            )
                          : setDeposit(0)
                      }
                    />

                    <TextField
                      className="depositReturn"
                      id="standard-basic"
                      label="المرتجع من العربون (ريال سعودي)"
                      type="number"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      min="0"
                      value={depositReturn}
                      onChange={(e) =>
                        e.target.value >= 0
                          ? setDepositReturn(
                              e.target.value
                                .replace(/[٠-٩]/g, (d) =>
                                  '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                )
                                .replace(/[۰-۹]/g, (d) =>
                                  '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                )
                            )
                          : setDepositReturn(0)
                      }
                    />

                    <TextField
                      id="standard-select"
                      className="VATState"
                      select
                      label="حالة التصرفات العقارية"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setVATState(e.target.value);
                        handelPrice(price, e.target.value);
                      }}
                      value={VATState}
                    >
                      <MenuItem key={1} value={'غير معفي'}>
                        غير معفي
                      </MenuItem>
                      <MenuItem key={2} value={'معفي'}>
                        معفي
                      </MenuItem>
                    </TextField>

                    <TextField
                      className="price"
                      id="standard-basic"
                      label="السعر (ريال سعودي)"
                      value={price}
                      type="number"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        e.target.value >= 0
                          ? handelPrice(e.target.value, VATState)
                          : setPrice(0)
                      }
                    />

                    <TextField
                      id="filled-disabled"
                      className="VAT"
                      label="قيمة التصرفات العقارية (ريال سعودي) "
                      value={VAT}
                      required
                      disabled
                    />

                    <TextField
                      id="standard-disabled"
                      className="total"
                      label="الإجمالي شامل الضريبة (ريال سعودي)"
                      disabled
                      value={
                        parseFloat(VAT) +
                        (isNaN(parseFloat(price)) ? 0 : parseFloat(price))
                      }
                      required
                    />
                    <TextField
                      id="standard-basic"
                      label="رقم الصك"
                      value={instrument_No}
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setInstrument_No(e.target.value)}
                    />
                    <TextField
                      id="standard-basic"
                      label="تاريخ الحجز"
                      value={date}
                      disabled
                    />
                    <TextField
                      id="standard-basic"
                      label="الموظف"
                      value={employe_name}
                      disabled
                    />

                    <TextField
                      id="standard-select"
                      select
                      id="standard-basic"
                      label="تقييم الوكييل"
                      value={done}
                      required
                      select
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setDone(e.target.value)}
                    >
                      <MenuItem key={1} value={'تم الإفراغ'}>
                        تم الإفراغ
                      </MenuItem>
                      <MenuItem key={2} value={'لم يتم الإفراغ'}>
                        لم يتم الإفراغ
                      </MenuItem>
                    </TextField>

                    <TextField
                      id="outlined-multiline-static"
                      label="ملاحظات"
                      multiline
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      rows={1}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />

                    {hidden ? (
                      <>
                        <TextField
                          id="standard-select"
                          select
                          label="دفعة اولى مقدمة"
                          required
                          disabled={
                            done === 'تم الإفراغ' && !(isAdmin || isOwner)
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setFirstDeposit(
                              e.target.value
                                .replace(/[٠-٩]/g, (d) =>
                                  '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                )
                                .replace(/[۰-۹]/g, (d) =>
                                  '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                )
                            )
                          }
                          value={firstDeposit}
                        >
                          <MenuItem key={1} value={'بدون'}>
                            بدون
                          </MenuItem>
                          <MenuItem key={2} value={'دفعة أولى'}>
                            دفعة أولى
                          </MenuItem>
                        </TextField>
                        {firstDeposit !== 'بدون' ? (
                          <TextField
                            id="standard-basic"
                            label="المبلغ"
                            required
                            disabled={
                              done === 'تم الإفراغ' && !(isAdmin || isOwner)
                                ? true
                                : false
                            }
                            type="number"
                            value={FD_Money}
                            onChange={(e) => {
                              e.target.value >= 0
                                ? setFD_Money(
                                    e.target.value
                                      .replace(/[٠-٩]/g, (d) =>
                                        '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                      )
                                      .replace(/[۰-۹]/g, (d) =>
                                        '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                      )
                                  )
                                : setFD_Money(0);
                            }}
                          />
                        ) : null}

                        <TextField
                          id="standard-select"
                          select
                          label="فائض"
                          required
                          disabled={
                            done === 'تم الإفراغ' && !(isAdmin || isOwner)
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setExcessAmount(
                              e.target.value
                                .replace(/[٠-٩]/g, (d) =>
                                  '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                )
                                .replace(/[۰-۹]/g, (d) =>
                                  '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                )
                            )
                          }
                          value={excessAmount}
                        >
                          <MenuItem key={1} value={'بدون'}>
                            بدون
                          </MenuItem>
                          <MenuItem key={2} value={'فائض'}>
                            فائض
                          </MenuItem>
                        </TextField>
                        {excessAmount !== 'بدون' ? (
                          <TextField
                            id="standard-basic"
                            label="المبلغ"
                            required
                            disabled={
                              done === 'تم الإفراغ' && !(isAdmin || isOwner)
                                ? true
                                : false
                            }
                            type="number"
                            value={excess_Money}
                            onChange={(e) => {
                              e.target.value >= 0
                                ? setExcess_Money(e.target.value)
                                : setExcess_Money(0);
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>
                  <Buttons />
                </>
              </TabPanel>
              <TabPanel value="4">
                <>
                  <div className="tabsContent">
                    <div className="bankNameDiv MuiFormControl-root">
                      <Autocomplete
                        value={bankName}
                        id="combo-box-demo"
                        options={bankNames}
                        disabled={
                          done === 'تم الإفراغ' && !(isAdmin || isOwner)
                            ? true
                            : false
                        }
                        getOptionLabel={(option) => option}
                        onChange={(e) => {
                          if (bankNames.includes(e.target.innerHTML)) {
                            setBankName(e.target.innerHTML);
                          } else {
                            setBankName('لايوجد');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="البنك"
                            variant="filled"
                          />
                        )}
                      />
                    </div>

                    <TextField
                      id="standard-select"
                      select
                      label="حالة البنك"
                      required
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setBankState(e.target.value)}
                      value={bankState}
                    >
                      {allBankState.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      id="standard-basic"
                      label="رقم الصك الجديد"
                      value={newInstrument_No}
                      disabled={
                        done === 'تم الإفراغ' && !(isAdmin || isOwner)
                          ? true
                          : false
                      }
                      onChange={(e) => setNewInstrument_No(e.target.value)}
                    />
                  </div>
                  <Buttons />
                </>
              </TabPanel>
            </TabContext>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
