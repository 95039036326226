//old code
import React from "react";
import ImageGallery from "react-image-gallery";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { getDataForGallery } from "../api";
import { useTranslation } from "react-i18next";

//new code
import { useRef } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { Button } from "react-responsive-button";
import "react-responsive-button/dist/index.css";

// function Gallery(props) {
//   let history = useHistory();

//   const { t, i18n } = useTranslation();
//   const [galleryData, setGalleryData] = React.useState(false);
//   const [galleryName, setGalleryName] = React.useState(null);
//   const [data, setData] = React.useState("");

//   if (data === "") {
//     getDataForGallery(props.match.params.id1, props.match.params.id2).then(
//       (e) => {
//         setData(e);
//       }
//     );
//   }

//   let imageArray = [];
//   if (!galleryData && data !== "") {
//     data.images.forEach((e) => {
//       imageArray.push({
//         original: `${e}`,
//         thumbnail: `${e}`,
//         description: "",
//       });
//     });

//     setGalleryData(imageArray);
//   }

//   if (data !== "") {
//     if (i18n.language === "en" && galleryName !== data.name.en) {
//       setGalleryName(data.name.en);
//     }
//     if (i18n.language === "ar" && galleryName !== data.name.ar) {
//       setGalleryName(data.name.ar);
//     }
//   }
//   const handelButtonClicked = () => {
//     history.push(`/Projects/${props.match.params.id1}`);
//   };

//   return (
//     <div className="project-main">
//       <div className="projectTitleButton">
//         <button
//           onClick={() => {
//             handelButtonClicked();
//           }}
//         >
//           {<AiOutlineArrowLeft />} {t("gallery.btn")}
//         </button>
//         <h2>{galleryName !== null ? galleryName : null}</h2>
//       </div>
//       {galleryData !== false ? <ImageGallery items={galleryData} /> : null}
//     </div>
//   );
// }

const Gallery = (props) => {
  const carouselRef = useRef(null);

  let history = useHistory();

  const { t, i18n } = useTranslation();
  const [galleryData, setGalleryData] = React.useState(false);
  const [galleryName, setGalleryName] = React.useState(null);
  const [data, setData] = React.useState("");

  if (data === "") {
    getDataForGallery(props.match.params.id1, props.match.params.id2).then(
      (e) => {
        setData(e);
      }
    );
  }

  let imageArray = [];
  if (!galleryData && data !== "") {
    // allimages = props.project.filter((e) => e._id === props.match.params.id);
    data.images.forEach((e) => {
      imageArray.push({
        src: e,
      });
    });

    setGalleryData(imageArray);
  }

  if (data !== "") {
    if (i18n.language === "en" && galleryName !== data.name.en) {
      setGalleryName(data.name.en);
    }
    if (i18n.language === "ar" && galleryName !== data.name.ar) {
      setGalleryName(data.name.ar);
    }
  }

  const handelButtonClicked = () => {
    history.push(`/Projects/${props.match.params.id1}`);
  };

  return (
    <section className="section" aria-labelledby="example5">
      <div className="projectTitleButton">
        <button
          onClick={() => {
            handelButtonClicked();
          }}
        >
          {<AiOutlineArrowLeft />} {t("gallery.btn")}
        </button>
        <h2>{galleryName !== null ? galleryName : null}</h2>
      </div>

      <div className="carousel-container">
        {galleryData !== false ? (
          <Carousel ref={carouselRef} images={galleryData} />
        ) : null}
      </div>
    </section>
  );
};

export default Gallery;
