import React from "react";
import SingleQRCode from "./SingleQRCode";

export default function QRCode(props) {
  let singleCode = "";
  if (Object.keys(props.project).length > 0) {
    singleCode = props.project.map((e, index) => (
      <SingleQRCode name={e} key={index} currentLang={props.currentLang} />
    ));
  }

  return <div className="div-QRCode">{singleCode}</div>;
}
