import React from "react";
import "./renderTables.css";
import Table from "react-bootstrap/Table";
export default function RenderTables({ name, building, data, lastBuilding }) {
  let bookingApart = [];
  let tempDataSold = {
    أ: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: []
  };

  let tempDataBooking = {
    أ: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: []
  };

  let temp = data.forEach((e, key) => {
    if (e.state === "مباعه") {
      tempDataSold[
        e.secondAppartmentNumber.split("/")[0].length > 1
          ? 1
          : e.secondAppartmentNumber.split("/")[0]
      ].push(
        e.secondAppartmentNumber.split("/")[1].split("").length > 1
          ? "1"
          : e.secondAppartmentNumber.split("/")[1]
      );
    } else if (e.state === "محجوزه") {
      tempDataBooking[
        e.secondAppartmentNumber.split("/")[0].length > 1
          ? 1
          : e.secondAppartmentNumber.split("/")[0]
      ].push(
        e.secondAppartmentNumber.split("/")[1].split("").length > 1
          ? "1"
          : e.secondAppartmentNumber.split("/")[1]
      );
    }
  });

  const floor = [
    {
      floor: "الدور الأول",
      symbol: "أ",
    },
    {
      floor: "الدور الثاني",
      symbol: "1",
    },
    {
      floor: "الدور الثالث",
      symbol: "2",
    },
    {
      floor: "الدور الرابع",
      symbol: "3",
    },
    {
      floor: "الدور الخامس",
      symbol: "4",
    },
    {
      floor: "الدور السادس",
      symbol: "5",
    },
    {
      floor: "الدور السابع",
      symbol: "6",
    },
  ];

  const renderTr = floor.map((e, key) => {
    // missing 5 and 6
    const renderTh = ["1", "2", "3", "4", "", ""].map((ee, key2) => {
      return (
        <th
          className="hovertext"
          data-hover={
            tempDataSold[e.symbol].includes(ee)
              ? "مباعه"
              : tempDataBooking[e.symbol].includes(ee)
              ? "محجوزة"
              : "متاحه"
          }
          style={{
            backgroundColor: tempDataSold[e.symbol].includes(ee)
              ? "rgb(151 36 36 / 90%)"
              : tempDataBooking[e.symbol].includes(ee)
              ? "rgb(146 147 139)"
              : "white",
            color: tempDataSold[e.symbol].includes(ee)
              ? "white"
              : tempDataBooking[e.symbol].includes(ee)
              ? "white"
              : "black",
          }}
          key={key2}
        >
          {ee}
        </th>
      );
    });
    return (
      <tr key={key}>
        <th className="rep_tableFloor">{e.floor}</th>
        {renderTh}
      </tr>
    );
  });

  return (
    <div className="rep_tableDiv">
      <h5 className="projectName">{name}</h5>
      <h5 className="buildingName">مبنى رقم {building}</h5>
      <Table bordered hover className="rep_table">
        <tbody>{renderTr}</tbody>
      </Table>

      {lastBuilding ? <hr /> : null}
    </div>
  );
}
