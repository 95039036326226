import React, { Component } from "react";
import { BsTrash } from "react-icons/bs";
import { BsPerson } from "react-icons/bs";
import { RiBuilding4Line } from "react-icons/ri";
import { BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { RiTimeLine } from "react-icons/ri";
import { MdDateRange } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
import CheckIcon from "@material-ui/icons/Check";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingBy: this.props.bookingBy,
      isDone: this.props.isDone,
    };
  }

  deleteMessage = (event) => {
    event.preventDefault();
    this.props.deleteMessage(this.props.id);
  };

  updateMessage = (event, bookingBy, isDone) => {
    event.preventDefault();
    this.props.updateMessage(this.props.id, bookingBy, isDone);
  };

  render() {
    const userName = this.props.currentUserData.name;
    const publishedOn = new Date(this.props.publishedOn._seconds * 1000);
    const options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const am_pm = publishedOn.getHours() > 12 ? "PM" : "AM";

    const hours =
      publishedOn.getHours() > 12
        ? publishedOn.getHours() % 12
        : publishedOn.getHours();
    const minutes =
      publishedOn.getMinutes() > 10
        ? publishedOn.getMinutes()
        : `0${publishedOn.getMinutes()}`;

    return (
      <div
        className={`message ${
          this.state.isDone === "true"
            ? `gray`
            : `${this.state.bookingBy === "false" ? null : "red"}`
        }`}
      >
        {/* Title & Content & Author */}
        <p>
          <BsPerson /> {this.props.name}
        </p>
        {this.props.email !== "" ? (
          <p className="email">
            <AiOutlineMail /> {this.props.email}
          </p>
        ) : null}
        <p>
          <BiPhone /> {this.props.mobile}
        </p>
        <p>
          <RiBuilding4Line /> {this.props.location}
        </p>
        <p>
          <BiMessageSquareDetail /> {this.props.message}
        </p>
        <p>
          <MdDateRange />
          En {publishedOn.toLocaleDateString(undefined, options)}
        </p>
        <p>
          <MdDateRange />
          Ar {publishedOn.toLocaleDateString("ar-EG", options)}
        </p>
        <p>
          <RiTimeLine /> {hours + ":" + minutes + " " + am_pm}
        </p>

        {this.state.isDone !== "true" ? (
          this.state.bookingBy !== "false" ? (
            this.state.bookingBy == userName ? (
              <>
                <div className="Buttons">
                  <p
                    className="done-herf"
                    // onClick={(e) => this.deleteMessage(e)}
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        bookingBy: userName,
                        isDone: "true",
                      });
                      this.updateMessage(e, userName, true);
                    }}
                  >
                    <AssignmentTurnedInOutlinedIcon /> تم التنفيذ
                  </p>
                  <p
                    className="cancel-herf"
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        bookingBy: "false",
                        isDone: "false",
                      });
                      this.updateMessage(e, false, false);
                    }}
                  >
                    <ClearRoundedIcon /> إلغاء
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>قيد التنفيذ من قبل {this.state.bookingBy}</p>
              </>
            )
          ) : (
            <>
              <p
                className="check-herf"
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    bookingBy: userName,
                    isDone: "false",
                  });
                  this.updateMessage(e, userName, false);
                }}
              >
                <CheckIcon />
                حجز المهمه
              </p>
            </>
          )
        ) : (
          <p>تم التنفيذ من قبل {this.props.bookingBy}</p>
        )}
      </div>
    );
  }
}
