import React from "react";
import Projects from "./Projects";
import Card from "react-bootstrap/Card";

import { addNewProject } from "../../api";
import swal from "sweetalert";

export default function Home(props) {
  const [projects, setProjects] = React.useState([]);
  const [refresh, setRefresh] = React.useState(1);

  React.useEffect(() => {
    if (props.projects !== null && props.projects !== undefined) {
      if (Object.keys(props.projects).length !== 0) {
        setProjects(props.projects);
      }
    }
  }, [props.projects]);

  const addProject = (e) => {
    e.preventDefault();
    let project = {
      name: {
        ar: "جديد",
        en: "new",
      },
      state: {
        ar: "",
        en: "",
      },
      img: "",
      location: "",
      no_Buidings: "",
      booking: {},
      order: 11,
      project: [],
      isService: false,
    };
    let newProject = [...projects];
    newProject.push(project);
    setProjects(newProject);
    setRefresh(refresh + 1);
    addNewProject(project, props.currentUserData.name);
    swal("تم", "إضافة مشروع جديد بنجاح", "success");
  };

  const deleteProject = (e, project) => {
    e.preventDefault();
    let newProject = [...projects];
    newProject.splice(project, 1);
    setProjects(newProject);
    // setRefresh(refresh + 1);
  };

  return (
    <div className="dashboard-home">
      <input type="hidden" value={refresh} />
      {projects.map((e, index) => (
        <Projects
          project={e}
          index={index}
          key={index}
          deleteProject={deleteProject}
          currentUserData={props.currentUserData}
        />
      ))}

      <div className="dashboardAddProjects">
        <Card
          className="card-div"
          onClick={(e) => {
            addProject(e);
          }}
        >
          <p>إضافة مشروع جديد</p>
        </Card>
      </div>
    </div>
  );
}
