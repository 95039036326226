import {
  updateMyBooking,
  updateMyBooking2,
  insertIntoArchive
} from '../../api';
import swal from 'sweetalert';

const handelUpdateData = (
  booking,
  clieckedData,
  refresh,
  setRefresh,
  type,
  user
) => {
  // updateMyBooking(
  //   selectedProject,
  //   selectedBuilding,
  //   parseInt(selectedAppartment),
  //   selectedAppartmentState,
  //   bankState,
  //   bankName,
  //   done,
  //   user
  // );
  // let tempObject = data[selectedProject][selectedBuilding].filter(
  //   (e) => e.no_apartment === parseInt(selectedAppartment)
  // );
  // tempObject[0].bankName = bankName;
  // let tempData = Object.values(allData);
  // allData.forEach((e, index) => {
  //   if (e.no_apartment === parseInt(selectedAppartment)) {
  //     tempData[index].bankName = bankName;
  //     tempData[index].bankState = bankState;
  //     tempData[index].state = selectedAppartmentState;
  //     tempData[index].done = done;

  //     if (selectedAppartmentState === "إلغاء الحجز") {
  //       tempData.splice(index, 1);
  //       window.location.reload();
  //     }
  //   }
  // });

  updateMyBooking2(booking, clieckedData, user, type);
  setRefresh(refresh + 1);
  updateMessage(type);
};

const handelRemoveBooking = (data, user) => {
  let date = new Date();
  let newdate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  data['removedDate'] = newdate;
  data['removedBy'] = user.name;
  insertIntoArchive(data, user);
};

const insertDataTempArr = (data) => {
  let tempArr = [];
  let newBody = [];
  let counter = 1;
  const tableBody = Object.keys(data).forEach((project, index) => {
    // console.log(data);
    Object.keys(data[project]).forEach((building, index2) => {
      newBody = data[project][building].forEach((e, key) => {
        tempArr.push({
          projectName: project,
          buildingNumber: building,
          no_apartment: e.no_apartment,
          space: e.space,
          client_name: e.client_name,
          client_phone: e.client_phone,
          date: e.date,
          state: e.state,
          employe_name: e.employe_name,
          deposit: e.deposit,
          price: e.price,
          sold: e.sold,
          instrument_No: e.instrument_No,
          newInstrument_No: e.newInstrument_No,
          id: e.id,
          depositReturn: 0,
          FD_Money: e.FD_Money,
          excess_Money: e.excess_Money,
          bankState: e.bankState,
          bankName: e.bankName,
          note: e.note,
          done: e.done,
          IDFile: e.IDFile,
          INSFile: e.INSFile,
          checkNumber: e.checkNumber,
          checkDate: e.checkDate,
          VAT: e.VAT !== undefined ? e.VAT : 0,
          VATState: e.VATState !== undefined ? e.VATState : '',
          clientID: e.clientID !== undefined ? e.clientID : '',
          secondAppartmentNumber: e.secondAppartmentNumber,
          total: e.total !== undefined ? e.total : 0,
          contract: e.contract,
          contractID: e.contractID
        });
        // console.log(e.contract);
        counter++;
      });
    });
  });

  return tempArr;
};

const updateMessage = (e) => {
  if (e === 'delete') {
    swal('تم الحذف بنجاح', '', 'success').then((ok) => {});
  } else if (e === 'edit') {
    swal('تم التعديل بنجاح', '', 'success').then((ok) => {});
  } else if (e === 'add') {
    swal('تم الإضافة بنجاح', '', 'success').then((ok) => {});
  } else if (e === 'resetPass') {
    swal(
      '',
      'تم إرسال رابط إستعادة كلمة المرور الى البريد الإلكتروني المسجل لدينا',
      'success'
    ).then((ok) => {});
  } else if (e === 'createContract') {
    swal('تم إنشاء عقد بنجاح', '', 'success').then((ok) => {});
  } else if (e === 'EditContract') {
    swal('تم تعديل العقد بنجاح', '', 'success').then((ok) => {});
  } else if (e === 'deleteContract') {
    swal('تم حذف العقد بنجاح', '', 'success').then((ok) => {});
  }
};

const validateSAID = (id) => {
  id = id.trim();
  if (isNaN(parseInt(id))) {
    return -1;
  }
  if (id.length !== 10) {
    return -1;
  }
  var type = id.substr(0, 1);
  if (type !== '2' && type !== '1') {
    return -1;
  }
  var sum = 0;
  for (var i = 0; i < 10; i++) {
    if (i % 2 === 0) {
      var ZFOdd = String('00' + String(Number(id.substr(i, 1)) * 2)).slice(-2);
      sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
    } else {
      sum += Number(id.substr(i, 1));
    }
  }
  return sum % 10 !== 0 ? -1 : type;
};
export {
  handelUpdateData,
  insertDataTempArr,
  updateMessage,
  validateSAID,
  handelRemoveBooking
};
