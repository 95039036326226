/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import Button from "../../component/CustomButtons/Button.js";

import app from "../../base.js";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(() => ({
  text: {
    color: "white",
  },
}));

export default function HeaderLinks(props) {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = React.useState(i18n.language);
  const history = useHistory();
  const classes = useStyles();
  const classes2 = useStyles2();
  const [isAuth, setAuth] = React.useState(true);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    props.handelLang(lang);
  };

  const logout = () => {
    app.auth().signOut();
  };

  app.auth().onAuthStateChanged((user) => {
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  });

  return (
    <List style={{ color: "#fff" }} className={classes.list}>
      {i18n.language == "ar" ? (
        <>
          {window.innerWidth < 960 ? (
            <>
              <ListItem className={classes.listItem}>
                <Link to="/#" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/home");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn1")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link style={{ color: "#fff" }} smooth to="Projects">
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/Projects");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn2")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link
                  style={{ color: "#fff" }}
                  smooth
                  to="home#contact-section"
                >
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/home#contact-section");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn3")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem
                className={classes.listItem}
                style={{ marginRight: "100px" }}
              >
                <Button
                  color="transparent"
                  onClick={() => {
                    props.toggle();
                    history.push("/services");
                  }}
                  className={
                    classes.navLink +
                    " " +
                    classes.socialIconsButton +
                    " " +
                    classes2.text
                  }
                  style={{
                    fontFamily: i18n.language == "ar" ? "lfFont" : null,
                    fontSize: "18px",
                  }}
                >
                  {t("nav.btn4")}
                </Button>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem className={classes.listItem}>
                <Link to="/services" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/services");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn4")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link
                  style={{ color: "#fff" }}
                  smooth
                  to="home#contact-section"
                >
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/home#contact-section");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn3")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link style={{ color: "#fff" }} smooth to="Projects">
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/Projects");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav.btn2")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem
                className={classes.listItem}
                style={{ marginRight: "100px" }}
              >
                <Button
                  color="transparent"
                  onClick={() => {
                    props.toggle();
                    history.push("/home");
                  }}
                  className={
                    classes.navLink +
                    " " +
                    classes.socialIconsButton +
                    " " +
                    classes2.text
                  }
                  style={{
                    fontFamily: i18n.language == "ar" ? "lfFont" : null,
                    fontSize: "18px",
                  }}
                >
                  {t("nav.btn1")}
                </Button>
              </ListItem>
            </>
          )}
        </>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              onClick={() => {
                props.toggle();
                history.push("/home");
              }}
              className={
                classes.navLink +
                " " +
                classes.socialIconsButton +
                " " +
                classes2.text
              }
              style={{
                fontSize: "16px",
              }}
            >
              {t("nav.btn1")}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link style={{ color: "#fff" }} smooth to="Projects">
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/Projects");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav.btn2")}
              </Button>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link style={{ color: "#fff" }} smooth to="home#contact-section">
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/home#contact-section");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav.btn3")}
              </Button>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link style={{ color: "#fff" }} smooth to="services">
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/services");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav.btn4")}
              </Button>
            </Link>
          </ListItem>
        </>
      )}
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          style={
            currentLang == "en"
              ? { backgroundColor: "rgba(83, 87, 90, 0.7)" }
              : { backgroundColor: "transparent" }
          }
          className={classes.navLink + " " + classes.socialIconsButton}
          onClick={() => changeLanguage("en")}
        >
          EN
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          style={
            currentLang == "ar"
              ? { backgroundColor: "rgba(83, 87, 90, 0.7)" }
              : { backgroundColor: "transparent" }
          }
          className={classes.navLink + " " + classes.socialIconsButton}
          onClick={() => changeLanguage("ar")}
        >
          عربي
        </Button>
      </ListItem>
    </List>
  );
}
