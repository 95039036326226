/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

// core components
import Button from "../../component/CustomButtons/Button.js";

import app from "../../base.js";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(() => ({
  text: {
    color: "white",
  },
}));

export default function HeaderLinks(props) {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = React.useState(i18n.language);
  const history = useHistory();
  const classes = useStyles();
  const classes2 = useStyles2();
  const [isAuth, setAuth] = React.useState(true);
  const [admin, setAdmin] = React.useState(false);
  const [owner, setOwner] = React.useState(false);

  React.useEffect(() => {
    setAdmin(props.privilege.isAdmin);
    setOwner(props.privilege.isOwner);
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    props.handelLang(lang);
  };

  const logout = () => {
    app.auth().signOut();
  };

  app.auth().onAuthStateChanged((user) => {
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  });
  return (
    <List style={{ color: "#fff" }} className={`${classes.list} navbarSystem`}>
      {i18n.language == "ar" ? (
        <>
          {window.innerWidth < 960 ? (
            <>
              <ListItem className={classes.listItem}>
                <Link smooth to="controlpanel" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/controlpanel");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn1")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        color="transparent"
                        className={
                          classes.navLink +
                          " " +
                          classes.socialIconsButton +
                          " " +
                          classes2.text
                        }
                        style={{
                          fontFamily: i18n.language == "ar" ? "lfFont" : null,
                          fontSize: "18px",
                        }}
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        {t("nav2.btn2")}
                      </Button>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link style={{ color: "#555" }} smooth to="booking">
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/booking");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.sales.salesPage")}
                            </Button>
                          </Link>
                        </MenuItem>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{
                              color: "#555",
                            }}
                            smooth
                            to="mybooking"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/mybooking");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.sales.mySales")}
                            </Button>
                          </Link>
                        </MenuItem>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{
                              color: "#555",
                            }}
                            smooth
                            to="newOrder"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/newOrder");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.btn7")}
                            </Button>
                          </Link>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link style={{ color: "#fff" }} smooth to="الوكيل">
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/الوكيل");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn3")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link smooth to="messages" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/messages");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "17px",
                    }}
                  >
                    {t("nav2.btn6")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem
                className={classes.listItem}
                style={{ marginRight: "100px" }}
              >
                <Link smooth to="REPORTS" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/REPORTS");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn4")}
                  </Button>
                </Link>
              </ListItem>
              {isAuth ? (
                <>
                  <ListItem className={classes.listItem}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Button
                            color="transparent"
                            className={
                              classes.navLink +
                              " " +
                              classes.socialIconsButton +
                              " " +
                              classes2.text
                            }
                            style={{
                              fontFamily:
                                i18n.language == "ar" ? "lfFont" : null,
                              fontSize: "17px",
                            }}
                            variant="contained"
                            {...bindTrigger(popupState)}
                          >
                            {t("nav2.btn5")}
                          </Button>

                          <Menu {...bindMenu(popupState)}>
                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{ color: "#555" }}
                                smooth
                                to="manage-users"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    props.toggle();
                                    history.push("/manage-users");
                                    popupState.close();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.manageUsers")}
                                </Button>
                              </Link>
                            </MenuItem>

                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{ color: "#555" }}
                                smooth
                                to="setting"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    props.toggle();
                                    history.push("/setting");
                                    popupState.close();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.settings")}
                                </Button>
                              </Link>
                            </MenuItem>
                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{
                                  color: "#555",
                                }}
                                smooth
                                to="/#"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    logout();
                                    window.location.reload();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.logout")}
                                </Button>
                              </Link>
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </ListItem>
                </>
              ) : null}
            </>
          ) : (
            <>
              {isAuth ? (
                <>
                  <ListItem className={classes.listItem}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <Button
                            color="transparent"
                            className={
                              classes.navLink +
                              " " +
                              classes.socialIconsButton +
                              " " +
                              classes2.text
                            }
                            style={{
                              fontFamily:
                                i18n.language == "ar" ? "lfFont" : null,
                              fontSize: "17px",
                            }}
                            variant="contained"
                            {...bindTrigger(popupState)}
                          >
                            {t("nav2.btn5")}
                          </Button>

                          <Menu {...bindMenu(popupState)}>
                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{ color: "#555" }}
                                smooth
                                to="manage-users"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    props.toggle();
                                    history.push("/manage-users");
                                    popupState.close();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.manageUsers")}
                                </Button>
                              </Link>
                            </MenuItem>

                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{ color: "#555" }}
                                smooth
                                to="setting"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    props.toggle();
                                    history.push("/setting");
                                    popupState.close();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.settings")}
                                </Button>
                              </Link>
                            </MenuItem>
                            <MenuItem style={{ justifyContent: "center" }}>
                              <Link
                                style={{
                                  color: "#555",
                                }}
                                smooth
                                to="/#"
                              >
                                <Button
                                  color="transparent"
                                  onClick={() => {
                                    logout();
                                    window.location.reload();
                                  }}
                                  className={
                                    classes.navLink +
                                    " " +
                                    classes.socialIconsButton +
                                    " "
                                  }
                                  style={{
                                    fontFamily:
                                      i18n.language == "ar" ? "lfFont" : null,
                                    fontSize: "17px",
                                  }}
                                >
                                  {t("nav2.profile.logout")}
                                </Button>
                              </Link>
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </ListItem>
                </>
              ) : null}
              <ListItem className={classes.listItem}>
                <Link to="REPORTS" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/REPORTS");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn4")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link smooth to="messages" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/messages");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn6")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link style={{ color: "#fff" }} smooth to="الوكيل">
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/الوكيل");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn3")}
                  </Button>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        color="transparent"
                        className={
                          classes.navLink +
                          " " +
                          classes.socialIconsButton +
                          " " +
                          classes2.text
                        }
                        style={{
                          fontFamily: i18n.language == "ar" ? "lfFont" : null,
                          fontSize: "17px",
                        }}
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        {t("nav2.btn2")}
                      </Button>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link style={{ color: "#555" }} smooth to="booking">
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/booking");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.sales.salesPage")}
                            </Button>
                          </Link>
                        </MenuItem>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{
                              color: "#555",
                            }}
                            smooth
                            to="mybooking"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/mybooking");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "18px",
                              }}
                            >
                              {t("nav2.sales.mySales")}
                            </Button>
                          </Link>
                        </MenuItem>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{
                              color: "#555",
                            }}
                            smooth
                            to="newOrder"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/newOrder");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.btn7")}
                            </Button>
                          </Link>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </ListItem>
              <ListItem
                className={classes.listItem}
                style={{ marginRight: "100px" }}
              >
                <Link to="controlpanel" style={{ color: "#fff" }}>
                  <Button
                    color="transparent"
                    onClick={() => {
                      props.toggle();
                      history.push("/controlpanel");
                    }}
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "18px",
                    }}
                  >
                    {t("nav2.btn1")}
                  </Button>
                </Link>
              </ListItem>
            </>
          )}
        </>
      ) : (
        <>
          <ListItem className={classes.listItem}>
            <Link to="controlpanel" style={{ color: "#fff" }}>
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/controlpanel");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav2.btn1")}
              </Button>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    color="transparent"
                    className={
                      classes.navLink +
                      " " +
                      classes.socialIconsButton +
                      " " +
                      classes2.text
                    }
                    style={{
                      fontFamily: i18n.language == "ar" ? "lfFont" : null,
                      fontSize: "17px",
                    }}
                    variant="contained"
                    {...bindTrigger(popupState)}
                  >
                    {t("nav2.btn2")}
                  </Button>

                  <Menu {...bindMenu(popupState)}>
                    <MenuItem style={{ justifyContent: "center" }}>
                      <Link style={{ color: "#555" }} smooth to="booking">
                        <Button
                          color="transparent"
                          onClick={() => {
                            props.toggle();
                            history.push("/booking");
                            popupState.close();
                          }}
                          className={
                            classes.navLink +
                            " " +
                            classes.socialIconsButton +
                            " "
                          }
                          style={{
                            fontFamily: i18n.language == "ar" ? "lfFont" : null,
                            fontSize: "17px",
                          }}
                        >
                          {t("nav2.sales.salesPage")}
                        </Button>
                      </Link>
                    </MenuItem>
                    <MenuItem style={{ justifyContent: "center" }}>
                      <Link
                        style={{
                          color: "#555",
                        }}
                        smooth
                        to="mybooking"
                      >
                        <Button
                          color="transparent"
                          onClick={() => {
                            props.toggle();
                            history.push("/mybooking");
                            popupState.close();
                          }}
                          className={
                            classes.navLink +
                            " " +
                            classes.socialIconsButton +
                            " "
                          }
                          style={{
                            fontFamily: i18n.language == "ar" ? "lfFont" : null,
                            fontSize: "18px",
                          }}
                        >
                          {t("nav2.sales.mySales")}
                        </Button>
                      </Link>
                    </MenuItem>
                    <MenuItem style={{ justifyContent: "center" }}>
                      <Link
                        style={{
                          color: "#555",
                        }}
                        smooth
                        to="newOrder"
                      >
                        <Button
                          color="transparent"
                          onClick={() => {
                            props.toggle();
                            history.push("/newOrder");
                            popupState.close();
                          }}
                          className={
                            classes.navLink +
                            " " +
                            classes.socialIconsButton +
                            " "
                          }
                          style={{
                            fontFamily: i18n.language == "ar" ? "lfFont" : null,
                            fontSize: "17px",
                          }}
                        >
                          {t("nav2.btn7")}
                        </Button>
                      </Link>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link style={{ color: "#fff" }} smooth to="الوكيل">
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/الوكيل");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav2.btn3")}
              </Button>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link smooth to="messages" style={{ color: "#fff" }}>
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/messages");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontFamily: i18n.language == "ar" ? "lfFont" : null,
                  fontSize: "17px",
                }}
              >
                {t("nav2.btn6")}
              </Button>
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link style={{ color: "#fff" }} smooth to="REPORTS">
              <Button
                color="transparent"
                onClick={() => {
                  props.toggle();
                  history.push("/REPORTS");
                }}
                className={
                  classes.navLink +
                  " " +
                  classes.socialIconsButton +
                  " " +
                  classes2.text
                }
                style={{
                  fontSize: "16px",
                }}
              >
                {t("nav2.btn4")}
              </Button>
            </Link>
          </ListItem>

          {isAuth ? (
            <>
              <ListItem className={classes.listItem}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        color="transparent"
                        className={
                          classes.navLink +
                          " " +
                          classes.socialIconsButton +
                          " " +
                          classes2.text
                        }
                        style={{
                          fontFamily: i18n.language == "ar" ? "lfFont" : null,
                          fontSize: "17px",
                        }}
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        {t("nav2.btn5")}
                      </Button>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{ color: "#555" }}
                            smooth
                            to="manage-users"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/manage-users");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.profile.manageUsers")}
                            </Button>
                          </Link>
                        </MenuItem>

                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link style={{ color: "#555" }} smooth to="setting">
                            <Button
                              color="transparent"
                              onClick={() => {
                                props.toggle();
                                history.push("/setting");
                                popupState.close();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.profile.settings")}
                            </Button>
                          </Link>
                        </MenuItem>
                        <MenuItem style={{ justifyContent: "center" }}>
                          <Link
                            style={{
                              color: "#555",
                            }}
                            smooth
                            to="/#"
                          >
                            <Button
                              color="transparent"
                              onClick={() => {
                                logout();
                                window.location.reload();
                              }}
                              className={
                                classes.navLink +
                                " " +
                                classes.socialIconsButton +
                                " "
                              }
                              style={{
                                fontFamily:
                                  i18n.language == "ar" ? "lfFont" : null,
                                fontSize: "17px",
                              }}
                            >
                              {t("nav2.profile.logout")}
                            </Button>
                          </Link>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </ListItem>
            </>
          ) : null}
        </>
      )}
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          style={
            currentLang == "en"
              ? { backgroundColor: "rgba(83, 87, 90, 0.7)" }
              : { backgroundColor: "transparent" }
          }
          className={classes.navLink + " " + classes.socialIconsButton}
          onClick={() => changeLanguage("en")}
        >
          EN
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          style={
            currentLang == "ar"
              ? { backgroundColor: "rgba(83, 87, 90, 0.7)" }
              : { backgroundColor: "transparent" }
          }
          className={classes.navLink + " " + classes.socialIconsButton}
          onClick={() => changeLanguage("ar")}
        >
          عربي
        </Button>
      </ListItem>
    </List>
  );
}
