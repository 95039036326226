import React from 'react';
import './mybooking.css';
import {
  getBookingData,
  updateMyBooking,
  updateMybookingTable,
  uploadImages
} from '../../../api';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Form from 'react-bootstrap/Form';
import { TextField } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import SearchBar from '../SearchBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  handelUpdateData,
  insertDataTempArr,
  updateMessage,
  handelRemoveBooking
} from '../Functions';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import {
  AttachFile,
  AudioTrack,
  Description,
  PictureAsPdf,
  Theaters
} from '@material-ui/icons';
import app from '../../../base';
import swal from 'sweetalert';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BookingData from './BookingData';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap'
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5)
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  }),
  { defaultTheme }
);

export default function Mybooking(props) {
  const [data, setData] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState('');
  const [selectedBuilding, setSelectedBuilding] = React.useState('');
  const [selectedAppartment, setSelectedAppartement] = React.useState('');
  const [selectedAppartmentState, setSelectedAppartmentState] =
    React.useState('');
  const [rows, setRows] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [bankState, setBankState] = React.useState('لايوجد');
  const [refresh, setRefresh] = React.useState(1);
  const [bankName, setBankName] = React.useState('لايوجد');
  const [files, setFiles] = React.useState();
  const [filesID, setFilesID] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [done, setDone] = React.useState();
  const [editData, setEditData] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen = () => {
    setOpen3(true);
  };
  const handleClose = () => {
    setOpen3(false);
  };

  const [showAllData, setShowAllData] = React.useState(false);

  const handelShowAllData = () => {
    setShowAllData(!showAllData);
  };

  let newAllData = [];
  let newBody = [];

  let bankNames = [
    'لايوجد',
    'البنك الأهلي التجاري',
    'البنك السعودي الفرنسي',
    'البنك الأول',
    'البنك السعودي للاستثمار',
    'البنك العربي الوطني',
    'بنك البلاد',
    'بنك الجزيرة',
    'بنك الرياض',
    'مجموعة سامبا المالية (سامبا)',
    'مصرف الراجحي',
    'مصرف الإنماء',
    'بنك الخليج الدولي - السعودية'
  ];

  let allBankState = [
    'لايوجد',
    'تحت الإجراء',
    'وقع العقود',
    'تقييم أول',
    'تقييم ثاني',
    'صدر الشيك',
    'تم الإفراغ',
    'كاش'
  ];

  React.useEffect(async () => {
    const temp = getBookingData(props.currentUserData, 'myBooking');
    temp.then(async (e) => {
      let temp2 = {};
      await props.projects.forEach((e) => {
        tempArr[e.name['en']] = e.name['ar'];
      });
      for (let [key, value] of Object.entries(e)) {
        temp2[tempArr[key]] = value;
        delete temp2[key];
      }

      setData(temp2);
    });
  }, [data.length == 0]);
  // convert all data in object to array contain project name and building number
  let tempArr = insertDataTempArr(data);

  if (tempArr.length !== 0 && rows.length === 0) {
    newAllData = tempArr;
    setRows(tempArr);
    setAllData(tempArr);
  }

  let tempProjectArr = [];
  tempArr.forEach((e) => {
    if (!tempProjectArr.includes(e.projectName))
      tempProjectArr.push(e.projectName);
  });

  let appartmentsArr = [];
  tempArr.forEach((e) => {
    if (selectedProject === e.projectName && e.done !== 'تم الإفراغ') {
      appartmentsArr.push({
        buildingNumber: e.buildingNumber,
        no_apartment: e.no_apartment
      });
    }
  });
  const classes = useStyles();

  let columns = [
    {
      field: 'edit',
      headerName: 'التحكم',
      width: 170,
      renderCell: (params) => (
        <strong className="edit_delte_buttons">
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ justifyContent: 'center' }}
            onClick={async (e) => {
              let clieckedData = '';
              await tempArr.forEach((e, index) => {
                if (e.id === params.id) {
                  clieckedData = e;
                }
              });
              setEditData(clieckedData);
              handleOpen();
            }}
          >
            عرض
          </Button>

          {params.row.done !== 'تم الإفراغ' ? (
            <>
              {' '}
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ justifyContent: 'center' }}
                onClick={async (e) => {
                  let clieckedData = '';
                  await tempArr.forEach((e, index) => {
                    if (e.id === params.id) {
                      clieckedData = e;
                    }
                  });
                  setEditData(clieckedData);
                  let index = -1;
                  let removedReason = '';
                  if (window.confirm('هل انت متأكد؟')) {
                    removedReason = prompt('الرجال إدخال سبب الإلغاء');
                    await allData.forEach((e, key) => {
                      if (
                        e.projectName === clieckedData.projectName &&
                        e.buildingNumber === clieckedData.buildingNumber &&
                        e.no_apartment === clieckedData.no_apartment
                      ) {
                        index = key;
                      }
                    });
                  }

                  if (index > -1) {
                    let tempAllData = JSON.parse(JSON.stringify(allData));
                    let data2 = JSON.parse(JSON.stringify(data));
                    let projectdeleted = clieckedData;
                    let removedBooking = tempAllData[index];
                    removedBooking['removedReason'] = removedReason;
                    handelRemoveBooking(removedBooking, props.currentUserData);
                    tempAllData.splice(index, 1);
                    let index2 = '';
                    await data2[projectdeleted.projectName][
                      projectdeleted.buildingNumber
                    ].forEach((e, key) => {
                      if (e.no_apartment === clieckedData.no_apartment) {
                        index2 = key;
                      }
                    });

                    await data2[projectdeleted.projectName][
                      projectdeleted.buildingNumber
                    ].splice(index2, 1);
                    let booking =
                      data2[projectdeleted.projectName][
                        projectdeleted.buildingNumber
                      ];
                    handelUpdateData(
                      booking,
                      clieckedData,
                      refresh,
                      setRefresh,
                      'delete',
                      props.currentUserData
                    );
                    setData(data2);
                    setAllData(tempAllData);
                    setRefresh(refresh + 1);
                    setSearch(true);
                  }

                  // handleOpen();
                }}
              >
                حذف
              </Button>
            </>
          ) : null}
        </strong>
      )
    },
    {
      field: 'done',
      headerName: 'تقييم الوكيل',
      width: 128
    },
    // { field: "state", headerName: "الحالة", width: 102, resizable: false },
    // {
    //   field: "IDFile",
    //   headerName: "الهوية",
    //   width: 103,
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ justifyContent: "center" }}
    //         onClick={async (e) => {
    //           let tempArrOnClicked = Object.values(e.nativeEvent.path[4]);
    //           let clieckedData = "";
    //           await tempArr.forEach((e, index) => {
    //             if (e.id === tempArrOnClicked[1]["data-id"]) {
    //               clieckedData = e;
    //             }
    //           });

    //           let storageRef = app.storage().ref();
    //           let fileRef = storageRef
    //             .child(`/ID/${clieckedData.insurFile}`)
    //             .getDownloadURL()
    //             .then((url) => {
    //               // `url` is the download URL for 'images/stars.jpg'
    //               window.open(url, "_blank");
    //               // This can be downloaded directly:
    //               // var xhr = new XMLHttpRequest();
    //               // xhr.responseType = "blob";
    //               // xhr.onload = (event) => {
    //               //   var blob = xhr.response;
    //               // };

    //               // xhr.open("GET", url);
    //               // xhr.send();
    //             })
    //             .catch((error) => {
    //               // Handle any errors
    //             });
    //         }}
    //       >
    //         عرض
    //       </Button>
    //     </strong>
    //   ),
    // },
    // {
    //   field: "insurFile",
    //   headerName: "ملف الصك",
    //   width: 127,
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ justifyContent: "center" }}
    //         onClick={async (e) => {
    //           let tempArrOnClicked = Object.values(e.nativeEvent.path[4]);
    //           let clieckedData = "";
    //           await tempArr.forEach((e, index) => {
    //             if (e.id === tempArrOnClicked[1]["data-id"]) {
    //               clieckedData = e;
    //             }
    //           });

    //           let storageRef = app.storage().ref();
    //           let fileRef = storageRef
    //             .child(`/instrument/${clieckedData.insurFile}`)
    //             .getDownloadURL()
    //             .then((url) => {
    //               // `url` is the download URL for 'images/stars.jpg'
    //               window.open(url, "_blank");
    //               // This can be downloaded directly:
    //               // var xhr = new XMLHttpRequest();
    //               // xhr.responseType = "blob";
    //               // xhr.onload = (event) => {
    //               //   var blob = xhr.response;
    //               // };

    //               // xhr.open("GET", url);
    //               // xhr.send();
    //             })
    //             .catch((error) => {
    //               // Handle any errors
    //             });
    //         }}
    //       >
    //         عرض
    //       </Button>
    //     </strong>
    //   ),
    // },
    // {
    //   field: "date",
    //   headerName: "التاريخ",
    //   width: 150,
    // },
    // {
    //   field: "bankState",
    //   headerName: "حالة البنك",
    //   description:
    //     "ادخل 1 اذا كان تحت الاجراء  ادخل 2 اذاتم توقع العقود  ادخل 3 في حالة تقييم أول  ادخل 4 في حالة تقييم ثاني ادخل 5 اذا صدر الشيك  ادخل 6 اذا تم الإفراغ ادخل 7 في حالة الكاش",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: "bankName",
    //   headerName: "إسم البنك",
    //   width: 150,
    // },
    // {
    //   field: "instrument_No",
    //   headerName: "رقم الصك",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: "excess_Money",
    //   headerName: "الفائض",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: "FD_Money",
    //   headerName: "دفعة أولى",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: "sold",
    //   headerName: "سعر البيع",
    //   width: 120,
    // },

    // {
    //   field: "depositReturn",
    //   headerName: "إرجاع العربون",
    //   width: 150,
    //   editable: true,
    // },

    // {
    //   field: "deposit",
    //   headerName: "العربون",
    //   width: 150,
    //   editable: true,
    // },

    {
      field: 'client_phone',
      headerName: 'الجوال',
      // type: 'number',
      width: 150,
      editable: true
    },
    {
      field: 'client_name',
      headerName: 'إسم العميل',
      width: 150,
      editable: true
    },
    // {
    //   field: "space",
    //   headerName: "المساحة",
    //   width: 150,
    // },
    {
      field: 'secondAppartmentNumber',
      headerName: 'رقم الشقة الجديد',
      width: 150
    },
    {
      field: 'no_apartment',
      headerName: 'رقم الشقة',
      width: 150
    },
    {
      field: 'buildingNumber',
      headerName: 'رقم المبنى',
      width: 150
    },
    {
      field: 'projectName',
      headerName: 'المشروع',
      width: 150
    }
  ];

  if (props.currentUserData.isAdmin || props.currentUserData.isOwner) {
    columns.splice(2, 0, {
      field: 'employe_name',
      headerName: 'إسم الموظف',
      width: 150
    });
  }

  if (showAllData) {
    columns.splice(5, 0, {
      field: 'space',
      headerName: 'المساحة',
      width: 150
    });
    columns.splice(
      3,
      0,
      {
        field: 'date',
        headerName: 'التاريخ',
        width: 150
      },
      {
        field: 'bankState',
        headerName: 'حالة البنك',
        description:
          'ادخل 1 اذا كان تحت الاجراء  ادخل 2 اذاتم توقع العقود  ادخل 3 في حالة تقييم أول  ادخل 4 في حالة تقييم ثاني ادخل 5 اذا صدر الشيك  ادخل 6 اذا تم الإفراغ ادخل 7 في حالة الكاش',
        width: 150,
        editable: true
      },
      {
        field: 'bankName',
        headerName: 'إسم البنك',
        width: 150
      },

      {
        field: 'date',
        headerName: 'تاريخ الحجز',
        width: 150
      },

      {
        field: 'instrument_No',
        headerName: 'رقم الصك',
        width: 150
      },

      {
        field: 'total',
        headerName: 'الإجمالي شامل الضريبة',
        width: 190
      },

      {
        field: 'VAT',
        headerName: 'قيمة التصرفات العقارية',
        width: 190
      },
      {
        field: 'price',
        headerName: 'سعر الشقة',
        width: 150
      },
      {
        field: 'VATState',
        headerName: 'حالة التصرفات العقارية',
        width: 190
      },
      {
        field: 'depositReturn',
        headerName: 'المرتجع',
        width: 150
      },
      {
        field: 'deposit',
        headerName: 'العربون',
        width: 150
      },
      {
        field: 'state',
        headerName: 'الحالة',
        width: 150
      },
      {
        field: 'clientID',
        headerName: 'الهوية',
        width: 150
      }
    );
  }

  const handleChange = (files) => {
    setFiles(files);
  };

  return (
    <div className="mybookingDiv">
      <input type="hidden" value={refresh} />
      <h3 style={{ textAlign: 'center', direction: 'rtl' }}>إدارة المبيعات</h3>
      <p style={{ direction: 'rtl', textAlign: 'center' }}>
        اهلا{' '}
        <span style={{ color: 'rgb(6 105 69)' }}>
          {props.currentUserData.name}
        </span>
      </p>

      {editData ? (
        <>
          {' '}
          <BookingData
            open3={open3}
            handleClose={handleClose}
            editData={editData}
            data={data}
            setData={setData}
            allData={allData}
            setAllData={setAllData}
            setSearch={setSearch}
            refresh={refresh}
            setRefresh={setRefresh}
            currentUserData={props.currentUserData}
          />
        </>
      ) : null}

      <SearchBar
        data={data}
        allData={allData}
        columns={columns}
        setData={setData}
        currentUserData={props.currentUserData}
        from={'booking'}
        setSelectedProject={setSelectedProject}
        setSelectedBuilding={setSelectedBuilding}
        setSelectedAppartement={setSelectedAppartement}
        setSelectedAppartmentState={setSelectedAppartmentState}
        setBankName={setBankName}
        setBankState={setBankState}
        setDone={setDone}
        changeSearch={search}
        setSearch={setSearch}
        handelShowAllData={handelShowAllData}
      />
      <p className="noteMybooking">
        * تنبيه في حالة إعتماد الحالة من قبل الوكيل لن تتمكن من التعديل عليها
        الا عن طريق الادمن
      </p>

      {/* <div className="updateBooking">
        <div className="selectProjectDiv">
          <Form.Control
            required
            as="select"
            onChange={(e) => {
              setSelectedProject(e.target.value);
              if (e.target.value === "") {
                setSelectedBuilding("");
                setSelectedAppartement("");
                setSelectedAppartmentState("");
              }
            }}
            value={selectedProject}
          >
            <option value="" key={-1}>
              اختر المشروع
            </option>
            {tempProjectArr.map((e, key) => {
              return (
                <option value={e} key={key}>
                  {`${e}`}
                </option>
              );
            })}
          </Form.Control>
        </div>
        {selectedProject !== "" ? (
          <div className="selectAppartmentDiv">
            <Form.Control
              required
              as="select"
              onChange={(e) => {
                const array = e.target.value.split(",");
                if (e.target.value === "") {
                  setSelectedBuilding("");
                  setSelectedAppartement("");
                  setSelectedAppartmentState("");
                } else {
                  setSelectedBuilding(array[0]);
                  setSelectedAppartement(array[1]);
                  tempArr.forEach((e) => {
                    if (
                      e.projectName == selectedProject &&
                      e.buildingNumber == array[0] &&
                      e.no_apartment == array[1]
                    ) {
                      setSelectedAppartmentState(e.state);
                      if (e.bankName) setBankName(e.bankName);
                      else setBankName("لايوجد");
                      if (e.bankState) setBankState(e.bankState);
                      else setBankState("لايوجد");
                    }
                  });
                }
              }}
              value={[selectedBuilding, selectedAppartment]}
            >
              <option value="" key={-1}>
                اختر الشقة
              </option>
              {appartmentsArr.map((e, key) => {
                return (
                  <option value={[e.buildingNumber, e.no_apartment]} key={key}>
                    {`مبنى : ${e.buildingNumber} شقة : ${e.no_apartment}`}
                  </option>
                );
              })}
            </Form.Control>
          </div>
        ) : null}
        <div className="state_bank_div">
          <div className="selectAppartmentStateDiv">
            {selectedAppartment !== "" && appartmentsArr.length !== 0 ? (
              <>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setSelectedAppartmentState(e.target.value)}
                  value={selectedAppartmentState}
                >
                  <option value="" key={-1}>
                    اختر
                  </option>
                  <option value="إلغاء الحجز" key={1}>
                    إلغاء الحجز
                  </option>
                  <option value="محجوزه" key={2}>
                    حجز
                  </option>
                  <option value="مباعه" key={3}>
                    بيع
                  </option>
                </Form.Control>{" "}
                {selectedAppartmentState !== "إلغاء الحجز" &&
                selectedAppartmentState !== "" ? (
                  <>
                    <div className="bankNameDiv">
                      <Autocomplete
                        value={bankName}
                        id="combo-box-demo"
                        options={bankNames}
                        getOptionLabel={(option) => option}
                        onChange={(e) => setBankName(e.target.innerHTML)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="البنك"
                            variant="filled"
                          />
                        )}
                      />
                    </div>

                    <TextField
                      id="standard-select"
                      select
                      label="حالة البنك"
                      required
                      onChange={(e) => setBankState(e.target.value)}
                      value={bankState}
                    >
                      {allBankState.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <div className="uploadDiv">
                      <DropzoneDialog
                        Icon={PictureAsPdf}
                        acceptedFiles={[".pdf"]}
                        dropzoneText={"قم بسحب وإسقاط الصورة أو انقر هنا"}
                        cancelButtonText={"cancel"}
                        submitButtonText={"submit"}
                        maxFileSize={5000000}
                        open={open2}
                        onClose={() => setOpen2(false)}
                        onSave={(e) => {
                          // console.log("Files:", files[0]);
                          // uploadImages(files[0]);
                          setFiles(e[0]);
                          setOpen2(false);
                        }}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        filesLimit={1}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpen2(true);
                        }}
                      >
                        رفع ملف الصك
                      </Button>
                    </div>

                    <div className="uploadDiv">
                      <DropzoneDialog
                        Icon={PictureAsPdf}
                        acceptedFiles={[".pdf"]}
                        dropzoneText={"قم بسحب وإسقاط الصورة أو انقر هنا"}
                        cancelButtonText={"cancel"}
                        submitButtonText={"submit"}
                        maxFileSize={5000000}
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={(e) => {
                          // console.log("Files:", files[0]);
                          // uploadImages(files[0]);
                          setFilesID(e[0]);
                          setOpen(false);
                        }}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        filesLimit={1}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        رفع ملف الهوية
                      </Button>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>

        {selectedAppartmentState !== "" ? (
          <div className="buttonBookingUpdate">
            <button
              onClick={(e) => {
                let file = files;
                let ID_File = filesID;
                let storageRef = app.storage().ref();
                let fileRef = storageRef.child(
                  `instrument/${selectedProject}/${selectedBuilding}/${selectedAppartment}`
                );

                if (file) {
                  fileRef.put(file).then(() => {});
                }
                fileRef = storageRef.child(
                  `ID/${selectedProject}/${selectedBuilding}/${selectedAppartment}`
                );

                if (ID_File) {
                  fileRef.put(ID_File).then(() => {});
                }

                let filterTemp = tempArr.filter(
                  (e) =>
                    e.projectName == selectedProject &&
                    e.buildingNumber == selectedBuilding &&
                    e.no_apartment == selectedAppartment
                );
                let done = filterTemp[0]["done"];
                handelUpdateData(
                  selectedProject,
                  selectedBuilding,
                  selectedAppartment,
                  selectedAppartmentState,
                  bankState,
                  bankName,
                  allData,
                  setAllData,
                  refresh,
                  setRefresh,
                  data,
                  done,
                  props.currentUserData.name
                );

                updateMessage();
              }}
            >
              تنفيذ
            </button>
          </div>
        ) : null}
      </div>
      <div id="myimg"></div> */}
    </div>
  );
}
