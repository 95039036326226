import React from 'react';

import Tasks from './Tasks';
import { TextField } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

/**/
import dayjs from 'dayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

 
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function BoxTask({
  currentList,
  userEmail,
  allProjects,
  userAttendance,
  handelShowingReport
}) {
  let [leave, setLeave] = React.useState(false);
  let [attend, setAttend] = React.useState(true);
  let [selected, setSelected] = React.useState("")
  const handelAfterAttend = () => {
    setLeave(true);
    setAttend(false);
    console.log('Done');
  };
  let newDate = new Date();
  const tasks = {
    الرئيسية: [
      {
        className: 'homeTasks1',
        paragraph: 'التحضير',
        isShowing:
          attend &&
          (Object.keys(userAttendance) !== 0
            ? `${parseInt(
                newDate.getMonth() + 1
              )}-${newDate.getDate()}-${newDate.getFullYear()}` in
              userAttendance
              ? userAttendance[
                  parseInt(newDate.getMonth() + 1) +
                    '-' +
                    newDate.getDate() +
                    '-' +
                    newDate.getFullYear()
                ]['attend']
                ? false
                : true
              : true
            : true)
      },
      {
        className: 'homeTasks2',
        paragraph: 'الإنصراف',
        isShowing:
          leave ||
          (Object.keys(userAttendance) !== 0
            ? `${parseInt(
                newDate.getMonth() + 1
              )}-${newDate.getDate()}-${newDate.getFullYear()}` in
              userAttendance
              ? `attend` in
                userAttendance[
                  parseInt(newDate.getMonth() + 1) +
                    '-' +
                    newDate.getDate() +
                    '-' +
                    newDate.getFullYear()
                ]
                ? 'leave' in
                  userAttendance[
                    parseInt(newDate.getMonth() + 1) +
                      '-' +
                      newDate.getDate() +
                      '-' +
                      newDate.getFullYear()
                  ]
                  ? false
                  : true
                : true
              : false
            : true)
      }
    ],
    التقارير: [
      {
        className: 'reportTask1',
        paragraph: 'تقرير الحضور',
        isShowing: true
      },
      {
        className: 'reportTask2',
        paragraph: 'تقرير الإستئذان',
        isShowing: false
      },
      {
        className: 'reportTask3',
        paragraph: 'تقرير الإجازة',
        isShowing: false
      }
    ],
    الطلبات: [
      {
        className: 'orderTasks1',
        paragraph: 'طلب إستئذان',
        isShowing: false
      },
      {
        className: 'orderTasks2',
        paragraph: 'طلب إجازة',
        isShowing: false
      }
    ]
  };

  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const Buttons = () => {
    return (
      <div className="buttons">
        
          <button
            className="btn btn-primary next save"
          >
            حفظ
          </button>
       
        <button
          className="btn btn-primary past cancel"
          onClick={handleClose}
        >
          إلغاء
        </button>
      </div>
    );
  };

  const TasksLoader = tasks[currentList].map((e, key) => (
    <Tasks
      task={e}
      key={key}
      userEmail={userEmail}
      allProjects={allProjects}
      userAttendance={userAttendance}
      handelShowingReport={handelShowingReport()}
      handelAfterAttend={handelAfterAttend}
      setSelected={setSelected}
      setOpen={setOpen}
    />
  ));


  const [value, setValue] = React.useState(dayjs('2022-10-13T17:50:20'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  return open === false ? <div className="BoxTaskDiv">{TasksLoader}</div> : <>      
  <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
  className="bookingDataEdite"
>
  <Box className="mainBoxINFO" sx={{ ...style, width: "80%" }}>
    <h4
      style={{
        width: "40%",
        textAlign: "center",
        margin: "auto",
        marginTop: "3%",
      }}
      onClick={(e) => setHidden(!hidden)}
    >
      طلب الإستئذان
    </h4>

    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {
        /*
              <div className="tabsContent myBookingData">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
          {/* <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          /> 
          <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
    
        </LocalizationProvider>
      </div>
      <Buttons />
      */}

       
    </Box>
  
  </Box>
</Modal></>;
}