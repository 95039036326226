import React, { useEffect, useState } from 'react';
import { checkPrivilage, updateUserLastSeen } from './api.js';
import app from './base.js';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import AwtarGIF from './component/images/AwtarGIF3.gif';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    backgroundColor: 'white'
  },
  barColorPrimary: {
    backgroundColor: '#d9af43'
  }
}));
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [currentUserData, setCurrentUserData] = React.useState({});
  const classes = useStyles();

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await checkPrivilage(user.email).then((e) => {
          setCurrentUserData(e.data);
        });
        updateUserLastSeen(user.email);
      }
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <div>
        <img
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          src={AwtarGIF}
          alt="this slowpoke moves"
          width="250"
        />
        <Box
          style={{
            position: 'fixed',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%'
          }}
          sx={{ width: '100%' }}
        >
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary
            }}
          />
        </Box>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
