import React from "react";
import "./orderHome.css";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { updateMessage } from "../Functions";
import { addNewOrder } from "../../../api";

export default function OrderHome(props) {
  const [clientName, setClientName] = React.useState("");
  const [clientPhone, setClientPhone] = React.useState("");
  const [numberOfRooms, setNumberOfRooms] = React.useState(1);
  const [averagePrice, setAveragePrice] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [typeOfOrder, setTypeOfOrder] = React.useState("شقة");
  const [paymentMethod, setPaymentMethod] = React.useState("كاش");

  const rooms = [1, 2, 3, 4, 5, 6, 7, 8];
  const allDistricts = [
    "ابحر الشمالية",
    "الاجاويد",
    "الاجواد",
    "الاصالة",
    "الامواج",
    "الأمير عبدالمجيد",
    "الأمير فواز الجنوبي",
    "الأمير فواز الشمالي",
    "البساتين",
    "البشائر",
    "البغدادية الشرقية",
    "البغدادية الغربية",
    "البلد",
    "البوادي",
    "التوفيق",
    "الثعالبة",
    "الثغر",
    "الجامعة",
    "الجوهرة",
    "الحمدانية",
    "الحمراء",
    "الخالدية",
    "الخمرة",
    "الربوة",
    "الربيع",
    "الرحاب",
    "الرحمانية",
    "الروابي",
    "الروضة",
    "الرويس",
    "الرياض",
    "الريان",
    "الزمرد",
    "الزهراء",
    "السامر",
    "السبيل",
    "السروات",
    "السرورية",
    "السلامة",
    "السليمانية",
    "السنابل",
    "الشاطئ",
    "الشراع",
    "الشرفية",
    "الشروق",
    "الشفا",
    "الصالة",
    "الصفا",
    "الصفة",
    "الصواري",
    "العدل",
    "العزيزية",
    "العضيان",
    "العمارية",
    "العين العزيزية",
    "الفاروق",
    "الفردوس",
    "الفروسية",
    "الفضل",
    "الفضيلة",
    "الفلاح",
    "الفيحاء",
    "الفيصلية",
    "القريات",
    "القوزين",
    "الكندرة",
    "الكوثر",
    "اللؤلؤ",
    "المحاميد",
    "المحجر",
    "المحمدية",
    "المدينة الصناعية",
    "المرجان",
    "المروة",
    "المنار",
    "المنتزه",
    "المنتزهات",
    "النزلة الشرقية",
    "النزلة اليمانية",
    "النزهة",
    "النسيم",
    "النعيم",
    "النهضة",
    "الهدى",
    "الهنداوية",
    "الواحة",
    "البوادي",
    "الورود",
    "الوزيرية",
    "الياقوت",
    "ام حبلين",
    "أبحر الجنوبية",
    "بترومين",
    "بريمان",
    "بني مالك",
    "ذهبان",
    "طبية",
    "غليل",
    "قويزة",
    "مدائن ال فهد",
    "مريخ",
    "مشرفه",
    "مخطط التيسير",
    "مخطط الفهد",
  ];

  return (
    <div className="orderHomeDiv">
      <h4>طلب شقة </h4>
      <div className="tabsContent myBookingData">
        <TextField
          id="standard-basic"
          label="اسم العميل"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        ></TextField>

        <TextField
          id="standard-basic"
          label="رقم الجوال"
          value={clientPhone}
          onChange={(e) => setClientPhone(e.target.value)}
        ></TextField>

        <TextField
          id="standard-basic"
          label="عدد الغرف"
          value={numberOfRooms}
          onChange={(e) => setNumberOfRooms(e.target.value)}
          select
        >
          {rooms.map((e, key) => {
            return (
              <MenuItem key={key} value={e}>
                {e}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          id="standard-basic"
          label="السعر التقريبي"
          value={averagePrice}
          onChange={(e) => setAveragePrice(e.target.value)}
        ></TextField>

        <div className="distractDiv bankNameDiv MuiFormControl-root">
          <Autocomplete
            value={district}
            id="combo-box-demo"
            options={allDistricts}
            getOptionLabel={(option) => option}
            onChange={(e) => {
              if (allDistricts.includes(e.target.innerHTML)) {
                setDistrict(e.target.innerHTML);
              } else {
                district !== ""
                  ? setDistrict(e.target.innerHTML)
                  : setDistrict("");
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="الحي" variant="filled" />
            )}
          />
        </div>

        <TextField
          id="standard-basic"
          label="نوع السكن"
          value={typeOfOrder}
          onChange={(e) => setTypeOfOrder(e.target.value)}
          select
        >
          <MenuItem key={1} value="شقة">
            شقة
          </MenuItem>
          <MenuItem key={2} value="روف">
            روف
          </MenuItem>
        </TextField>

        <TextField
          id="standard-basic"
          label="طريقة الدفع"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          select
        >
          <MenuItem key={1} value="كاش">
            كاش
          </MenuItem>
          <MenuItem key={2} value="بنك">
            بنك
          </MenuItem>
        </TextField>
      </div>
      <div style={{ width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block", margin: "0 auto" }}
          onClick={(e) => {
            updateMessage("add");
            console.log(props.currentUserData.name);
            let newOrder = {
              client_name: clientName,
              client_phone: clientPhone,
              numberOfRooms: numberOfRooms,
              price: averagePrice,
              district: district,
              type: typeOfOrder,
              paymentMethod: paymentMethod,
            };

            addNewOrder(props.currentUserData.name, newOrder);
          }}
        >
          حفظ
        </Button>
      </div>
    </div>
  );
}
