import React from "react";
import "./Home.css";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { updateUserPassword } from "../../api";
import { updateMessage } from "../Booking/Functions";
import app from "../../base";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default function Home(props) {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const Buttons = () => {
    return (
      <div className="buttons updateButton">
        <button
          className="btn btn-primary "
          onClick={(e) => {
            if (password.length >= 6 && password == confirmPassword) {
              app
                .auth()
                .currentUser.updatePassword(password)
                .then((e) => {
                  updateMessage("update");
                });
            }
          }}
        >
          تحديث
        </button>
      </div>
    );
  };

  return (
    <div className="settingDiv">
      <div className="">
        <div className="updatePassword">
          <TextField
            id="standard-basic"
            label="كلمة المرور الجديدة"
            value={password}
            error={password.length < 6 && password !== "" ? true : false}
            onChange={(e) => setPassword(e.target.value)}
            required
          ></TextField>

          <TextField
            id="standard-basic"
            label="تأكيد كلمة المرور"
            value={confirmPassword}
            error={password !== confirmPassword ? true : false}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            required
            type="password"
          ></TextField>

          <Buttons />
        </div>
      </div>
    </div>
  );
}
