import React, { useState, useEffect } from 'react';
import './time.css';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as CalenderIcon } from '../../icons/calendar.svg';

export default function Time() {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000);
  }, []);
  return (
    <div className="time">
      <p>
        {' '}
        <CalenderIcon />
        {'التاريخ:  '}
        {dateState.toLocaleDateString('ar-GB', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })}{' '}
        <ClockIcon />
        {'الوقت:  '}
        {dateState.toLocaleString('ar-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        })}
      </p>
    </div>
  );
}
