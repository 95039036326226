import React from "react";
import { getDataID, updateProjectToDB } from "../../api";
import Buildings from "./Buildings";
import Images from "./Images";
import "./Buildings.css";
import "./SingleProject.css";
import swal from "sweetalert";
import Form from "react-bootstrap/Form";
import {
  AttachFile,
  AudioTrack,
  Description,
  PictureAsPdf,
  Theaters,
} from "@material-ui/icons";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import app from "../../base";
import { service } from "firebase-functions/lib/providers/analytics";

export default function SingleProject(props) {
  const [projectsArr, setProjectsArr] = React.useState([]);
  const [check, setCheck] = React.useState(true);
  const [projectNameAr, setProjectNameAr] = React.useState("");
  const [projectNameEn, setProjectNameEn] = React.useState("");
  const [projectStateAr, setProjectStateAr] = React.useState("");
  const [projectStateEn, setProjectStateEn] = React.useState("");
  const [projectLocation, setProjectLocation] = React.useState("");
  const [projectImage, setProjectImage] = React.useState("");
  const [projectOrder, setProjectOrder] = React.useState("");
  const [currentProject, setCurrentProject] = React.useState(null);
  const [projectBooking, setProjectBooking] = React.useState({});
  const [projectBuildingNameAr, setProjectBuildingNameAr] = React.useState("");
  const [projectBuildingNameEn, setProjectBuildingNameEn] = React.useState("");
  const [projectBuildingImage, setProjectBuildingImage] = React.useState("");
  const [projectBuildingStateAr, setProjectBuildingStateAr] =
    React.useState("");
  const [projectBuildingStateEn, setProjectBuildingStateEn] =
    React.useState("");
  const [projectBuidingOrder, setProjectBuidingOrder] = React.useState("");
  const [projectBuildingImages, setProjectBuildingImages] = React.useState([]);

  const [projectBuildingDetailsAr, setProjectBuildingDetailsAr] =
    React.useState();
  const [projectBuildingDetailsEn, setProjectBuildingDetailsEn] =
    React.useState();
  const [newImage, setNewImage] = React.useState("");
  const [refresh, setRefresh] = React.useState(1);
  const [done, setDone] = React.useState(false);

  const [buiding_no, setBuilding_no] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [mainIMG, setMainIMG] = React.useState([]);
  const [serviceState, setServiceState] = React.useState("false");

  React.useEffect(() => {
    if (projectsArr.length === 0) {
      getDataID(props.match.params.id).then((res) => {
        const proj = res[0];
        setProjectsArr(proj);
        setProjectNameAr(proj.name["ar"]);
        setProjectNameEn(proj.name["en"]);
        setProjectStateAr(proj.state["ar"]);
        setProjectStateEn(proj.state["en"]);
        setProjectLocation(proj.location);
        setProjectImage(proj.img);
        setProjectOrder(proj.order);
        setProjectBooking(proj.booking);
        if (proj.no_Buildings) {
          setBuilding_no(proj.no_Buildings);
        }

        setServiceState(serviceState);
      });
    }
  }, [props]);

  let buildings = null;
  let allBuildings = null;
  if (projectsArr.length !== 0 && projectsArr.project.length !== 0) {
    buildings = projectsArr.project.map((e, index) => (
      <Buildings building={e} key={index} />
    ));

    const handelChangeProject = (e, projectData) => {
      e.preventDefault();
      setCurrentProject(projectData);
      setCheck(true);
    };

    const deleteBuilding = (e, building) => {
      e.preventDefault();
      let buildings = [...projectsArr.project];
      buildings.splice(building, 1);
      let newProject = projectsArr;
      newProject.project = buildings;
      setProjectsArr(newProject);
      setRefresh(refresh + 1);
    };

    allBuildings = projectsArr.project.map((e, index) => {
      return (
        <div className="div-building" key={index}>
          <a
            href="#"
            className="each-building"
            onClick={(ee) => handelChangeProject(ee, e)}
          >
            <p>{`${e.name["ar"]} غرف`}</p>
            <div
              className="deleteBuilding"
              onClick={(ee) => {
                deleteBuilding(ee, index);
                setCurrentProject(null);
                setRefresh(refresh + 1);
              }}
            >
              x
            </div>
          </a>
        </div>
      );
    });
  }

  const handelImages = (e) => {
    e.preventDefault();
    let images = projectBuildingImages;
    images.push(newImage);
    setProjectBuildingImages(allImages);
  };

  const handelDeleteImage = (e) => {
    let images = projectBuildingImages;
    images.splice(e - 1, 1);
  };

  let allImages = projectBuildingImages.map((e, index) => (
    <Images
      images={e}
      number={index + 1}
      key={index}
      handelDeleteImage={handelDeleteImage}
    />
  ));

  const addBuilding = (e) => {
    e.preventDefault();

    let buildings = [...projectsArr.project];
    let build = {
      name: {
        ar: "غير مصنف",
        en: "unclassified",
      },
      img: "",
      order: "",
      state: {
        ar: "متوفر",
        en: "Available",
      },
      details: {
        ar: [],
        en: [],
      },
      images: [],
      figures: [],
    };
    let newProjectsArr = projectsArr;
    newProjectsArr.project.push(build);
    setProjectsArr(newProjectsArr);
    setCurrentProject(build);
    setRefresh(refresh + 1);
  };

  const updateProject = async () => {
    // e.preventDefault();

    // let file = mainIMG;
    // let storageRef = app.storage().ref();
    // let fileRef = storageRef.child(`Images/${projectNameEn}/main/main.jpg`);
    let tempUrl = false;
    // if (file && projectImage === "") {
    //   await fileRef.put(file).then(() => {});
    //   fileRef = await storageRef
    //     .child(`/Images/${projectNameEn}/main/main.jpg`)
    //     .getDownloadURL()
    //     .then(async (url) => {
    //       tempUrl = url;
    //       await setProjectImage(url);
    //     })
    //     .catch((error) => {
    //       // Handle any errors
    //     });
    // }

    swal("تم", "التعدل بنجاح", "success");
    let project = {
      name: {
        ar: projectNameAr,
        en: projectNameEn,
      },
      state: {
        ar: projectStateAr,
        en: projectStateEn,
      },
      location: projectLocation,
      no_Buildings: buiding_no,

      img: tempUrl === false ? projectImage : tempUrl,
      order: projectOrder,
      project: projectsArr.project,
      booking: projectBooking,
      isService: serviceState === true ? true : serviceState.toLowerCase() == "true" ? true : false,
    };
    updateProjectToDB(
      props.match.params.id,
      project,
      props.currentUserData.name
    ).then((response) => {});
    setDone(false);
  };

  const changeImageBuilding = (image, index) => {
    let newCurrentProject = currentProject;
    newCurrentProject.images[index - 1] = image;
    setCurrentProject(newCurrentProject);
  };

  const deleteImageBuilding = (index) => {
    let newCurrentProject = currentProject;
    newCurrentProject.images.splice(index - 1, 1);
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };

  const addImageBuilding = (image) => {
    let newCurrentProject = currentProject;
    newCurrentProject.images.push(image);
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };

  const handelNameArChange = (name) => {
    let newCurrentProject = currentProject;
    newCurrentProject.name["ar"] = name;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelNameEnChange = (name) => {
    let newCurrentProject = currentProject;
    newCurrentProject.name["en"] = name;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelDetailsArChange = (index, detail) => {
    let newCurrentProject = currentProject;
    newCurrentProject.details["ar"][index] = detail;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelDetailsEnChange = (index, detail) => {
    let newCurrentProject = currentProject;
    newCurrentProject.details["en"][index] = detail;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelStateArChange = (state) => {
    let newCurrentProject = currentProject;
    newCurrentProject.state["ar"] = state;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelStateEnChange = (state) => {
    let newCurrentProject = currentProject;
    newCurrentProject.state["en"] = state;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelImageChange = (img) => {
    let newCurrentProject = currentProject;
    newCurrentProject.img = img;
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };
  const handelOrderChange = (order) => {
    let newOrder = "";
    switch (order) {
      case "بدا الحجز":
        setProjectStateAr("بدا الحجز");
        setProjectStateEn("Booking");
        newOrder = 2;
        break;
      case "بدا البيع":
        setProjectStateAr("بدا البيع");
        setProjectStateEn("for Sale");
        newOrder = 1;
        break;
      case "قريبا":
        setProjectStateAr("قريبا");
        setProjectStateEn("Soon");
        newOrder = 3;
        break;
      case "مباع":
        setProjectStateAr("مباع");
        setProjectStateEn("Sold");
        newOrder = 4;
    }
    setRefresh(refresh + 1);
    setProjectOrder(newOrder);
  };

  const handelStateBuildingChange = (order) => {
    let newCurrentProject = currentProject;
    let newOrder = "";
    let stateAr = "";
    let stateEn = "";
    switch (order) {
      case "متوفر":
        stateAr = "متوفر";
        stateEn = "Available";
        newOrder = 1;
        break;
      case "مباع":
        stateAr = "مباع";
        stateEn = "Sold";
        newOrder = 2;
    }
    // setRefresh(refresh + 1);
    newCurrentProject.state["ar"] = stateAr;
    newCurrentProject.state["en"] = stateEn;
    newCurrentProject.order = newOrder;
    setCurrentProject(newCurrentProject);
  };

  const handelOrderBuildingChange = (order) => {
    let newCurrentProject = currentProject;
    newCurrentProject.order = order;
    setCurrentProject(newCurrentProject);
  };

  const getProjectOrderValue = (order) => {
    let value = "";
    switch (order) {
      case 1:
        value = "بدا البيع";
        break;
      case 2:
        value = "بدا الحجز";
        break;
      case 3:
        value = "قريبا";
        break;
      case 4:
        value = "مباع";
        break;
    }

    return value;
  };

  const handelAddDetails = () => {
    let newCurrentProject = currentProject;
    newCurrentProject.details["ar"].push("");
    newCurrentProject.details["en"].push("");
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };

  const handelRemoveDetails = (lang, index) => {
    let newCurrentProject = currentProject;
    newCurrentProject.details["ar"].splice(index, 1);
    newCurrentProject.details["en"].splice(index, 1);
    setCurrentProject(newCurrentProject);
    setRefresh(refresh + 1);
  };

  const handelUpdateFigure = (figures) => {
    let newCurrentProject = currentProject;
    newCurrentProject.figures = figures;
  };

  return (
    <div className="dashboard-singleProject">
      <input type="hidden" value={refresh} />
      <div className="dashboard-singleProjectInfo">
        <label>إسم المشروع بالعربي</label>

        <input
          value={projectNameAr}
          onChange={(e) => setProjectNameAr(e.target.value)}
        ></input>
        <label>إنجليزي بالإنجليزي</label>
        <input
          value={projectNameEn}
          onChange={(e) => setProjectNameEn(e.target.value)}
        ></input>
        <br />
        <label>حالة المشروع</label>
        <Form.Control
          required
          as="select"
          onChange={(e) => handelOrderChange(e.target.value)}
          value={getProjectOrderValue(projectOrder)}
          // dir={`${this.props.i18n.language === "ar" ? "rtl" : "ltr"}`}
        >
          <option>بدا الحجز</option>
          <option>بدا البيع</option>
          <option>قريبا</option>
          <option>مباع</option>
        </Form.Control>

        <br />
        <label>موقع المشروع</label>
        <input
          value={projectLocation}
          onChange={(e) => setProjectLocation(e.target.value)}
        ></input>
        <br />

        <label>عدد العمائر</label>
        <input
          value={buiding_no}
          onChange={(e) => setBuilding_no(e.target.value)}
        ></input>
        <br />
        <label>هل يوجد صيانة ؟</label>
        <Form.Control
          required
          as="select"
          onChange={(e) => setServiceState(e.target.value)}
          value={serviceState}
          // dir={`${this.props.i18n.language === "ar" ? "rtl" : "ltr"}`}
        >
          <option value={true}>نعم</option>
          <option value={false}>لا</option>
        </Form.Control>
        <br />

        <label>رابط صورة المشروع</label>
        <input
          value={projectImage}
          onChange={(e) => setProjectImage(e.target.value)}
        ></input>
        {/* <h5>او</h5>
        <div className="uploadDiv">
          <DropzoneDialog
            acceptedFiles={[`image/*`]}
            dropzoneText={"قم بسحب وإسقاط الصورة أو انقر هنا"}
            cancelButtonText={"cancel"}
            submitButtonText={"submit"}
            maxFileSize={5000000}
            open={open}
            onClose={() => setOpen(false)}
            onSave={(e) => {
              setMainIMG(e[0]);
              setOpen(false);
              setProjectImage("");
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            filesLimit={1}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            رفع صورة المشروع
          </Button>
        </div> */}

        <br />
        <img src={projectImage} alt="Project Image" />
      </div>
      <br />
      {/* نماذج المشروع */}
      <div className="div-eachProject">
        {allBuildings}
        <div className="div-addBuilding" onClick={(e) => addBuilding(e)}>
          <p>إضافة تصنيف</p>
        </div>
      </div>

      {currentProject ? (
        <Buildings
          building={currentProject}
          updateProject={updateProject}
          done={done}
          setDone={setDone}
          changeImageBuilding={changeImageBuilding}
          deleteImageBuilding={deleteImageBuilding}
          addImageBuilding={addImageBuilding}
          handelNameArChange={handelNameArChange}
          handelNameEnChange={handelNameEnChange}
          handelDetailsArChange={handelDetailsArChange}
          handelDetailsEnChange={handelDetailsEnChange}
          handelStateArChange={handelStateArChange}
          handelStateEnChange={handelStateEnChange}
          handelImageChange={handelImageChange}
          handelStateBuildingChange={handelStateBuildingChange}
          handelOrderBuildingChange={handelOrderBuildingChange}
          handelAddDetails={handelAddDetails}
          handelRemoveDetails={handelRemoveDetails}
          handelUpdateFigure={handelUpdateFigure}
          key={projectsArr.project.indexOf(currentProject)}
        />
      ) : (
        <></>
      )}

      <div className="buildingUpdateButton">
        <button
          className="update-button"
          onClick={(e) => {
            setDone(true);
            if (currentProject === null) {
              updateProject();
            }
          }}
        >
          حفظ
        </button>
      </div>
    </div>
  );
}
