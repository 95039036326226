import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import './TableExcel.css';
import { getAndUpdateID, updateProjectToDB } from '../../api';
import app from '../../base';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import { updateMessage, validateSAID } from './Functions';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import MuiPhoneNumber from 'material-ui-phone-number';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

export default function TableExcel({ data, building, currentUser }) {
  const [newData, setNewData] = React.useState([]);

  // if ("booking" in data) {
  //   setNewData(data["booking"][building]);
  // }

  React.useEffect(() => {
    // console.log(building);
    // console.log(data[0]["booking"]);
    if (building in data['booking']) {
      // console.log(data);
      setNewData(data['booking'][building]);
    } else {
      setNewData([]);
    }
  }, [building]);

  const [apartment_No, setApartment_No] = React.useState();
  const [secondAppartmentNumber, setSecondAppatmentNumber] = React.useState('');
  const [size, setSize] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [phone, setClientPhone] = React.useState('');
  const [clientID, setClientID] = React.useState('');
  const [refresh, setRefresh] = React.useState(1);
  const [state, setState] = React.useState('محجوزه');
  // const [check, setCheck] = React.useState(true);
  const ref = React.useRef();
  const [deposit, setDeposit] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [sold, setSold] = React.useState(0);
  const [instrument_No, setInstrument_No] = React.useState();
  const [firstDeposit, setFirstDeposit] = React.useState('بدون');
  const [FD_Money, setFD_Money] = React.useState(0);
  const [excessAmount, setExcessAmount] = React.useState('بدون');
  const [excess_Money, setExcess_Money] = React.useState(0);
  const [VAT, setVAT] = React.useState(0);
  const [VATState, setVATState] = React.useState('غير معفي');
  const [hidden, setHidden] = React.useState(false);
  const [floor, setFloor] = React.useState('أ');
  const [appartment, setAppartment] = React.useState(1);
  const [note, setNote] = React.useState();
  const [dalala, setDalala] = React.useState(0);
  const [bankName, setBankName] = React.useState('لايوجد');
  const [bankState, setBankState] = React.useState('لايوجد');

  const handelAddData = async (e) => {
    e.preventDefault();
    let check = true;
    let date = new Date();
    let newdate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    newData.forEach(async (e) => {
      if (e.no_apartment == apartment_No) {
        alert(`الشقة ${e.state} مسبقا`);
        check = false;
      }
    });

    if (check) {
      if (price > 0) {
        let id = '';
        await getAndUpdateID().then((e) => {
          id = e;
        });
        let tempObj = {
          no_apartment: parseInt(apartment_No),
          secondAppartmentNumber: `${floor}/${appartment}`,
          space: size,
          client_name: clientName,
          clientID: clientID,
          client_phone: phone,
          state: state,
          deposit: deposit,
          VATState: VATState,
          price: price,
          VAT: VAT,
          total: parseFloat(VAT) + parseFloat(price),
          note: '',
          FD_Money: FD_Money,
          excess_Money: excess_Money,
          done: 'لم يتم الإفراغ',
          bankName: bankName,
          bankState: bankState,
          id: id,
          employe_name: currentUser,
          date: newdate,
          instrument_No: ''
        };
        let temp_arr = JSON.parse(JSON.stringify(newData));
        temp_arr.push(tempObj);
        setNewData(temp_arr);
        let booking = {};
        // booking[building] = newData;
        data.booking[building] = temp_arr;

        // console.log(data[0]);
        updateProjectToDB(data.name['en'], data).then((response) => {});
        updateMessage('add');
        // clean input filed after booking
        setApartment_No(1);
        setSize('');
        setClientName('');
        setState('');
        setClientPhone('');
        setDeposit(0);
        setPrice(0);
        setSold('');
        setInstrument_No('');
        setSecondAppatmentNumber('');
        setFirstDeposit('بدون');
        setFD_Money(0);
        setExcessAmount('بدون');
        setExcess_Money(0);
        setVATState('غير معفي');
        setVAT(0);
        setClientID('');
        setHidden(false);
        setFloor('أ');
        setValue('1');
        setAppartment(1);
        setNote('');
        setBankName('لايوجد');
        setBankState('لايوجد');
      }
      // else {
      //   alert(`لايمكن بيع الشقة بأقل من سعرها`);
      // }
    }
    setRefresh(refresh + 1);
  };

  let bankNames = [
    'لايوجد',
    'البنك الأهلي التجاري',
    'البنك السعودي الفرنسي',
    'البنك الأول',
    'البنك السعودي للاستثمار',
    'البنك العربي الوطني',
    'بنك البلاد',
    'بنك الجزيرة',
    'بنك الرياض',
    'مجموعة سامبا المالية (سامبا)',
    'مصرف الراجحي',
    'مصرف الإنماء',
    'بنك الخليج الدولي - السعودية'
  ];

  let allBankState = [
    'لايوجد',
    'تحت الإجراء',
    'وقع العقود',
    'تقييم أول',
    'تقييم ثاني',
    'صدر الشيك',
    'تم الإفراغ',
    'كاش'
  ];

  const classes = useStyles();

  const columns = [
    { field: 'state', headerName: 'الحالة', width: 150 },
    {
      field: 'date',
      headerName: 'التاريخ',
      width: 150
    },
    {
      field: 'employe_name',
      headerName: 'إسم الموظف',
      width: 150
    },
    // {
    //   field: "client_phone",
    //   headerName: "الجوال",
    //   // type: 'number',
    //   width: 110,
    // },
    // {
    //   field: "client_name",
    //   headerName: "إسم العميل",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, "firstName") || ""} ${
    //       params.getValue(params.id, "lastName") || ""
    //     }`,
    // },
    {
      field: 'space',
      headerName: 'المساحة',
      width: 150
    },
    {
      field: 'no_apartment',
      headerName: 'رقم الشقة',
      width: 150
    }
  ];

  const [value, setValue] = React.useState('1');
  const [value1, setValue1] = React.useState('1');
  const [validateID, setValidateID] = React.useState(true);
  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  const handelPrice = (newPrice, VState) => {
    setPrice(
      newPrice
        .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
        .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    );
    if (newPrice !== '') {
      if (VState === 'معفي') {
        if (parseInt(newPrice) > 1000000) {
          console.log(parseInt(newPrice));
          setVAT(((parseInt(newPrice) - 1000000) * 5) / 100);
        } else {
          setVAT(0);
        }
      } else {
        setVAT((parseInt(newPrice) * 5) / 100);
      }
    } else {
      setVAT(0);
    }
  };

  const appartmentNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30
  ];
  const appartmentFloor = ['أ', 1, 2, 3, 4, 5, 6, 7];
  const appartmentOnFloor = [1, 2, 3, 4, 5];

  let unFreeAppartments = newData.map((e) => {
    if (appartmentNumber.includes(e.no_apartment)) return e.no_apartment;
  });
  unFreeAppartments = unFreeAppartments.filter((e) => e !== undefined);

  const handelPhoneChange = (value) => {
    setClientPhone(value);
  };

  const handelSubmit = (e, number) => {
    e.preventDefault();
    if (number === '2') {
      if (size > 50 && size < 700) {
        setValue(number);
      }
    } else if (number === '3') {
      if (validateID && phone.length === 10) {
        setValue(number);
      }
    }
  };

  return (
    <div className="tableExcelDiv">
      {/* {console.log(newData)} */}
      <input type="hidden" value={refresh} />
      <TabContext className="addBookingTab1" value={value1}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            className="tabList1"
            aria-label="lab API tabs example"
            dir="rtl"
          >
            <Tab label="حجز جديد" value="1" />
            <Tab label="عرض المحجوز" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tabPanel">
          <h4
            style={{
              width: '40%',
              textAlign: 'center',
              margin: 'auto',
              marginTop: '3%'
            }}
            onClick={(e) => setHidden(!hidden)}
          >
            معلومات الحجز
          </h4>
          <div className="addBookingTab">
            <TabContext className="addBookingTab" value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList aria-label="lab API tabs example">
                  <Tab label="معلومات الشقة" value="1" />
                  <Tab label="معلومات العميل" value="2" />
                  <Tab label="معلومات الحجز" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <>
                  <form
                    Validate
                    autoComplete="off"
                    onSubmit={(e) => handelSubmit(e, '2')}
                  >
                    <div className="tabsContent">
                      <TextField
                        className="appartmentNumber"
                        id="standard-select"
                        select
                        label="رقم الشقة التسلسلي"
                        required
                        onChange={(e) => setApartment_No(e.target.value)}
                        value={apartment_No}
                      >
                        {appartmentNumber.map((e, key) => {
                          if (!unFreeAppartments.includes(e)) {
                            return (
                              <MenuItem key={key} value={e}>
                                {e}
                              </MenuItem>
                            );
                          } else {
                            return (
                              <MenuItem key={key} value={e} disabled>
                                {e}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                      <TextField
                        id="standard-select"
                        className="appartmentOnFloor"
                        select
                        label="رقم الشقة في الدور"
                        required
                        onChange={(e) => setAppartment(e.target.value)}
                        value={appartment}
                      >
                        {appartmentOnFloor.map((e, key) => {
                          return (
                            <MenuItem key={key} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <p className="slash">/</p>

                      <TextField
                        className="floor"
                        id="standard-select"
                        select
                        label="الدور"
                        required
                        onChange={(e) => setFloor(e.target.value)}
                        value={floor}
                      >
                        {appartmentFloor.map((e, key) => {
                          return (
                            <MenuItem key={key} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      {/* <TextField
                        className="appartmentNumber"
                        id="standard-basic"
                        label="رقم الشقة الجديد "
                        required
                        value={secondAppartmentNumber}
                        onChange={(e) =>
                          setSecondAppatmentNumber(
                            e.target.value
                              .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
                              .replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d))
                          )
                        }
                      /> */}
                      <TextField
                        id="standard-basic"
                        error={
                          (size >= 50 && size <= 1000) || size === ''
                            ? false
                            : true
                        }
                        label="مساحة الشقة (متر مربع)"
                        className="sizeField"
                        required
                        value={size}
                        helperText={
                          (size >= 50 && size <= 1000) || size === ''
                            ? null
                            : 'مساحة الشقة يجب ان تكون بين 50-1000 متر مربع'
                        }
                        onChange={(e) => {
                          setSize(
                            e.target.value
                              .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                              .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
                          );
                        }}
                      />
                    </div>
                    <div className="buttons">
                      <button className="btn btn-primary next" type="submit">
                        التالي
                      </button>
                    </div>
                  </form>
                </>
              </TabPanel>
              <TabPanel value="2">
                <>
                  <form
                    Validate
                    autoComplete="off"
                    onSubmit={(e) => handelSubmit(e, '3')}
                  >
                    <div className="tabsContent">
                      <TextField
                        id="standard-basic"
                        label="اسم العميل"
                        required
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                      />
                      <TextField
                        error={validateID || clientID === '' ? false : true}
                        className="IDField"
                        id="standard-basic"
                        label="هوية العميل"
                        variant="standard"
                        required
                        value={clientID}
                        helperText={
                          validateID || clientID === ''
                            ? null
                            : 'رقم الهوية غير صحيح'
                        }
                        onChange={(e) => {
                          setClientID(e.target.value);
                          // console.log(validateSAID(e.target.value));
                          validateSAID(e.target.value) != -1
                            ? setValidateID(true)
                            : setValidateID(false);
                        }}
                      />
                      {/* <MuiPhoneNumber
                        className="phoneField"
                        countryCodeEditable={false}
                        defaultCountry={"sa"}
                        onChange={handelPhoneChange}
                        onlyCountries={"sa"}
                      /> */}

                      <TextField
                        className="phoneField"
                        id="standard-basic"
                        label="الجوال"
                        required
                        error={
                          phone.length === 10 || phone === '' ? false : true
                        }
                        value={phone}
                        helperText="ex:05XXXXXXXX"
                        onChange={(e) =>
                          e.target.value.length <= 10
                            ? setClientPhone(
                                e.target.value
                                  .replace(/[٠-٩]/g, (d) =>
                                    '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                  )
                                  .replace(/[۰-۹]/g, (d) =>
                                    '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                  )
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="buttons">
                      <button className="btn btn-primary next" type="submit">
                        التالي
                      </button>
                      <button
                        className="btn btn-primary past"
                        onClick={(e) => setValue('1')}
                      >
                        السابق
                      </button>
                    </div>
                  </form>
                </>
              </TabPanel>
              <TabPanel value="3">
                <>
                  <form Validate autoComplete="off" onSubmit={handelAddData}>
                    <div className="tabsContent">
                      <TextField
                        id="standard-select"
                        select
                        label="الحالة"
                        required
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      >
                        <MenuItem key={1} value={'محجوزه'}>
                          حجز
                        </MenuItem>
                        <MenuItem key={2} value={'مباعه'}>
                          بيع
                        </MenuItem>
                      </TextField>
                      <TextField
                        id="standard-basic"
                        label="العربون (ريال سعودي)"
                        type="number"
                        required
                        min="0"
                        value={deposit}
                        onChange={(e) =>
                          e.target.value >= 0
                            ? setDeposit(
                                e.target.value
                                  .replace(/[٠-٩]/g, (d) =>
                                    '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                  )
                                  .replace(/[۰-۹]/g, (d) =>
                                    '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                  )
                              )
                            : setDeposit(0)
                        }
                      />

                      <TextField
                        id="standard-select"
                        select
                        label="حالة التصرفات العقارية"
                        required
                        onChange={(e) => {
                          setVATState(e.target.value);
                          handelPrice(price, e.target.value);
                        }}
                        value={VATState}
                      >
                        <MenuItem key={1} value={'غير معفي'}>
                          غير معفي
                        </MenuItem>
                        <MenuItem key={2} value={'معفي'}>
                          معفي
                        </MenuItem>
                      </TextField>

                      <TextField
                        id="standard-basic"
                        label="السعر (ريال سعودي)"
                        value={price}
                        type="number"
                        required
                        onChange={(e) =>
                          e.target.value >= 0
                            ? handelPrice(e.target.value, VATState)
                            : setPrice(0)
                        }
                      />

                      <TextField
                        id="filled-disabled"
                        label="قيمة التصرفات العقارية (ريال سعودي) "
                        value={VAT}
                        required
                      />

                      <TextField
                        id="standard-disabled"
                        label="الإجمالي شامل الضريبة (ريال سعودي)"
                        value={
                          parseFloat(VAT) +
                          (isNaN(parseFloat(price)) ? 0 : parseFloat(price))
                        }
                        required
                      />

                      <div className="MuiFormControl-root">
                        <Autocomplete
                          value={bankName}
                          id="combo-box-demo"
                          options={bankNames}
                          getOptionLabel={(option) => option}
                          onChange={(e) => {
                            if (bankNames.includes(e.target.innerHTML)) {
                              setBankName(e.target.innerHTML);
                            } else {
                              setBankName('لايوجد');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="البنك"
                              variant="filled"
                            />
                          )}
                        />
                      </div>

                      <TextField
                        id="standard-select"
                        select
                        label="حالة البنك"
                        onChange={(e) => setBankState(e.target.value)}
                        value={bankState}
                      >
                        {allBankState.map((e, key) => {
                          return (
                            <MenuItem key={key} value={e}>
                              {e}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <TextField
                        id="outlined-multiline-static"
                        label="ملاحظات"
                        multiline
                        rows={4}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />

                      {/* <TextField
                    id="standard-basic"
                    label="مبلغ الشيك"
                    value={sold}
                    type="number"
                    required
                    onChange={(e) =>
                      e.target.value >= 0
                        ? setSold(
                            e.target.value
                              .replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
                              .replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d))
                          )
                        : setSold(0)
                    }
                  /> */}
                      {/* <TextField
            id="standard-basic"
            label="رقم الصك"
            value={instrument_No}
            required
            onChange={(e) => setInstrument_No(e.target.value)}
          /> */}

                      {hidden ? (
                        <>
                          <TextField
                            id="standard-select"
                            select
                            label="دفعة اولى مقدمة"
                            required
                            onChange={(e) =>
                              setFirstDeposit(
                                e.target.value
                                  .replace(/[٠-٩]/g, (d) =>
                                    '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                  )
                                  .replace(/[۰-۹]/g, (d) =>
                                    '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                  )
                              )
                            }
                            value={firstDeposit}
                          >
                            <MenuItem key={1} value={'بدون'}>
                              بدون
                            </MenuItem>
                            <MenuItem key={2} value={'دفعة أولى'}>
                              دفعة أولى
                            </MenuItem>
                          </TextField>
                          {firstDeposit !== 'بدون' ? (
                            <TextField
                              id="standard-basic"
                              label="المبلغ"
                              required
                              type="number"
                              value={FD_Money}
                              onChange={(e) => {
                                e.target.value >= 0
                                  ? setFD_Money(
                                      e.target.value
                                        .replace(/[٠-٩]/g, (d) =>
                                          '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                        )
                                        .replace(/[۰-۹]/g, (d) =>
                                          '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                        )
                                    )
                                  : setFD_Money(0);
                              }}
                            />
                          ) : null}

                          <TextField
                            id="standard-select"
                            select
                            label="فائض"
                            required
                            onChange={(e) =>
                              setExcessAmount(
                                e.target.value
                                  .replace(/[٠-٩]/g, (d) =>
                                    '٠١٢٣٤٥٦٧٨٩'.indexOf(d)
                                  )
                                  .replace(/[۰-۹]/g, (d) =>
                                    '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)
                                  )
                              )
                            }
                            value={excessAmount}
                          >
                            <MenuItem key={1} value={'بدون'}>
                              بدون
                            </MenuItem>
                            <MenuItem key={2} value={'فائض'}>
                              فائض
                            </MenuItem>
                          </TextField>
                          {excessAmount !== 'بدون' ? (
                            <TextField
                              id="standard-basic"
                              label="المبلغ"
                              required
                              type="number"
                              value={excess_Money}
                              onChange={(e) => {
                                e.target.value >= 0
                                  ? setExcess_Money(e.target.value)
                                  : setExcess_Money(0);
                              }}
                            />
                          ) : null}

                          <TextField
                            id="standard-disabled"
                            label="الدلالة"
                            value={dalala}
                            onChange={(e) => setDalala(e.target.value)}
                          />
                        </>
                      ) : null}
                    </div>
                    <div className="buttons">
                      <button className="btn btn-primary next" type="submit">
                        حجز
                      </button>
                      <button
                        className="btn btn-primary past"
                        onClick={(e) => setValue('2')}
                      >
                        السابق
                      </button>
                    </div>
                  </form>
                </>
              </TabPanel>
            </TabContext>
          </div>
        </TabPanel>
        <TabPanel value="2" className="tabPanel">
          <div className="dataTableDiv" style={{ width: '50%' }}>
            <DataGrid
              rows={newData}
              columns={columns}
              autoHeight={true}
              hideFooter
            />
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}
