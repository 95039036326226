import React from "react";
import Images from "./Images";
import Form from "react-bootstrap/Form";
import BuildingDetails from "./BuildingDetails";
import Button from "@material-ui/core/Button";
import { DropzoneDialog } from "material-ui-dropzone";
import Figure from "./Figure";
import { element } from "prop-types";

export default function Buildings({
  building,
  done,
  updateProject,
  setDone,
  changeImageBuilding,
  deleteImageBuilding,
  addImageBuilding,
  handelNameArChange,
  handelNameEnChange,
  handelDetailsArChange,
  handelDetailsEnChange,
  handelImageChange,
  handelStateBuildingChange,
  handelOrderBuildingChange,
  handelAddDetails,
  handelRemoveDetails,
  handelUpdateFigure,
}) {
  const [projectBuilding, setProjectBuilding] = React.useState(building);
  const [projectBuildingNameAr, setProjectBuildingNameAr] = React.useState(
    building.name["ar"]
  );
  const [projectBuildingNameEn, setProjectBuildingNameEn] = React.useState(
    building.name["en"]
  );
  const [projectBuildingImage, setProjectBuildingImage] = React.useState(
    building.img
  );
  const [projectBuildingStateAr, setProjectBuildingStateAr] = React.useState(
    building.state["ar"]
  );
  const [projectBuildingStateEn, setProjectBuildingStateEn] = React.useState(
    building.state["en"]
  );
  const [projectBuidingOrder, setProjectBuidingOrder] = React.useState(
    building.order
  );
  const [projectBuildingImages, setProjectBuildingImages] = React.useState(
    building.images
  );
  const [projectDetails, setProjectDetails] = React.useState({});

  const [projectBuildingDetailsAr, setProjectBuildingDetailsAr] =
    React.useState(projectBuilding.details["ar"]);
  const [projectBuildingDetailsEn, setProjectBuildingDetailsEn] =
    React.useState(projectBuilding.details["en"]);

  const [newImage, setNewImage] = React.useState("");
  const [refresh, setRefresh] = React.useState(1);

  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);

  const [projectFigures, setProjectFigures] = React.useState();

  React.useEffect(() => {
    setProjectBuilding(building);
    setProjectBuildingNameAr(building.name["ar"]);
    setProjectBuildingNameEn(building.name["en"]);
    setProjectBuildingImage(building.img);
    setProjectBuildingStateAr(building.state["ar"]);
    setProjectBuildingStateEn(building.state["en"]);
    setProjectBuidingOrder(building.order);
    setProjectBuildingImages(building.images);
    setProjectBuildingDetailsAr(building.details["ar"]);
    setProjectBuildingDetailsEn(building.details["en"]);
    const details = {
      ar: building.details["ar"],
      en: building.details["en"],
    };
    setProjectDetails(details);
    setProjectFigures(building.figures);
  }, [building]);

  React.useEffect(() => {
    if (done) {
      setDone(false);
      updateProject();
    }
  }, [done]);

  const handelImages = (e) => {
    addImageBuilding(newImage);
    setNewImage("");
  };

  const handelDeleteImage = (e) => {
    deleteImageBuilding(e);
  };

  const changeImage = (image, index) => {
    changeImageBuilding(image, index);
  };

  const addDetails = (e) => {
    handelAddDetails();
  };

  const addFigure = (e) => {
    let figure = {
      size: "",
      rooms: "",
      livingRooms: "",
      bathRooms: "",
      parking: "",
      extra: false,
    };

    let temp = projectFigures;
    temp.push(figure);
    setProjectFigures(temp);
    handelUpdateFigure(temp);
    setRefresh(refresh + 1);
  };

  const deleteFigure = (index) => {
    let temp = projectFigures;
    temp.splice(index, 1);
    setProjectFigures(temp);
    handelUpdateFigure(temp);
    setRefresh(refresh + 1);
  };

  const addFigureData = (index, element, value) => {
    let temp = projectFigures;
    temp[index][element] = value;
    setProjectFigures(temp);
    handelUpdateFigure(temp);
    setRefresh(refresh + 1);
  };

  const numberOfRooms = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <div className="building">
      <input type="hidden" value={refresh} />

      <div className="classInfo">
        <p style={{ width: "80%", margin: "auto", textAlign: "center" }}>
          معلومات التصنيف
        </p>
        <div className="newDiv">
          <div className="grid-container">
            <div className="grid-item 1 classRooms">
              <Form.Control
                required
                as="select"
                onChange={(e) => {
                  setProjectBuildingNameAr(e.target.value);
                  handelNameArChange(e.target.value);
                }}
                value={projectBuildingNameAr}
                // dir={`${this.props.i18n.language === "ar" ? "rtl" : "ltr"}`}
              >
                {numberOfRooms.map((e, index) => (
                  <option key={index}>{e}</option>
                ))}
              </Form.Control>
            </div>
            <div className="grid-item 2 classRooms">عدد الغرف</div>
            <div className="grid-item 3 classState">
              {" "}
              <Form.Control
                required
                as="select"
                onChange={(e) => {
                  handelStateBuildingChange(e.target.value);
                  setProjectBuildingStateAr(e.target.value);
                }}
                value={projectBuildingStateAr}
                // dir={`${this.props.i18n.language === "ar" ? "rtl" : "ltr"}`}
              >
                <option>متوفر</option>
                <option>مباع</option>
              </Form.Control>
            </div>
            <div className="grid-item 4 classState">الحالة</div>
            <div className="grid-item 5 classImage">
              {" "}
              <input
                value={projectBuildingImage}
                onChange={(e) => {
                  setProjectBuildingImage(e.target.value);
                  handelImageChange(e.target.value);
                }}
              ></input>
            </div>
            <div className="grid-item 6 classImage">صورة التصنيف</div>
            <div className="grid-item 7 classOrder">
              {" "}
              <input
                value={projectBuidingOrder}
                onChange={(e) => {
                  setProjectBuidingOrder(e.target.value);
                  handelOrderBuildingChange(e.target.value);
                }}
              ></input>
            </div>
            <div className="grid-item 8 classOrder">ترتيب الظهور</div>
          </div>
        </div>
      </div>

      <div className="div-images">
        <br />
        <p>صور التصنيف</p>
        <div className="buildingImages">
          <div className="div-allImage">
            {projectBuildingImages.map((e, index) => {
              return (
                <Images
                  images={e}
                  number={index + 1}
                  key={index}
                  handelDeleteImage={handelDeleteImage}
                  changeImage={changeImage}
                />
              );
            })}

            <div className="inputImageDiv" onClick={(ee) => {}}>
              <input
                className="inputImage"
                placeholder="رابط الصورة"
                value={newImage}
                onChange={(e) => setNewImage(e.target.value)}
              ></input>
            </div>
            <div className="addImageToImages">
              <Button onClick={(e) => handelImages(e)}> اضف صورة</Button>
            </div>
          </div>
        </div>
      </div>

      {/* معلومات النموذج (يجب ان يحتوي النموذج على عدة نماذج لمواصفات الشقة) */}
      <p
        style={{
          width: "80%",
          margin: "auto",
          textAlign: "center",
          fontSize: "32px",
          marginTop: "2%",
        }}
      >
        النماذج
      </p>
      {building.figures.map((e, index) => (
        <Figure
          figure={e}
          key={index}
          index={index}
          deleteFigure={deleteFigure}
          addFigureData={addFigureData}
        />
      ))}
      <div className="addFigure">
        <Button onClick={(e) => addFigure()}>إضافة نموذج</Button>
      </div>

      <div className="div-details">
        <p className="center">التفاصيل</p>

        <div className="details">
          <div className="details-ar">
            <h5>التفاصيل بالعربي</h5>
            {Object.keys(projectDetails).length !== 0
              ? projectDetails["ar"].map((e, index) => {
                  return (
                    <BuildingDetails
                      e={e}
                      detailsLang="ar"
                      key={index}
                      index={index}
                      setProjectBuildingDetails={setProjectBuildingDetailsAr}
                      handelDetailsChange={handelDetailsArChange}
                      handelRemoveDetails={handelRemoveDetails}
                    />
                  );
                })
              : null}
          </div>

          <div className="details-en">
            <h5>التفاصيل بالإنجليزي</h5>
            {Object.keys(projectDetails).length !== 0
              ? projectDetails["en"].map((e, index) => {
                  return (
                    <BuildingDetails
                      e={e}
                      detailsLang="en"
                      index={index}
                      key={index}
                      setProjectBuildingDetails={setProjectBuildingDetailsEn}
                      handelDetailsChange={handelDetailsEnChange}
                      handelRemoveDetails={handelRemoveDetails}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <p className="addDetailsButton" onClick={(e) => addDetails(e)}>
        إضافة تفاصيل
      </p>
    </div>
  );
}
